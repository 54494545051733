<template>
  <div class="oscar-subproject">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.oscarSubProjects">
        <h1 class="title">
          <breadcrumb location="/oscarsubprojects"
                      text="Sous-projet OSCAR"></breadcrumb>
          {{ oscarSubProject.name }}
        </h1>
      </page-header>

      <div class="panel infos">
        <div class="panel-heading has-background-primary">
          <div class="tabs">
            <ul>
              <li :class="{'is-active': currentTab == 0}"
                  @click="currentTab = 0">
                <a>{{$t('common.information')}}</a>
              </li>
              <li :class="{'is-active': currentTab == 1}"
                  @click="currentTab = 1">
                <a>{{$t('oscarSubProjects.constructionBaseQuantities')}}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="panel-block is-block no-border"
             v-if="currentTab === 0">
          <field :label="$t('common.dsp')"
                 readonly>{{ oscarSubProject.dsp }}</field>
          <field :label="$t('oscarSubProjects.columns.oiCode')"
                 readonly>{{ oscarSubProject.oiCode }}</field>
          <field :label="$t('oscarSubProjects.columns.marketType')"
                 readonly>{{ oscarSubProject.marketType }}</field>

          <field :label="$t('oscarSubProjects.columns.projectName')"
                 readonly>{{ oscarSubProject.projectName }}</field>
          <field :label="$t('oscarSubProjects.columns.subProjectName')"
                 readonly>{{ oscarSubProject.name }}</field>
          <field :label="$t('oscarSubProjects.columns.arcgisName')"
                 readonly>{{ oscarSubProject.arcgisName }}</field>

          <field :label="$t('oscarSubProjects.columns.subProjectYear')"
                 readonly>{{ oscarSubProject.year }}</field>
          <field :label="$t('oscarSubProjects.columns.subProjectCity')"
                 readonly>{{ oscarSubProject.city }}</field>
          <field :label="$t('oscarSubProjects.columns.subprojectStatus')"
                 readonly>{{ oscarSubProject.status }}</field>
          <field :label="$t('common.milestone')"
                 readonly>{{ oscarSubProject.milestone }}</field>

          <field :label="$t('oscarSubProjects.columns.nroName')"
                 readonly>{{ oscarSubProject.nroName }}</field>
          <field :label="$t('oscarSubProjects.columns.nroIpeId')"
                 readonly>{{ oscarSubProject.ipeIdNro }}</field>
          <field :label="$t('oscarSubProjects.columns.pmIpeId')"
                 readonly>{{ oscarSubProject.ipeIdPm }}</field>
          <field :label="$t('oscarSubProjects.columns.pmOfferId')"
                 readonly>{{ oscarSubProject.offerIdPm }}</field>
          <field :label="$t('oscarSubProjects.columns.pmAxioneId')"
                 readonly>{{ oscarSubProject.axioneInternalPmId }}</field>
          <field :label="$t('oscarSubProjects.columns.pmCity')"
                 readonly>{{ oscarSubProject.pmCity }}</field>
        </div>

        <div class="panel-block is-block no-border"
             v-if="currentTab === 1">
          <div class="content"
               v-if="!oscarSubProject.oscarColumns">
            <p class="has-text-centered">{{$t('expressions.noData')}}</p>
          </div>
          <div class="content"
               v-else>
            <field v-for="(qty, columnName) in oscarSubProject.oscarColumns"
                   :value="qty"
                   :key="columnName"
                   :label="columnName"></field>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { globalLocale } from '../../i18n'

export default {
  name: 'oscar-subproject',
  props: ['id'],
  data () {
    return {
      oscarSubProject: {},
      currentTab: 0,
      globalLocale: globalLocale
    };
  },
  computed: {
    ...mapGetters({
    }),
  },
  mounted () {
    this.fetchData();
  },
  methods: {
    fetchData () {
      axios.get(`/oscarsubproject/${this.id}`).then((response) => {
        this.oscarSubProject = response.data;
        this.$store.dispatch('states/setEdition', false);
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.panel-heading {
  padding: 0em 0.75em;

  .tabs {
    ul {
      border-bottom: none;
    }

    li {
      width: 25%;

      a {
        color: #fff;
        border-bottom: none;
        padding: 1em 0em;
      }

      &.is-active a {
        color: #000;
        background-color: #fff;
      }
    }
  }
}
</style>
