var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"container is-fluid"},[_c('page-header',{attrs:{"entity":_vm.$entitiesName.Document}},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('common.documents')))])]),_c('datatable',{ref:"listDocuments",attrs:{"fetch":_vm.fetchDocuments},scopedSlots:_vm._u([{key:"filename",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('expressions.fileName'),"sortable":true}},[_c('span',[_vm._v(_vm._s(item.filename))])])}},{key:"type",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.type'),"sortable":true}},[_c('span',[_vm._v(_vm._s(item.type && item.type.name))])])}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.date'),"sortable":true}},[_c('span',[_vm._v(_vm._s(_vm._f("reverseDate")(item.createdAt)))])])}},{key:"actions",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('router-link',{staticClass:"button is-primary",attrs:{"to":'/document/' + item.id}},[_c('i',{staticClass:"fa fa-search"})])],1)}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }