<template>
  <tr class="has-background-info-light">
    <th>{{ deposit.createdAt | date }}</th>
    <th>
      <text-field v-model="depositEdited.reference" :inline="false" :edit="isEditing" />
    </th>
    <th class="has-text-right">
      <numeric-field 
        type="price"
        v-model="depositEdited.amount"
        :edit="isEditing"
        :inline="false"
      />
    <th>
      <date-field
        v-model="depositEdited.reimbursementDate"
        :edit="isEditing"
        :inline="false"
      />
    </th>
    <td v-if="editable">
      <div class="is-flex is-justify-content-flex-end">
      <button v-if="!isEditing" class="button is-primary is-small" @click="isEditing = true">
        <i class="fa fa-pencil"></i>
      </button>
      <button v-if="isEditing" @click="updateDeposit" class="button is-small is-success">
        <i class="fa fa-check"></i>
      </button>
      <button v-if="isEditing" class="button is-small is-warning" @click="cancel">
        <i class="fa fa-times"></i>
      </button>
      </div>
    </td>
  </tr>
</template>

<script>
import axios from 'axios';

export default {
  name: 'DepositRow',
  props: {
    deposit: {
      type: Object,
      default: () => {}
    },
    editable: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isEditing: false,
      depositEdited: {}
    }
  },
  mounted() {
    this.depositEdited = JSON.parse(JSON.stringify(this.deposit))
  },
  methods: {
    updateDeposit() {
      axios.put(`/deposit/${this.deposit.id}`, this.depositEdited).then(() => {
          this.isEditing = false
          this.$emit('updated')
        })
    },
    cancel() {
      this.isEditing = false
      this.depositEdited = JSON.parse(JSON.stringify(this.deposit))
    }
  }
}
</script>

<style>

</style>