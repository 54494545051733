<template>
  <div class="society">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Company"
                   :edit="edit"
                   :save="save"
                   :cancel="cancel"
                   :remove="remove">
        <h1 class="title"
            cy-data="cy-company-title">
          <breadcrumb location="/sst/companies"
                      text="Société"></breadcrumb>
          {{ company.name }}
        </h1>
      </page-header>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          <div class="tabs">
            <ul>
              <li :class="{'is-active': currentTab == 0}"
                  @click="currentTab = 0">
                <a>{{$t('common.information')}}</a>
              </li>
              <li :class="{'is-active': currentTab == 1}"
                  @click="currentTab = 1">
                <a>{{$t('common.agencies')}}</a>
              </li>
              <li :class="{'is-active': currentTab == 2}"
                  @click="currentTab = 2">
                <a>{{$t('common.documents')}}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="panel-block no-border is-block"
             v-if="currentTab == 0">
          <text-field cy-data="edifice"
                      :label="$t('companies.columns.edificeCode')"
                      v-model="company.edifice"
                      required></text-field>
          <text-field cy-data="name"
                      :label="$t('common.name')"
                      v-model="company.name"
                      required></text-field>
          <text-field cy-data="siren"
                      :label="$t('common.siren')"
                      v-model="company.siren"></text-field>
          <text-field cy-data="manager"
                      :label="$t('common.director')"
                      v-model="company.manager"></text-field>
          <text-field cy-data="mail"
                      :label="$t('common.mail')"
                      type="email"
                      v-model="company.mail"></text-field>
        </div>

        <div class="panel-block no-border is-block"
             v-if="currentTab == 1">
          <div class="is-full-width">
            <button v-if="getRights($entitiesName.Agency).create"
                    class="is-pulled-right button is-success"
                    @click="openCreateAgencyModal">
              <i class="fa fa-plus"></i>
            </button>
          </div>
          <flexi-table class="is-full-width"
                       :loader="fetchAgencies"
                       v-model="agencies"
                       :columns="agencyTableColumns"
                       :showIndex="true"
                       :hasActions="true"
                       ref="agencyTable">
            <template slot="dataRows"
                      slot-scope="{ item, index }">
              <tr>
                <td>{{ index + 1 }}</td>
                <td>
                  <restricted-link :entity="$entitiesName.Agency"
                                   :to="`/sst/agency/${item.id}`">
                    {{ item.edifice }}
                  </restricted-link>
                </td>
                <td>{{ item.siret }}</td>
                <td>{{ item.manager }}</td>
                <td>{{ item.address }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.mail}}</td>
                <td>
                  <button v-if="getRights($entitiesName.Agency).update"
                          @click="editAgency(item.id)"
                          class="button is-small is-primary">
                    <i class="fa fa-pencil"></i>
                  </button>
                </td>
              </tr>
            </template>
          </flexi-table>
          <modal ref="createAgencyModal"
                 @create="createAgency"
                 :title="$t('companies.agencyCreation')">
            <div class="columns">
              <div class="column is-half">
                <siret-field
                  :label="$t('common.siret')"
                  v-model="siret"
                  :inline="false"
                  :edit="true"
                ></siret-field>

                <text-field
                  :label="$t('common.agency')"
                  v-model="agency.edifice"
                  :inline="false"
                  :edit="!siret || siret.inThirdParty === false"
                  required
                ></text-field>

                <textarea-field
                  :label="$t('common.address')"
                  v-model="agency.address"
                  :inline="false"
                  :edit="!siret || siret.inThirdParty === false"
                ></textarea-field>

                <checkbox-field
                  v-if="siret && siret.inThirdParty !== false"
                  label="Suivi e-attestation"
                  v-model="agency.synchronize"
                  :inline="false"
                  :edit="true"
                ></checkbox-field>

                <text-field
                  v-if="
                    siret &&
                    siret.inThirdParty !== false &&
                    agency.synchronize &&
                    !siret.onboarded
                  "
                  type="email"
                  :label="$t('agencies.columns.inviteMail')"
                  v-model="agency.inviteMail"
                  :inline="false"
                  :edit="true"
                ></text-field>
                <article
                  v-if="
                siret &&
                siret.inThirdParty !== null &&
                agency.synchronize &&
                !siret.onboarded
              "
                  slot="helper"
                  class="message is-info"
                >
                  <div class="message-body">{{ $t("agencies.inviteMailHint") }}</div>
                </article>
              </div>

              <div class="column is-half">
                <text-field :label="$t('common.manager')"
                            v-model="agency.manager"
                            :inline="false"
                            :edit="true"></text-field>
                <text-field :label="$t('common.phone')"
                            v-model="agency.phone"
                            :inline="false"
                            :edit="true"></text-field>
                <text-field :label="$t('common.email')"
                            v-model="agency.mail"
                            :inline="false"
                            :edit="true"></text-field>
              </div>
            </div>
          </modal>
        </div>

        <div class="panel-block no-border is-block"
             v-if="currentTab == 2">
          <document-manager :objectName="$entitiesName.Company"
                            :objectId="id"
                            :deletable="getRights($entitiesName.Company).update"></document-manager>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'company',
  props: ['id'],
  data () {
    return {
      company: {},
      agencies: {},
      agency: {},
      currentTab: 0,
      siret: null,
      agencyTableColumns: [
        {
          title: this.$t('agencies.columns.agencyCode'),
          name: 'code',
          accessor: 'code',
          filter: '',
        },
        {
          title: this.$t('common.siret'),
          name: 'siret',
          accessor: 'siret',
          filter: '',
        },
        {
          title: this.$t('common.manager'),
          name: 'manager',
          accessor: 'manager',
          filter: '',
        },
        {
          title: this.$t('common.address'),
          name: 'address',
          accessor: 'address',
          filter: '',
        },
        {
          title: this.$t('common.phone'),
          name: 'phone',
          accessor: 'phone',
          filter: '',
        },
        {
          title: this.$t('common.mail'),
          name: 'mail',
          accessor: 'mail',
          filter: '',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      editMode: 'states/isEdition',
      getRights: 'auth/getRights',
    }),
  },
  mounted () {
    this.fetchData();
  },
  watch: {
    siret(val) {
      if (!val) {
        this.agency = {
          inThirdParty: false,
          edifice: "",
          siret: "",
          address: "",
          synchronize: false,
          inviteMail: "",
        };
        return;
      }

      if (!val.inThirdParty) {
        this.agency = {
          siret: val.siret,
          inThirdParty: false,
        };
        return;
      }

      this.agency = {
        inThirdParty: val.inThirdParty,
        edifice: val.name,
        siret: val.siret,
        address: val.address,
        synchronize: true,
        inviteMail: "",
      };
    },
  },
  methods: {
    fetchData () {
      axios.get(`/company/${this.id}`).then((response) => {
        this.$store.dispatch('states/setEdition', false);
        this.company = response.data;
      });
    },
    fetchAgencies (callback) {
      axios.get(`/company/${this.id}/agencies`).then((response) => {
        if (typeof callback === 'function') {
          callback(response.data);
        }
        this.agencies = response.data;
      });
    },
    editAgency (agencyId) {
      this.$router.push(`/sst/agency/${agencyId}`);
      this.$store.dispatch('states/setEdition', true);
    },
    openCreateAgencyModal () {
      this.$refs.createAgencyModal.open();
    },
    createAgency () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.agency.company = this.company;
          axios.post('/agency/create', this.agency).then(() => {
            this.agency = {};
            this.$refs.createAgencyModal.close();
            this.fetchData();
            this.$refs.agencyTable.fetch();
          });
        }
      });
    },
    save () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          return axios
            .put(`/company/${this.id}`, this.company)
            .then((response) => {
              if (response.data.success) {
                this.$store.dispatch('states/setEdition', false);
              }
            });
        }
        return Promise.resolve();
      });
    },
    edit () {
      this.$store.dispatch('states/setEdition', true);
    },
    cancel () {
      this.fetchData();
    },
    deleteAgency (agencyId) {
      const self = this;
      this.$awn.confirm(
        'Êtes-vous sûr de vouloir supprimer cet élément ?',
        () => {
          axios
            .delete(`/company/${self.id}/agency/${agencyId}/delete`)
            .then((response) => {
              if (response.data.success) {
                self.company.agencies = self.company.agencies.filter(
                  agency => agency.id !== agencyId,
                );
              }
            })
            .finally(() => {
              this.$refs.agencyTable.fetch();
            });
        },
      );
    },
    remove () {
      return this.$awn.confirm(
        'Êtes-vous sûr de vouloir supprimer cet élément ?',
        () =>
          axios.delete(`/company/${this.id}/delete`).then((response) => {
            if (response.data.success) {
              this.$router.replace('/sst/companies');
            }
          }),
      );
    },
  },
};
</script>

<style lang='scss' scoped>
.infos {
  .no-border {
    border: none;
    > span {
      width: 30%;
      display: inline-block;
    }
  }
}

.no-border {
  border: none;
}

.panel-heading {
  padding: 0em 0.75em;
  .tabs {
    ul {
      border-bottom: none;
    }
    li {
      width: 25%;
      a {
        color: #fff;
        border-bottom: none;
        padding: 1em 0em;
      }
      &.is-active a {
        color: #000;
        background-color: #fff;
      }
    }
  }
}

.is-full-width {
  flex: 1;
  width: 100%;
}
</style>
