<template>
  <div class="home">
    <div class="container is-fluid">

      <h1 class="title">{{$t('common.workflow')}} {{ workflow.name }}</h1>

      <workflow-chart :id="id"></workflow-chart>

    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: ['id'],
  name: 'workflow',
  data () {
    return {
      workflow: {},
      nodes: [],
    };
  },
  mounted () {
    this.fetchData();
  },
  watch: {
    id () {
      this.fetchData();
    },
  },
  methods: {
    fetchData () {
      axios.get(`/workflow/${this.id}`).then((response) => {
        this.workflow = response.data;
        this.$store.dispatch('states/setEdition', false);
      });
    },
  },
};
</script>

<style scoped>
</style>
