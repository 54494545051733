<template>
  <div class="column mt-8">
    <div v-if="loading">
      <div class="loader-container">
        <i class="loader"></i>
      </div>
    </div>
    <div v-else class="columns">
      <div class="column is-two-thirds envelope-column" v-if="envId">
        <div class="basic-info" :key="'basinf_' + envId">
          <h3 class="title is-3">{{ envelope.name }}</h3>
          <text-field
            :label="$t('common.name')"
            :value="envelope.name"
            @input="update"
            :readonly="getRights($entitiesName.Envelope).update ? false : true"
          />
          <date-field
            :label="$t('common.date')"
            :value="envelope.createdAt"
            :readonly="true"
          />
        </div>
        <div class="is-flex is-justify-space-between mt-40">
          <h4>{{ $t("common.documents") }}</h4>
          <div>
            <button
              class="button is-success white is-small mr-10"
              v-if="envelopeIsDraft"
              @click.stop="$refs.uploadDocumentModal.open"
              cy-data="upload"
            >
              <i class="fa fa-upload"></i>{{ $t("common.upload") }}
            </button>
            <button
              class="button is-primary white is-small"
              @click.stop="$refs.downloadContractTemplateModal.open()"
              cy-data="download-contract-template"
            >
              <i class="fa fa-download"></i>
              {{ $t("contractTemplates.downloadContractTemplate") }}
            </button>
          </div>
        </div>
        <documents-table
          v-if="envId"
          ref="doctab"
          :key="'doctab_' + documentsTableKey"
          :objectName="$entitiesName.Envelope"
          :objectId="envId"
          :deletable="
            getRights($entitiesName.Envelope).update && envelopeIsDraft
          "
          :showTopButtons="false"
          th-class="has-background-light"
          @updated="updateDocumentsCount"
        />
        <h4>{{ $t("common.validations") }}</h4>
        <validators-table
          v-if="envId"
          :envelopeId="envId"
          :key="'valitab_' + validatorsTableKey"
          @updated="updateValidatorsCount"
        />
      </div>
      <div class="column is-one-third" v-if="envId">
        <div class="level-right envelope-actions">
          <button
            class="button is-success white"
            v-if="
              userRoleOptions.includes(
                $roleOptions.ENVELOPES.CAN_SEND_FOR_SIGNATURE
              ) &&
              documentsCount > 0 &&
              envelopeIsDraft
            "
            @click.stop="$refs.sendToValidationModal.open"
            cy-data="send-to-validation"
          >
            {{ $t("envelopes.validation.sendToValidation") }}
          </button>
          <button
            class="button is-primary white"
            v-if="
              userRoleOptions.includes(
                $roleOptions.ENVELOPES.CAN_SEND_FOR_SIGNATURE
              ) && envelopIsValidated
            "
            @click.stop="$refs.sendToSignatureModal.open"
            cy-data="send-to-signature"
          >
            {{ $t("envelopes.signature.sendForSignature") }}
          </button>
          <button
            v-if="getRights($entitiesName.Envelope).delete"
            class="button is-danger"
            @click="$emit('delete')"
          >
            <i class="fa fa-trash"></i>
            {{ $t("common.delete") }}
          </button>
        </div>
        <workflow-history
          v-if="envId"
          :key="'workhis_' + workflowHistoryKey"
          v-model="workflowComment"
          :entity="envelope"
          :entityName="WorkflowCodes.ENV.NODE.OBJECT_NAME"
          :entityId="envId"
          :validators-count="validatorsCount"
          @updated="refreshValidatorsTableAndStatusWF"
        />
      </div>
    </div>
    <!-- modals  -->
    <download-contract-template-modal
      ref="downloadContractTemplateModal"
    ></download-contract-template-modal>

    <send-to-validation-modal
      ref="sendToValidationModal"
      :envelopeId="envId"
      @created="refreshValidatorsTableAndStatusWF"
    ></send-to-validation-modal>
    <send-to-signature-modal
      ref="sendToSignatureModal"
      :envelopeId="envId"
      @created="$emit('refresh')"
    ></send-to-signature-modal>
    <upload-document-modal
      ref="uploadDocumentModal"
      :envelopeId="envId"
      @created="refreshDocumentsTable"
    ></upload-document-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Workflows as WORKFLOWS } from "../constants/Workflow";

export default {
  name: "envelope-details",
  props: ["envelopeId", "envelope", "contractId", "loading"],
  data() {
    return {
      WorkflowCodes: WORKFLOWS,
      envId: this.envelopeId,
      workflowComment: "",
      documentsCount: 0,
      documentsTableKey: 0,
      workflowHistoryKey: 0,
      validatorsTableKey: 0,
      validatorsCount: 0,
    };
  },

  watch: {
    envelopeId(newVal, oldVal) {
      this.envId = newVal;
      this.refreshDocumentsTable();
      this.refreshValidatorsTable();
      this.refreshWorkflowHistory();
    },
  },

  computed: {
    ...mapGetters({
      getRights: "auth/getRights",
      userRoleOptions: "auth/getOptions",
    }),

    envelopeIsDraft() {
      return (
        this.envelope.statusWF.nodeCode &&
        this.envelope.statusWF.nodeCode === this.WorkflowCodes.ENV.NODE.DRAFT
      );
    },
    envelopIsValidated() {
      return (
        this.envelope.statusWF.nodeCode &&
        this.envelope.statusWF.nodeCode ===
          this.WorkflowCodes.ENV.NODE.VALIDATED
      );
    },
  },

  methods: {
    refreshDocumentsTable() {
      this.documentsTableKey += 1;
    },
    refreshValidatorsTable() {
      this.validatorsTableKey += 1;
    },
    refreshWorkflowHistory() {
      this.workflowHistoryKey += 1;
    },
    refreshValidatorsTableAndStatusWF() {
      this.refreshValidatorsTable();
      this.$emit("refresh");
      this.refreshWorkflowHistory();
    },
    updateDocumentsCount(v) {
      this.documentsCount = v;
    },
    updateValidatorsCount(v) {
      this.validatorsCount = v;
    },
    update(newValue) {
      this.$emit("update", newValue);
    },
  },
};
</script>

<style scoped>
.level-right {
  margin-bottom: 8px;
}
.level-right .button,
.envelope-actions .button {
  margin-left: 4px;
}

.mr-10 {
  margin-right: 10px;
}

.envelope-column {
  margin-left: 15px;
  padding-right: 0;
}

.mt-8 {
  margin-top: 8px;
}

.mt-40 {
  margin-top: 40px;
}

button > i {
  margin-right: 7px;
}

h4 {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 22px;
}
</style>
