<template>
  <div class="contract-templates">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.ContractTemplates">
        <h1 class="title">{{$t('entities.contractTemplates')}}</h1>
      </page-header>
      <div class="is-block is-full-width">
        <div class="controls is-pulled-right">
          <button class="button is-primary white"
                  @click.stop="openUploadModal"
                  data-test="sel-document-upload">
            {{$t('common.upload')}}&nbsp;
            <i class="fa fa-upload"></i>
          </button>
        </div>
        <datatable 
          ref="ContractTemplatesTable"
          :fetch="(p) => $api.fetchContractTemplates(p, filter=null)"
          :objectName="$entitiesName.ContractTemplates"
          order="DESC"
        >
          <div slot="templateName" slot-scope="{ item }" :title="$t('expressions.templateName')" :sortable="true">
            {{ item.templateName }}  
          </div>
          <div slot="documentType" slot-scope="{ item }" :title="$t('entities.documentType')" :sortable="true">
            {{ item.documentType && item.documentType.name }}
          </div>
          <div slot="createdAt" slot-scope="{ item }" :title="$t('expressions.createdAt')" type="date" :sortable="true" nofilter>
            {{ item.createdAt | reverseDate }}
          </div>
          <div slot="actions" slot-scope="{ item }">
            <form class="inline-form"
                  method="POST"
                  target="_blank"
                  :action="`${API}/contracttemplate/${item.id}`">
              <button class="button is-success is-small"
                      type="submit">
                {{$t('common.download')}}&nbsp;
                <i class="fa fa-download"></i>
              </button>
              <input type="hidden" name="token" :value="authToken" />
            </form>

            <button class="button is-danger is-small" @click="deleteDocument(item)">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </datatable>
        <modal
          ref="createDocumentModal"
          @create="uploadDocument"
          @close="closeModal"
          :title="$t('contractTemplates.addContractTemplate')"
        >
          <div class="columns">
            <div class="column is-half">
              <input :style="{ display: 'none'}"
                ref="documentFileInput"
                type="file"
                name="documentFile"
                accept="application/pdf"
                data-test="sel-document-file-input"
                @change="selectFile">
              <text-field :label="$t('expressions.templateName')"
                v-model="contractTemplate.templateName"
                :inline="false"
                :edit="true"
                required
              />

              <many2one-field :label="$t('entities.documentType')"
                v-model="contractTemplate.documentType"
                :fetch="$api.fetchDocumentTypes"
                reference="name"
                :columns="{ name: $t('common.name') }"
                :edit="true"
                data-test="sel-document-upload-type">
              </many2one-field>
            </div>
            <div class="column is-half">
              <div class="field">
                <button class="button is-primary" @click.stop="addFile">
                  {{$t('expressions.selectFile')}}&nbsp;
                  <i class="fa fa-choose"></i>
                </button>
              </div>
              <field readonly inline :label="$t('expressions.selectedFileName')">
                {{ selectedFileName }}
              </field>
            </div>
          </div>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ContractTemplates',
  inject: ['$validator'],
  props: {
    deletable: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      documents: [],
      createDocument: {
        file: null,
      },
      contractTemplate: {},
      API: axios.defaults.baseURL,
      authToken: this.$store.getters['auth/getToken'],
      documentTableColumns: [
        {
          title: this.$t('expressions.fileName'),
          name: 'templateName',
          accessor: 'templateName',
          filter: '',
        },
        {
          title: 'Date',
          name: 'date',
          accessor: 'createdAt',
          filter: '',
        },
      ],
      isLoading: false,
    };
  },
  computed: {
    selectedFileName () {
      if (this.createDocument.file) {
        return this.createDocument.file.name;
      }
      return ' ';
    },
  },
  mounted () {
  },
  methods: {
    uploadDocument () {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            const form = new FormData();
            if (this.createDocument.file === null) {
              alert("Veuillez sélectionner un fichier");
              return;
            }
            form.set('templateName', this.contractTemplate.templateName);
            form.set('documentTypeId', this.contractTemplate.documentType.id);
            form.set('file', this.createDocument.file, this.createDocument.file.name);
            axios
              .post('/contracttemplate/create', form)
              .then(() => {
                this.$refs.ContractTemplatesTable.refresh();
              })
              .finally(() => {
                this.$refs.createDocumentModal.close();
              });
          }
        }
      )

    },
    openUploadModal () {
      this.$refs.createDocumentModal.open();
    },
    addFile () {
      this.$refs.documentFileInput.click();
    },
    selectFile () {
      this.createDocument.file = this.$refs.documentFileInput.files[0];
    },
    closeModal () {
      this.createDocument = {
        documentType: null,
        file: null,
      };
      this.templateName = '';
    },
    deleteDocument (document) {
      return this.$awn.confirm(
        'Êtes-vous sûr de vouloir supprimer ce document ?',
        () =>
          axios.delete(`/contracttemplate/${document.id}`).then((response) => {
            if (response.data.success) {
              this.$refs.ContractTemplatesTable.refresh();
            }
          }),
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.is-full-width {
  width: 100%;
  flex: 1;
}

.white {
  color: #fff;
}

.field {
  margin-bottom: 0.75em;
}

.inline-form {
  display: inline;
}
td .is-pulled-right > * {
  margin-left: 4px;
}
</style>
