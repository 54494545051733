<template>
  <div class="home">s
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Project"
                   :create="openCreateModal">
        <h1 class="title">{{$t('common.projects')}}</h1>
      </page-header>

      <datatable :fetch="$api.fetchProjects"
                 ref="listProject"
                 :enableDownload="true"
                 objectName="projects">
        <div slot="name"
             slot-scope="{ item }"
             :title="$t('common.name')"
             :sortable="true">{{ item.name }}</div>
        <div slot="code"
             slot-scope="{ item }"
             :title="$t('common.code')"
             :sortable="true">{{ item.code }}</div>
        <div slot="type"
             slot-scope="{ item }"
             :title="$t('common.type')"
             :sortable="true">
          <span v-if="item.type">{{ item.type.name }}</span>
        </div>
        <div slot="manager"
             slot-scope="{ item }"
             :title="$t('common.manager')"
             :sortable="true">{{ item.manager }}</div>
        <div slot="actions"
             slot-scope="{ item }">
          <router-link :to="'/project/' + item.id"
                       class="button is-primary">
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>

    <modal ref="createProjectModal"
           @create="createProject"
           :title="$t('projects.projectCreation')">
      <div class="columns">
        <div class="column is-half">
          <text-field :label="$t('common.name')"
                      v-model="project.name"
                      :inline="false"
                      :edit="true"
                      required />
          <text-field :label="$t('common.code')"
                      v-model="project.code"
                      :inline="false"
                      :edit="true"
                      required
                      validate="min:3,max:100" />
          <many2one-field :label="$t('entities.oscarProject')"
                          v-model="project.oscarProject"
                          :fetch="$api.fetchOscarProjects"
                          reference="name"
                          entity="oscar_project"
                          :columns="{ name: $t('entities.oscarProject') }"
                          :edit="true"
                          :hasFilter="true"
                          :inline="false"
                          data-test="sel-project-oscar-project"></many2one-field>
          <selector-field :label="$t('common.type')"
                          v-model="project.type"
                          :options="$api.fetchProjectTypesAsList"
                          :inline="false"
                          :edit="true">
            <template slot-scope="{ option }">{{ option.name }}</template>
          </selector-field>
          <text-field :label="$t('common.manager')"
                      v-model="project.manager"
                      :inline="false"
                      :edit="true" />
          <textarea-field :label="$t('projects.directorAddress')"
                          v-model="project.address"
                          :inline="false"
                          :edit="true" />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'projects-list',
  data () {
    return {
      projects: [],
      project: {},
    };
  },
  methods: {
    openCreateModal () {
      this.$refs.createProjectModal.open();
    },
    createProject () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          axios.post('/project', this.project).then((response) => {
            if (response.data.success) {
              this.project = {};
              this.$refs.listProject.refresh();
              this.$refs.createProjectModal.close();
              this.$router.push({
                name: 'Project',
                params: { id: response.data.id },
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
