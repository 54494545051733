<template>
  <div class="deposits has-background-light">
    <div class="deposits-header">
      <h3 class="title is-5 has-text-primary">{{ $t('common.deposits') }}</h3>

      <button
        v-if="editable"
        class="button is-success is-small"
        @click="openAddDepositModal"
      ><i class="fa fa-plus"></i></button>
    </div>

    <table v-if="Object.keys(deposits).length > 0" class="table is-fullwidth has-background-light">
      <thead>
        <tr>
          <th>{{ $t('expressions.addedOn') }}</th>
          <th>{{ $t('common.reference') }}</th>
          <th class="has-text-right">{{ $t('common.amount') }}</th>
          <th>{{ $t('deposits.reimbursementDate') }}</th>
          <th v-if="editable"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(deposit, index) in deposits" >
          <DepositRow
            :deposit="deposit"
            :editable="editable"
            :key="'dep-' + index"
            @updated="$emit('updated')"
          />
          
          <tr v-for="(att, idx) in deposit.attachements" :key="'att_' + deposit.id + '_' + idx" class="is-size-7">
            <td></td>
            <td>{{ att.reference }}</td>
            <td class="has-text-right">{{ att.amount | priceEUR }}</td>
            <td></td>
          </tr>
        </template>
      </tbody>
    </table>

    <modal
      ref="addDepositModal"
      @create="addDeposit"
      :title="$t('deposits.addDeposit')"
    >
      <div class="columns">
        <div class="column is-half">
          <text-field 
            :label="$t('common.reference')"
            v-model="newDeposit.reference"
            :edit="true"
            :inline="false"
          />
          <numeric-field 
            type="price"
            :label="$t('deposits.depositAmount')"
            v-model="newDeposit.amount"
            :min="0"
            :edit="true"
            :inline="false"
            required
          />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import DepositRow from './DepositRow.vue'

export default {
  name: 'Deposits',
  components: {
    DepositRow
  },
  props: {
    deposits: {
      type: Object,
      default: () => {}
    },
    editable: {
      type: Boolean,
      default: false
    },
    add: Function
  },
  data() {
    return {
      newDeposit: {
        reference: null,
        amount: null
      }
    }
  },
  computed: {
  },
  methods: {
    openAddDepositModal() {
      this.$refs.addDepositModal.open();
    },
    closeAddDepositModal() {
      this.newDeposit.reference = null;
      this.newDeposit.amount = null;
      this.$refs.addDepositModal.close();
    },
    addDeposit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const deposit = {
            ...this.newDeposit,
            objectId: this.id
          }

          this.add(deposit).then(() => {
              this.closeAddDepositModal()
              this.$emit('added')
            })
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.deposits {
  align-self: flex-start;
  padding: 10px;
  border-radius: 5px;
}
.deposits-header {
  display: flex;
  & > * {
    margin-right: 5px;
  }
}
</style>