<template>
  <div>
    <paginate
      v-if="reversePagination === false"
      :options="paginateOptions"
      :show-options="showOptions && !selector"
      :paginate="pagination"
      @change="paginate"
      :bulk-actions="bulkActions"
      :selected="Array.isArray(selected) ? selected : []"
      :isSmall="isSmall"
    />
    <table class="table is-fullwidth" :class="{ 'is-narrow': isSmall }">
      <thead>
        <tr>
          <th
            class="index-column sortable"
            @click="sortBy({ sortBy: identifier, sortable: true })"
          >
            #
          </th>
          <th class="index-column"></th>
          <th
            v-for="(column, idx) in internalColumns"
            :key="idx"
            @click="sortBy(column)"
            :class="{
              sortable: column.sortable,
              'has-text-right': column.right,
              'small-column-text': isSmall,
            }"
          >
            {{ column.title }}
            <i
              v-if="
                pagination.sort.column === column.id ||
                pagination.sort.column === column.sortBy
              "
              class="fa"
              :class="{
                'fa-sort-down': pagination.sort.direction === 'ASC',
                'fa-sort-up': pagination.sort.direction === 'DESC',
              }"
            ></i>
            <span class="help" v-if="column.help" :title="column.help">?</span>
          </th>
          <th class="action-column"></th>
        </tr>
        <!-- Display bar only if necessary: hasFilters, enableDownload, hasSelection -->
        <tr
          v-if="enableDownload || hasFilters || (!singleSelect && !noSelection)"
          :class="{ 'has-background-yellow': isFiltered && objectName !== '' }"
        >
          <td>
            <span
              v-if="!singleSelect && enableDownload"
              @click.stop="$refs.download.click()"
            >
              <i class="fa fa-cloud-download"></i>
            </span>
          </td>
          <td>
            <input
              v-if="!singleSelect && !noSelection"
              type="checkbox"
              v-model="selectAll"
            />
          </td>
          <td
            v-for="(column, idx) in internalColumns"
            :key="idx"
            :cy-data="column.id"
            class="has-text-centered"
          >
            <input
              v-if="column.hasFilter"
              class="filter-text"
              :type="column.inputType"
              :name="column.id"
              v-model.trim="internalColumns[idx].filter"
              @input="updateFilter"
            />
            <checkbox-field
              :label="''"
              v-if="column.switcher"
              :checked="allSwitched(column.id)"
              @change="switchAll(column.id)"
              :inline="false"
            />
          </td>
          <td class="action-column"></td>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading">
          <td :colspan="internalColumns.length + 3" class="loader-container">
            <i class="loader"></i>
          </td>
        </tr>
        <tr v-if="tableSummary !== null" class="has-background-info-light">
          <td colspan="2">Totaux</td>
          <td
            v-for="(column, idx) in internalColumns"
            :key="'summary_' + idx"
            :cy-data="'summary_' + column.id"
            class="has-text-right"
          >
            <slot :name="'summary_' + column.id">
              <template v-if="tableSummary[column.id]">
                {{ tableSummary[column.id] | priceEUR }}
              </template>
            </slot>
          </td>
          <td></td>
        </tr>
        <tr
          v-for="(item, index) in items"
          :key="index"
          :class="{
            selected:
              selected &&
              (selected.id === item.id ||
                (!singleSelect &&
                  selected.findIndex((e) => e.id === item.id) !== -1)),
            selectable: selector || singleSelect,
          }"
          @click="
            !noSelection && (selector || singleSelect) && toggleSelect(item)
          "
        >
          <td>#{{ (pagination.page - 1) * pagination.size + index + 1 }}</td>
          <td>
            <input
              v-if="!singleSelect && !noSelection && item.id"
              type="checkbox"
              @click.stop
              @input="toggleSelect(item)"
              :checked="
                selected && selected.findIndex((e) => e.id === item.id) !== -1
              "
            />
            <i
              v-if="selected && selected.id === item.id"
              class="fa fa-check"
            ></i>
          </td>
          <td
            v-for="(column, idx) in internalColumns"
            :key="idx"
            :cy-data="column.id"
          >
            <slot :name="column.id" :item="item">
              <template v-if="!column.component">
                {{ displayItem(item, column.id) }}
              </template>
              <template v-else>
                <component
                  :is="column.component"
                  :value="displayItem(item, column.id)"
                />
              </template>
            </slot>
          </td>
          <td class="action-column">
            <slot name="actions" :item="item" :index="index"></slot>
          </td>
        </tr>
        <tr v-if="!loading && items.length == 0">
          <td :colspan="internalColumns.length + 3" class="no-data">
            <div>{{ emptyTableMessage }}</div>
          </td>
        </tr>
      </tbody>
    </table>
    <form
      v-if="enableDownload"
      :style="{ display: 'none' }"
      target="_blank"
      :action="`${API}/${objectName}/csv?${getParams()}&selected=${selectedToUrl}`"
      method="POST"
    >
      <button type="submit" ref="download"></button>
      <input type="hidden" name="token" :value="authToken" />
    </form>
    <paginate
      v-if="reversePagination"
      :options="paginateOptions"
      :show-options="showOptions && !selector"
      :paginate="pagination"
      @change="paginate"
      :bulk-actions="bulkActions"
      :selected="Array.isArray(selected) ? selected : []"
      :isSmall="isSmall"
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import PaginationBuilder from "../lib/Paginate";
import Paginate from "./Paginate";

export default {
  name: "datatable",
  components: {
    Paginate,
  },
  props: {
    fetch: Function,
    singleSelect: {
      type: Boolean,
      default: false,
    },
    selector: {
      type: Boolean,
      default: false,
    },
    noSelection: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Object, Array],
      default: () => null,
    },
    columns: {
      type: Object,
      default: () => null,
    },
    enableDownload: {
      type: Boolean,
      default: false,
    },
    objectName: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      default: 50,
    },
    filterColumns: {
      type: Boolean,
      default: false,
    },
    identifier: {
      type: String,
      default: "id",
    },
    customEmptyTableMessage: {
      type: String,
      default: "",
    },
    order: {
      type: String,
      default: "ASC",
    },
    externalFilters: {
      type: Object,
      default: () => null,
    },
    paginateOptions: {
      type: Array,
      default: () => [50, 100, 200],
    },
    showOptions: {
      type: Boolean,
      default: () => true,
    },
    bulkActions: {
      type: Array,
    },
    additionalDownloadParams: {
      type: String,
      default: "",
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    reversePagination: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      internalColumns: [],
      hasFilters: false,
      items: [],
      pagination: {
        page: 1,
        total: 0,
        size: this.size,
        sort: {
          column: this.identifier,
          direction: this.order,
        },
      },
      selectAll: false,
      selected: [],
      API: axios.defaults.baseURL,
      authToken: this.$store.getters["auth/getToken"],
      emptyTableMessage: this.customEmptyTableMessage,
      tableSummary: null,
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    value() {
      this.selected = this.value;
    },
    selectAll() {
      this.selected = [];
      if (this.selectAll === true) {
        this.items.forEach((item) => {
          this.selected.push(item);
        });
      }
      this.$emit("input", this.selected);
    },
    externalFilters(newFilters, oldFilters) {
      if (JSON.stringify(oldFilters) === JSON.stringify(newFilters)) return;
      this.refresh();
    },
  },
  computed: {
    ...mapGetters({
      getFilters: "filters/getFilters",
    }),
    selectedToUrl() {
      return this.selected
        ? this.singleSelect
          ? this.selected.id
          : this.selected.map((object) => object.id).toString()
        : "";
    },
    isFiltered() {
      let empty = true;
      this.internalColumns.forEach((column) => {
        if (column.filter !== "") {
          empty = false;
        }
      });
      return !empty;
    },
  },
  methods: {
    init() {
      this.selected = this.value;

      /* Columns are automatically determined by the children. */
      if (!this.columns) {
        Object.keys(this.$scopedSlots).forEach((key) => {
          const slot = this.$scopedSlots[key];
          if (!slot({ item: "test" })) return;
          const slotBlockData = slot({ item: "test" })[0].data;
          if (!slotBlockData.attrs) {
            return;
          }
          const t = slotBlockData.attrs.title;
          const nofilter = slotBlockData.attrs.nofilter != null;
          const switcher = slotBlockData.attrs.switcher != null;

          if (!nofilter) {
            this.hasFilters = true;
          }

          this.internalColumns.push({
            id: key,
            fn: slot,
            title: t,
            hasFilter: !nofilter,
            switcher: switcher,
            filter: "",
            inputType: slotBlockData.attrs.type
              ? slotBlockData.attrs.type
              : "text",
            sortable: slotBlockData.attrs.sortable || false,
            sortBy: slotBlockData.attrs.sortBy || key,
            help: slotBlockData.attrs.help ? slotBlockData.attrs.help : "",
            right: slotBlockData.attrs.right,
          });
        });
      } else {
        Object.keys(this.columns).forEach((id) => {
          let title = "";
          let filter = "";
          let hasFilter = this.filterColumns;
          if (typeof this.columns[id] === "string") {
            title = this.columns[id];
          } else {
            const column = this.columns[id];
            title = column.title;
            hasFilter = column.filter != null;
            filter = column.filter;
          }

          if (hasFilter) {
            this.hasFilters = true;
          }

          this.internalColumns.push({
            id,
            title,
            filter,
            hasFilter,
            inputType: this.columns[id].inputType || "text",
            sortable: this.columns[id].sortable || false,
            sortBy: this.columns[id].sortBy || this.key,
            component: this.columns[id].component || null,
          });
        });
      }
      const filters = this.getFilters(this.objectName);
      if (filters) {
        this.internalColumns = filters;
      }
      this.refresh();
    },
    refresh() {
      const p = this.preparePaginationObject();

      this.items = [];
      this.loading = true;
      this.fetch(p)
        .then(this.callback)
        .catch(this.fetchError)
        .finally(() => {
          this.$nextTick(() => {
            this.loading = false;
          });
        });
    },
    callback(data) {
      this.pagination.total = data.totalElements;
      this.items = Array.isArray(data.content) ? data.content : [];
      this.emptyTableMessage = this.$t("expressions.noData");
      this.tableSummary = data.summary || null;
    },
    fetchError() {
      this.emptyTableMessage = this.$t("expressions.fetchingError");
    },
    preparePaginationObject() {
      return {
        ...this.pagination,
        page: this.pagination.page - 1,
        sort: {
          ...this.pagination.sort,
        },
        filters: this.internalColumns.reduce(
          (filters, column) => {
            const allFilters = filters;
            if (typeof column.filter !== "undefined")
              allFilters[column.id] = column.filter;
            return filters;
          },
          { ...this.externalFilters }
        ),
      };
    },
    getParams() {
      const p = new PaginationBuilder(this.preparePaginationObject());
      return `${p.toString()}&${this.additionalDownloadParams}`;
    },
    paginate(p) {
      this.pagination = p;
      this.refresh();
    },
    displayItem(item, column) {
      const keys = column.split(".");
      let result = item;
      for (let i = 0; i < keys.length; i += 1) {
        const k = keys[i];
        if (!result[k]) {
          return "";
        }
        result = result[k];
      }
      return result;
    },
    toggleSelect(item) {
      if (this.singleSelect) {
        this.selected = item;
        this.$emit("input", this.selected);
        return;
      }

      if (!this.selected) {
        this.selected = [];
      }
      const idx =
        this.selected && this.selected.findIndex((e) => e.id === item.id);
      if (idx === -1) {
        this.selected.push(item);
      } else {
        this.selected.splice(idx, 1);
      }
      this.$emit("input", this.selected);
    },
    updateFilter: debounce(function refresh() {
      this.refresh();
      if (this.objectName !== "") {
        this.$store.dispatch("filters/setFilters", {
          name: this.objectName,
          filters: this.internalColumns,
        });
      }
    }, 500),
    download() {
      this.$emit("download", null);
    },
    sortBy(column) {
      const attribute = column.sortBy || column.id;

      if (!column.sortable) return;

      if (this.pagination.sort.column === attribute) {
        this.pagination.sort.direction =
          this.pagination.sort.direction === "ASC" ? "DESC" : "ASC";
      } else {
        this.pagination.sort.direction = "ASC";
        this.pagination.sort.column = attribute;
      }

      this.refresh();
    },
    switchAll(columnName) {
      let nextState = true;
      if (this.allSwitched(columnName)) {
        nextState = false;
      }

      this.items.forEach((item) => {
        item[columnName] = nextState;
      });
    },
    allSwitched(columnName) {
      return this.items.every((item) => !!item[columnName]);
    },
  },
};
</script>

<style lang="scss" scoped>
$primary: #019fc4;

.index-column {
  width: 20px;
}
.table th {
  text-align: center;
}

tbody {
  font-size: 14px;
}

.action-column {
  width: 50px;
  .button {
    height: 20px;
  }
}

.loader-container {
  font-size: 30px;
  text-align: center;
  background-color: #fff;
  .loader {
    margin: 0 auto;
  }
}

.no-data {
  text-align: center;
  background-color: #fff;
  color: #777;
  padding: 40px 0;
}

.selectable {
  cursor: pointer;
}

.selected {
  background-color: #cdffe7;
}

.fa-cloud-download {
  color: $primary;
  font-size: 24px;
}

input[type="text"] {
  width: 100%;
  height: 26px;
  font-size: 12px;
  line-height: 22px;
  padding: 2px 5px;
  margin: 0;
}

input[type="date"] {
  width: 100%;
  height: 26px;
  font-size: 12px;
  line-height: 22px;
  padding: 2px 5px;
  margin: 0;
}

.sortable {
  cursor: pointer;
  color: rgb(50, 115, 220);
  i {
    margin-left: 5px;
  }
}
.help {
  display: inline-block;
  color: white;
  background-color: #019fc4;
  border-radius: 50%;
  padding: 0 6px;
  cursor: pointer;
}

.small-column-text {
  font-size: 0.85rem;
}
</style>
