<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Quantitative">
        <h1 class="title">
          {{ $t("common.quantitatives") }}
        </h1>
      </page-header>

      <datatable
        :fetch="$api.fetchQuantitatives"
        ref="quantitativesList"
        enableDownload
        objectName="quantitatives"
      >
        <div
          slot="project"
          slot-scope="{ item: { project } }"
          :title="$t('common.project')"
        >
          <restricted-link
            v-if="project"
            entity="project"
            :to="`/project/${project.id}`"
          >
            {{ project.name }}
          </restricted-link>
        </div>

        <div
          slot="subcontractorMarket"
          slot-scope="{ item: { subcontractorMarket } }"
          :title="$t('common.contractorAgreement')"
        >
          <restricted-link
            v-if="subcontractorMarket"
            entity="market_sst"
            :to="`/sst/market/${subcontractorMarket.id}`"
          >
            {{ subcontractorMarket.edifice }}
          </restricted-link>
        </div>

        <div
          slot="imputation"
          slot-scope="{ item: { imputation } }"
          :title="$t('common.imputation')"
        >
          <span v-if="imputation">
            {{ imputation.eotp }}
          </span>
        </div>

        <div
          slot="arcgis"
          slot-scope="{ item: { oscarSubProject } }"
          :title="$t('oscarSubProjects.columns.arcgisName')"
          v-if="ActiveProfile.isFTTH"
        >
          {{ oscarSubProject && oscarSubProject.arcgisName }}
        </div>

        <div
          slot="nro"
          slot-scope="{ item: { oscarSubProject } }"
          :title="$t('common.nro')"
          v-if="ActiveProfile.isFTTH"
        >
          {{ oscarSubProject && oscarSubProject.nroName }}
        </div>

        <div
          slot="pm"
          slot-scope="{ item: { oscarSubProject } }"
          :title="$t('common.pm')"
          v-if="ActiveProfile.isFTTH"
        >
          {{ oscarSubProject && oscarSubProject.offerIdPm }}
        </div>

        <div
          slot="reference"
          slot-scope="{ item }"
          :title="$t('common.reference')"
        >
          <span v-if="item.prestation">
            {{ item.prestation.reference.name }}
          </span>
        </div>

        <div
          slot="designation"
          slot-scope="{ item }"
          :title="$t('common.designation')"
        >
          <span v-if="item.prestation">
            {{ item.prestation.description }}
          </span>
        </div>

        <div
          slot="unit"
          slot-scope="{ item: { unit } }"
          :title="$t('common.unit')"
        >
          <restricted-link v-if="unit" entity="unit" :to="`/unit/${unit.id}`">
            {{ unit.name }}
          </restricted-link>
        </div>

        <div
          slot="unitPrice"
          slot-scope="{ item }"
          :title="$t('prestations.columns.unitPrice')"
          class="has-text-right"
        >
          {{ item.unitPrice | priceEUR }}
        </div>

        <div
          slot="quantityOrdered"
          slot-scope="{ item }"
          :title="$t('quantitatives.quantityOrdered')"
          class="has-text-right"
        >
          {{ item.quantityOrdered }}
        </div>

        <div
          slot="cumulatedQuantity"
          slot-scope="{ item }"
          :title="$t('quantitatives.cumulatedQuantity')"
          class="has-text-right"
        >
          {{ item.quantityDone }}
        </div>

        <div
          slot="orderedAmount"
          slot-scope="{ item }"
          :title="$t('quantitatives.orderedAmount')"
          class="has-text-right"
        >
          {{ item.amountOrdered | priceEUR }}
        </div>

        <div
          slot="cumulatedAmount"
          slot-scope="{ item }"
          :title="$t('quantitatives.cumulativeAmount')"
          class="has-text-right"
        >
          {{ item.amountDone | priceEUR }}
        </div>
      </datatable>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      ActiveProfile: "auth/getActiveProfile",
    }),
  },
};
</script>
