import { render, staticRenderFns } from "./AgenciesList.vue?vue&type=template&id=e19b00d4&scoped=true&"
import script from "./AgenciesList.vue?vue&type=script&lang=js&"
export * from "./AgenciesList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e19b00d4",
  null
  
)

export default component.exports