<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.OscarSubProject">
        <h1 class="title">{{$t('entities.oscarSubProjects')}}</h1>
      </page-header>

      <datatable :fetch="$api.fetchOscarSubProjects"
                 :enableDownload="true"
                 :objectName="$entitiesName.OscarSubProject">
        <div slot="name"
             slot-scope="{ item }"
             :title="$t('common.name')">{{ item.name }}</div>
        <div slot="arcgisName"
             slot-scope="{ item }"
             :title="$t('oscarSubProjects.columns.arcgisName')">{{ item.arcgisName }}</div>
        <div slot="nroName"
             slot-scope="{ item }"
             :title="$t('common.nro')">{{ item.nroName }}</div>
        <div slot="ipeIdPm"
             slot-scope="{ item }"
             :title="$t('oscarSubProjects.columns.pmIpeId')">{{ item.ipeIdPm }}</div>
        <div slot="offerIdPm"
             slot-scope="{ item }"
             :title="$t('oscarSubProjects.columns.pmOfferId')">{{ item.offerIdPm }}</div>

        <div slot="actions"
             slot-scope="{ item }">
          <router-link :to="'/oscarsubproject/' + item.id"
                       class="button is-primary">
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>
  </div>
</template>

<script>
export default {
  name: 'oscar-subproject-list',
  data () {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped>
</style>
