<template>
  <div
    class="navbar is-fixed-top is-primary"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <router-link to="/" class="navbar-item logo">
        <img src="../assets/logo.png" />
      </router-link>

      <a
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarLeft"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarLeft" class="navbar-menu">
      <div class="navbar-start" v-if="user.role">
        <div
          class="navbar-item has-dropdown is-hoverable"
          v-for="(mainmenu, index) in user.role.menus"
          :key="index"
          :data-test="`sel-nav-${mainmenu.id}`"
        >
          <a class="navbar-link is-arrowless">
            {{ mainmenu.title }}
          </a>

          <div class="navbar-dropdown">
            <router-link
              :to="menu.path"
              class="navbar-item"
              :data-test="`sel-subnav-${menu.id}`"
              v-for="(menu, idx) in mainmenu.menus"
              :key="idx"
            >
              {{ menu.title }}
            </router-link>
          </div>
        </div>
      </div>

      <div class="navbar-end">
        <div
          v-if="userRoleOptions.includes('userinterface.display_help_menu')"
          class="navbar-item has-dropdown is-hoverable"
        >
          <a class="navbar-link is-arrowless">
            <span class="help-icon"><i class="fa fa-life-ring"></i></span>
            {{ $t("common.help") }}
          </a>

          <div class="navbar-dropdown">
            <a class="dropdown-item" @click="openEmailModal()">
              {{ $t("expressions.reportIncident") }}
            </a>
            <a class="dropdown-item" :href="user.userManualUrl" target="blank">
              {{ $t("expressions.userManuals") }}
            </a>
          </div>
        </div>

        <!--
        <div class="navbar-item is-hoverable">
          <a class="navbar-link is-arrowless" @click="sendNotification">Send</a>
        </div>
        -->

        <NotificationsMenu />

        <div
          class="navbar-item dropdown"
          :class="{ 'is-active': showProfiles }"
        >
          <div class="dropdown-trigger">
            <button
              v-if="user.role"
              class="button tag is-warning"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              @click.stop="toggleSelector"
            >
              {{ user.role.name }}
            </button>
          </div>
          <div class="navbar-dropdown" id="dropdown-menu" role="menu">
            <a
              class="dropdown-item"
              @click="changeProfile(profile)"
              v-for="(profile, idx) in profiles"
              :key="idx"
            >
              {{ profile.role.name }}
            </a>
          </div>
        </div>

        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link is-arrowless">
            <span class="avatar">{{
              user && user.name ? user.name[0] : "A"
            }}</span>
            {{ user ? user.name : "" }}
          </a>

          <div class="navbar-dropdown">
            <router-link to="/account-manage" class="navbar-item" cy-data="account-manage"
                         v-if="userRoleOptions.includes($roleOptions.USERS.CAN_DELEGATE) || userRoleOptions.includes($roleOptions.USERS.CAN_ADMIN_DELEGATE)">
              {{ $t("common.accountManage") }}
            </router-link>
            <router-link to="/logout" class="navbar-item">
              {{ $t("common.disconnect") }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import NotificationsMenu from "@/components/NotificationsMenu";

export default {
  name: "Navbar",
  components: { NotificationsMenu },
  data() {
    return {
      showProfiles: false,
      helpOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/getActiveProfile",
      userRoleOptions: "auth/getOptions",
      profiles: "auth/getAvailableProfiles",
      token: "auth/getToken",
    }),
  },
  watch: {
    showProfiles(val) {
      if (val) {
        window.addEventListener("click", this.toggleSelector);
      } else {
        window.removeEventListener("click", this.toggleSelector);
      }
    },
  },
  methods: {
    changeProfile(profile) {
      axios
        .post("/user/change-profile", { username: profile.uid })
        .then((response) => {
          if (this.token !== response.data.success) {
            const payload = {
              token: response.data.success,
              activeProfile: profile,
            };
            this.$store.commit("auth/setAuth", payload);
            this.$router.push("/");
          }
        });
    },
    toggleSelector() {
      if (this.profiles.length === 0) {
        return;
      }

      this.showProfiles = !this.showProfiles;
    },
    openEmailModal() {
      this.$emit("onRTClick");
    },
    sendNotification() {
      axios
        .post("/notification/send/test")
        .then((response) => {
          alert(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  background-color: #fff;
  padding: 5px 15px;
  img {
    height: 42px;
    max-height: 52px;
  }
  &:hover {
    background-color: #fff !important;
  }
}

.navbar-item {
  padding-left: 10px;
  padding-right: 10px;
  .tag {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
  }

  .avatar {
    display: block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    padding-top: 3px;
    border-radius: 100%;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    background-color: #f68121;
  }
}
.help-icon {
  margin-right: 10px;
  .fa {
    font-size: 20px;
  }
}
</style>
