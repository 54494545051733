<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.ProjectType"
                   :create="openCreateModal">
        <h1 class="title">{{$t('entities.projectTypes')}}</h1>
      </page-header>

      <datatable :fetch="$api.fetchProjectTypes"
                 ref="listProjectTypes"
                 identifier="name">
        <div slot="name"
             slot-scope="{ item }"
             :title="$t('common.name')"
             :sortable="true">
          <span>{{ item.name }}</span>
        </div>
        <div slot="actions"
             slot-scope="{ item }">
          <router-link :to="'/projecttype/' + item.id"
                       class="button is-primary">
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>

    <modal ref="createProjectTypeModal"
           @create="createProjectType"
           :title="$t('projectTypes.projectTypeCreation')">
      <div class="columns">
        <div class="column is-half">
          <text-field :label="$t('common.name')"
                      v-model="projectType.name"
                      :inline="false"
                      :edit="true"
                      required></text-field>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'project-type-list',
  data () {
    return {
      projectType: {},
    };
  },
  methods: {
    openCreateModal () {
      this.$refs.createProjectTypeModal.open();
    },
    createProjectType () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          axios.post('/projecttype', this.projectType).then((response) => {
            if (response.data.success) {
              this.projectType = {};
              this.$refs.listProjectTypes.refresh();
              this.$refs.createProjectTypeModal.close();
              this.$router.push({
                name: 'ProjectType',
                params: { id: response.data.id },
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
</style>

