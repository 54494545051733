<template>
  <field
    :edit="edit"
    :inline="inline"
    :label="label"
    :value="val"
    :readonly="readonly"
    :required="required"
    :type="type"
    :cy-data="cyDataString"
  >
    <div class="field has-addons">
      <p class="control">
        <input
          class="input"
          type="number"
          @input="input"
          v-model="val"
          :required="required"
          v-validate="validate"
          :name="internal_id"
          :data-vv-as="label"
          :class="{ error: errors.has(internal_id) }"
          :min="min"
          :cy-data="'input-' + cyDataString"
        />
      </p>
      <p class="control">
        <span class="button is-static">{{ unit }}</span>
      </p>
    </div>
    <div v-if="errors.has(internal_id)" class="message is-danger">
      <div class="message-body">{{ errors.first(internal_id) }}</div>
    </div>
  </field>
</template>

<script>
import uid from "uniqid";

export default {
  name: "numeric-field",
  inject: ["$validator"],
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "number",
    },
    label: {
      type: String,
      default: "",
    },
    value: {},
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: String,
      default: "",
    },
    min: {
      type: Number,
      default: null,
    },
    unit: {
      type: String,
      default: null,
    },
    cyData: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      val: "",
      internal_id: uid(),
    };
  },
  computed: {
    cyDataString() {
      const fieldLabel = this.cyData ? this.cyData : this.label;
      if (!fieldLabel) {
        return "";
      }
      return fieldLabel.replaceAll(/[ ']/g, "");
    },
  },
  mounted() {
    this.val = this.value;
  },
  watch: {
    value(val) {
      this.val = val;
    },
  },
  methods: {
    input() {
      this.$emit("input", this.val.trim());
    },
  },
};
</script>

<style lang='scss' scoped>
.input {
  max-width: 500px;
}

.error {
  border: 2px solid red;
}
</style>
