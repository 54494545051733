import { render, staticRenderFns } from "./OscarSubProjectList.vue?vue&type=template&id=ed1868ee&scoped=true&"
import script from "./OscarSubProjectList.vue?vue&type=script&lang=js&"
export * from "./OscarSubProjectList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed1868ee",
  null
  
)

export default component.exports