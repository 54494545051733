<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Notification">
        <h1 class="title">{{ $t("entities.notifications") }}</h1>
      </page-header>

      <div
        v-for="(notification, index) in notifications"
        :key="index"
        @click="setAsRead(notification)"
      >
        <component
          :is="notificationTemplates[notification.template] || empty"
          v-bind="notification"
        />
      </div>

      <Paginate
        :paginate="pagination"
        @change="paginate"
        :showOptions="false"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TestNotification from "@/components/notifications/TestNotification.vue";
import EmptyNotification from "@/components/notifications/EmptyNotification.vue";
import PopupNotification from "@/components/notifications/PopupNotification.vue";
import PaginationBuilder from "@/lib/Paginate";
import Paginate from "@/components/Paginate";

export default {
  name: "NotificationList",
  components: {
    Paginate,
  },
  data() {
    return {
      notifications: [],
      empty: EmptyNotification,
      notificationTemplates: {
        test_notification: TestNotification,
        popup_notification: PopupNotification,
      },
      pagination: {
        page: 1,
        total: 0,
        size: 20,
        sort: {
          column: "id",
          direction: "DESC",
        },
      },
    };
  },

  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      const p = new PaginationBuilder(this.preparePaginationObject());
      axios.get(`/notification/list?${p}`).then((response) => {
        this.notifications = response.data.content;
        this.pagination.total = response.data.totalElements;

        setTimeout(() => {
          this.notifications.forEach((notification) => {
            this.setAsRead(notification);
          });
        }, 2000);
      });
    },
    preparePaginationObject() {
      return {
        ...this.pagination,
        page: this.pagination.page - 1,
        sort: {
          ...this.pagination.sort,
        },
        filters: [],
      };
    },
    paginate(p) {
      this.pagination = p;
      this.refresh();
    },
    setAsRead(notification) {
      if (notification.readedAt === null) {
        axios.post(`/notification/read/${notification.id}`).then(() => {
          notification.readedAt = new Date().toISOString();
        });
      }
    },
  },
};
</script>
