const GlobalState = {
  namespaced: true,
  state: {
    loading: 0,
    editMode: false,
  },
  getters: {
    isLoading(state) {
      return state.loading > 0;
    },
    isEdition(state) {
      return state.editMode;
    },
    getEnv() {
      return process.env;
    },
  },
  actions: {
    setLoading({ commit }, loading) {
      commit('SET_LOADING', loading);
    },
    setEdition({ commit }, edition) {
      commit('SET_EDITION', edition);
    },
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading += loading ? 1 : -1;
    },
    SET_EDITION(state, edition) {
      state.editMode = edition;
    },
  },
};

export default GlobalState;
