<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$entitiesName.Attachement"
        :create="openCreateModal"
      >
        <h1 class="title">{{ $t("common.attachments") }}</h1>
      </page-header>

      <div v-if="hasMyActions" class="level">
        <div class="field has-addons">
          <p class="control">
            <a
              cy-data="view_all"
              class="button"
              :class="{ 'is-primary': toDoFilter === 'all' }"
              @click="setToDoFilter('all')"
              >{{ $t("common.all") }}</a
            >
          </p>

          <p class="control">
            <a
              cy-data="my_scope"
              class="button"
              :class="{ 'is-primary': toDoFilter === 'canDo' }"
              @click="setToDoFilter('canDo')"
              >{{ $t("expressions.myScope") }}</a
            >
          </p>

          <p class="control">
            <a
              cy-data="my_tasks"
              class="button"
              :class="{ 'is-primary': toDoFilter === 'haveToDo' }"
              @click="setToDoFilter('haveToDo')"
              >{{ $t("expressions.myTasks") }}</a
            >
          </p>
        </div>
      </div>

      <datatable
        ref="Attachements"
        :fetch="$api.fetchAttachements"
        :enableDownload="true"
        objectName="attachements"
        order="DESC"
        :externalFilters="{ filter: filter }"
      >
        <div
          slot="reference"
          slot-scope="{ item }"
          :title="$t('common.reference')"
          :sortable="true"
        >
          {{ item.reference }}
        </div>
        <div
          slot="order.name"
          slot-scope="{ item: { order } }"
          :title="$t('orders.columns.complement')"
          :sortable="true"
        >
          <span v-if="order">{{ order.name }}</span>
        </div>
        <div
          slot="market"
          slot-scope="{ item: { market } }"
          :title="$t('common.contractorAgreement')"
          :sortable="true"
          sortBy="order.market.edifice"
        >
          <span v-if="market">{{ market.edifice }}</span>
        </div>
        <div
          slot="market.project"
          slot-scope="{ item: { market } }"
          :title="$t('common.project')"
          :sortable="true"
          sortBy="order.market.project.name"
        >
          <span v-if="market && market.project">{{ market.project.name }}</span>
        </div>
        <div
          slot="market.contractNumber"
          slot-scope="{ item }"
          :title="$t('orders.columns.contractNumber')"
          :sortable="true"
        >
          {{ item.market && item.market.contractNumber }}
        </div>
        <div
          slot="order.clientMarket"
          slot-scope="{ item: { order } }"
          :title="$t('orders.columns.clientMarket')"
        >
          <span v-if="order">{{ order.clientMarket }}</span>
        </div>
        <div
          slot="order.client_bdc"
          slot-scope="{ item: { order } }"
          :title="$t('orders.columns.bdcCustomer')"
        >
          <span v-if="order">{{ order.clientBdc }}</span>
        </div>
        <div
          slot="oscarSubProject"
          slot-scope="{ item: { oscarSubProject } }"
          :title="$t('oscarSubProjects.columns.oscarSubProject')"
          v-if="activeProfile.isFTTH"
        >
          <span v-if="oscarSubProject">{{ oscarSubProject.name }}</span>
        </div>
        <div
          slot="arcgis"
          slot-scope="{ item: { oscarSubProject } }"
          :title="$t('oscarSubProjects.columns.arcgisName')"
          v-if="activeProfile.isFTTH"
        >
          <span v-if="oscarSubProject">{{ oscarSubProject.arcgisName }}</span>
        </div>
        <div
          slot="nro"
          slot-scope="{ item: { oscarSubProject } }"
          :title="$t('common.nro')"
          v-if="activeProfile.isFTTH"
        >
          <span v-if="oscarSubProject">{{ oscarSubProject.nroName }}</span>
        </div>
        <div
          slot="pm"
          slot-scope="{ item: { oscarSubProject } }"
          :title="$t('common.pm')"
          v-if="activeProfile.isFTTH"
        >
          <span v-if="oscarSubProject">{{ oscarSubProject.offerIdPm }}</span>
        </div>
        <div
          slot="imputation"
          slot-scope="{ item: { imputation } }"
          :title="$t('common.imputation')"
          :sortable="true"
          sortBy="order.imputation.eotp"
        >
          <span v-if="imputation">{{ imputation.eotp }}</span>
        </div>
        <div
          slot="follower"
          slot-scope="{ item: { follower } }"
          :title="$t('orders.columns.managedBy')"
          :sortable="true"
          sortBy="order.follower.name"
        >
          <span v-if="follower">{{ follower.name }}</span>
        </div>
        <div
          slot="amount"
          slot-scope="{ item }"
          :title="$t('common.amount')"
          class="nowrap has-text-right"
          :sortable="true"
        >
          {{ item.amount | priceEUR }}
        </div>
        <div
          slot="statusWF"
          slot-scope="{ item }"
          :title="$t('common.status')"
          class="nowrap"
        >
          <span v-if="item.statusWF">
            <router-link
              :to="'/node/' + item.nodeId"
              :title="item.statusWF.createdAt | date"
              >{{ item.nodeName }}</router-link
            >
            ({{ item.statusWF.createdAt | date }})
          </span>
        </div>
        <div
          slot="creator"
          slot-scope="{ item }"
          :title="$t('common.creator')"
          :sortable="true"
          sortBy="creator.name"
        >
          {{ item.creator }}
        </div>

        <div slot="actions" slot-scope="{ item }">
          <router-link
            :to="'/sst/attachement/' + item.id"
            class="button is-primary"
          >
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>

    <modal
      ref="createAttachementModal"
      @create="checkOSAttachmentCreationDate"
      :title="$t('attachments.attachmentCreation')"
    >
      <div class="columns">
        <div class="column is-half">
          <many2one-field
            :label="$t('common.order')"
            :title="$t('attachments.validatedOrders')"
            v-model="create.order"
            :fetch="$api.fetchValidatedOrders"
            reference="name"
            :columns="orderMany2OneColumns"
            :inline="true"
            :edit="true"
            :hasFilter="true"
            required
            v-validate="{ required: true }"
            name="orders2one"
            :error="errors.has('orders2one')"
          ></many2one-field>
          <textarea-field
            :label="$t('common.observation')"
            v-model="create.observation"
            :inline="false"
            :edit="true"
          ></textarea-field>
          <checkbox-field
            :label="$t('common.dgd')"
            v-model="create.isDGD"
            :inline="true"
            :edit="true"
          ></checkbox-field>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";

export default {
  name: "attachements",
  data() {
    return {
      create: {
        reference: null,
        observation: null,
        order: null,
        isDGD: false,
      },
      orderMany2OneColumns: {
        reference: this.$t("common.reference"),
        "market.edifice": this.$t("orders.columns.sstMarket"),
        "oscarSubProject.name": this.$t(
          "oscarSubProjects.columns.oscarSubProject"
        ),
        "oscarSubProject.arcgisName": this.$t(
          "oscarSubProjects.columns.arcgisReference"
        ),
        "oscarSubProject.nroName": this.$t("common.nro"),
        "oscarSubProject.offerIdPm": this.$t("common.pm"),
      },
      toDoFilter: "haveToDo",
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      hasMyActions: "auth/hasMyActions",
      activeProfile: "auth/getActiveProfile",
    }),
    filter() {
      if (!this.hasMyActions) {
        return "all";
      }
      return this.toDoFilter;
    },
  },
  methods: {
    openCreateModal() {
      this.$refs.createAttachementModal.open();
    },
    createAttachement() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const created = Object.assign({}, this.create);
          axios
            .post("/attachement", created)
            .then((res) => {
              this.create = {
                reference: null,
                observation: null,
                order: null,
                isDGD: false,
              };
              this.closeCreateModal();
              //this.$refs.Attachements.refresh();
              this.$router.push({
                name: "Attachement",
                params: { id: res.data.id },
              });
            })
            .catch((e) => {
              // eslint-disable-next-line
              console.error(e);
            });
        }
      });
    },
    closeCreateModal() {
      this.$refs.createAttachementModal.close();
    },
    setToDoFilter(action) {
      this.toDoFilter = action;
    },
    checkOSAttachmentCreationDate() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }

        if (!this.create.order.lastAttachment) {
          this.createAttachement();
          return;
        }

        const daysSinceLastAttachment = moment().diff(
          moment(this.create.order.lastAttachment.createdAt),
          "days"
        );
        if (daysSinceLastAttachment > 30) {
          this.createAttachement();
          return;
        }

        this.$awn.confirm(
          this.$t("attachments.sameMonthAttachment"),
          () => {
            this.createAttachement();
          },
          () => {}
        );
      });
    },
  },
};
</script>

<style scoped>
.level {
  display: flex;
  justify-content: flex-end;
}
</style>
