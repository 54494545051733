<template>
  <modal
    ref="uploadDocumentModal"
    @close="resetDocument"
    :loading="isUploading"
    @create="uploadDocument"
    :title="$t('expressions.addDocument')"
  >
    <div class="field">
      <button
        class="button is-primary"
        @click.stop="addFile"
        :disabled="isUploading"
        aria-label="Select File"
      >
        {{ $t("expressions.selectFile") }}
        <i class="fa fa-choose" aria-hidden="true"></i>
      </button>
    </div>
    <field readonly inline :label="$t('expressions.selectedFileName')">
      {{ selectedFileName }}
    </field>
    <input
      :style="{ display: 'none' }"
      ref="documentFileInput"
      type="file"
      name="documentFile"
      accept="*/*"
      cy-data="file-input"
      @change="selectFile"
    />
    <many2one-field
      :label="$t('entities.documentType')"
      v-model="document.documentType"
      :fetch="$api.fetchDocumentTypes"
      reference="name"
      :columns="documentTypeMany2OneColumns"
      :edit="true"
      cy-data="upload-type"
    ></many2one-field>
  </modal>
</template>

<script>
import axios from "axios";

export default {
  name: "UploadDocumentModal",
  props: {
    envelopeId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      isUploading: false,
      document: {
        documentType: null,
        file: null,
      },
      documentTypeMany2OneColumns: {
        name: this.$t("common.name"),
      },
    };
  },
  computed: {
    selectedFileName() {
      return this.document.file
        ? this.document.file.name
        : this.$t("expressions.noFileSelected");
    },
    isFormValid() {
      return this.document.file && this.document.documentType;
    },
  },
  methods: {
    open() {
      this.$openModal("uploadDocumentModal");
    },
    selectFile() {
      this.document.file = this.$refs.documentFileInput.files[0];
    },
    addFile() {
      this.$refs.documentFileInput.click();
    },
    async uploadDocument() {
      if (!this.isFormValid) {
        // eslint-disable-next-line
        alert(this.$t("expressions.completeAllFields"));
        return;
      }

      this.isUploading = true;
      const form = new FormData();
      const { file, documentType } = this.document;

      form.set("file", file, file.name);
      form.set("type", documentType.id);
      form.set("entity", this.$entitiesName.Envelope);
      form.set("id", this.envelopeId);

      try {
        await axios.post("document", form);
        this.$closeModal("uploadDocumentModal");
        this.$emit("created");
      } catch (error) {
        console.error("Error uploading document:", error);
        // eslint-disable-next-line
        alert(this.$t("expressions.uploadFailed"));
      } finally {
        this.isUploading = false;
      }
    },
    resetDocument() {
      this.document.file = null;
      this.document.documentType = null;
    },
  },
};
</script>
