<template>
  <div>
    <div class="rating-label">
      {{ label }}
    </div>
    <div class="is-size-7">
      {{ subLabel }}
    </div>
    <div class="star-rating">
      <i
        v-for="star in stars"
        :key="star"
        :class="['fa', starClass(star)]"
        aria-hidden="true"
        @click="setRating(star)"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    numberOfStars: {
      type: Number,
      default: 5,
    },
    label: {
      type: String,
      default: "",
    },
    subLabel: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      rating: 0,
    };
  },
  computed: {
    stars() {
      return Array.from({ length: this.numberOfStars }, (v, i) => i + 1);
    },
  },
  methods: {
    starClass(star) {
      return this.rating >= star ? "fa-star" : "fa-star-o";
    },
    setRating(star) {
      this.rating = star;
      this.$emit("rating-selected", this.rating);
    },
  },
};
</script>

<style scoped>
.star-rating {
  display: flex;
  margin-top: 5px;
}
.star-rating .fa {
  cursor: pointer;
  margin-right: 5px;
  font-size: 24px;
  color: gold;
}

.rating-label {
  font-weight: bold;
  vertical-align: top;
  padding-right: 4px;
}
</style>
