<template>
  <div>
    <canvas ref="radarChart"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default {
  name: "RadarChart",
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    chartData: {
      handler(newData) {
        if (this.chart) {
          this.chart.data = newData;
          this.chart.update();
        } else {
          this.renderChart();
        }
      },
      deep: true,
    },
  },
  methods: {
    renderChart() {
      this.chart = new Chart(this.$refs.radarChart, {
        type: "radar",
        data: this.chartData,
        options: this.options,
      });
    },
  },
};
</script>

<style scoped>
canvas {
  width: 100%;
  height: 100%;
}
</style>
