var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"container is-fluid"},[_c('page-header',{attrs:{"entity":_vm.$entitiesName.Attachement,"create":_vm.openCreateModal}},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("common.attachments")))])]),(_vm.hasMyActions)?_c('div',{staticClass:"level"},[_c('div',{staticClass:"field has-addons"},[_c('p',{staticClass:"control"},[_c('a',{staticClass:"button",class:{ 'is-primary': _vm.toDoFilter === 'all' },attrs:{"cy-data":"view_all"},on:{"click":function($event){return _vm.setToDoFilter('all')}}},[_vm._v(_vm._s(_vm.$t("common.all")))])]),_c('p',{staticClass:"control"},[_c('a',{staticClass:"button",class:{ 'is-primary': _vm.toDoFilter === 'canDo' },attrs:{"cy-data":"my_scope"},on:{"click":function($event){return _vm.setToDoFilter('canDo')}}},[_vm._v(_vm._s(_vm.$t("expressions.myScope")))])]),_c('p',{staticClass:"control"},[_c('a',{staticClass:"button",class:{ 'is-primary': _vm.toDoFilter === 'haveToDo' },attrs:{"cy-data":"my_tasks"},on:{"click":function($event){return _vm.setToDoFilter('haveToDo')}}},[_vm._v(_vm._s(_vm.$t("expressions.myTasks")))])])])]):_vm._e(),_c('datatable',{ref:"Attachements",attrs:{"fetch":_vm.$api.fetchAttachements,"enableDownload":true,"objectName":"attachements","order":"DESC","externalFilters":{ filter: _vm.filter }},scopedSlots:_vm._u([{key:"reference",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.reference'),"sortable":true}},[_vm._v(" "+_vm._s(item.reference)+" ")])}},{key:"order.name",fn:function(ref){
var order = ref.item.order;
return _c('div',{attrs:{"title":_vm.$t('orders.columns.complement'),"sortable":true}},[(order)?_c('span',[_vm._v(_vm._s(order.name))]):_vm._e()])}},{key:"market",fn:function(ref){
var market = ref.item.market;
return _c('div',{attrs:{"title":_vm.$t('common.contractorAgreement'),"sortable":true,"sortBy":"order.market.edifice"}},[(market)?_c('span',[_vm._v(_vm._s(market.edifice))]):_vm._e()])}},{key:"market.project",fn:function(ref){
var market = ref.item.market;
return _c('div',{attrs:{"title":_vm.$t('common.project'),"sortable":true,"sortBy":"order.market.project.name"}},[(market && market.project)?_c('span',[_vm._v(_vm._s(market.project.name))]):_vm._e()])}},{key:"market.contractNumber",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('orders.columns.contractNumber'),"sortable":true}},[_vm._v(" "+_vm._s(item.market && item.market.contractNumber)+" ")])}},{key:"order.clientMarket",fn:function(ref){
var order = ref.item.order;
return _c('div',{attrs:{"title":_vm.$t('orders.columns.clientMarket')}},[(order)?_c('span',[_vm._v(_vm._s(order.clientMarket))]):_vm._e()])}},{key:"order.client_bdc",fn:function(ref){
var order = ref.item.order;
return _c('div',{attrs:{"title":_vm.$t('orders.columns.bdcCustomer')}},[(order)?_c('span',[_vm._v(_vm._s(order.clientBdc))]):_vm._e()])}},{key:"oscarSubProject",fn:function(ref){
var oscarSubProject = ref.item.oscarSubProject;
return (_vm.activeProfile.isFTTH)?_c('div',{attrs:{"title":_vm.$t('oscarSubProjects.columns.oscarSubProject')}},[(oscarSubProject)?_c('span',[_vm._v(_vm._s(oscarSubProject.name))]):_vm._e()]):_vm._e()}},{key:"arcgis",fn:function(ref){
var oscarSubProject = ref.item.oscarSubProject;
return (_vm.activeProfile.isFTTH)?_c('div',{attrs:{"title":_vm.$t('oscarSubProjects.columns.arcgisName')}},[(oscarSubProject)?_c('span',[_vm._v(_vm._s(oscarSubProject.arcgisName))]):_vm._e()]):_vm._e()}},{key:"nro",fn:function(ref){
var oscarSubProject = ref.item.oscarSubProject;
return (_vm.activeProfile.isFTTH)?_c('div',{attrs:{"title":_vm.$t('common.nro')}},[(oscarSubProject)?_c('span',[_vm._v(_vm._s(oscarSubProject.nroName))]):_vm._e()]):_vm._e()}},{key:"pm",fn:function(ref){
var oscarSubProject = ref.item.oscarSubProject;
return (_vm.activeProfile.isFTTH)?_c('div',{attrs:{"title":_vm.$t('common.pm')}},[(oscarSubProject)?_c('span',[_vm._v(_vm._s(oscarSubProject.offerIdPm))]):_vm._e()]):_vm._e()}},{key:"imputation",fn:function(ref){
var imputation = ref.item.imputation;
return _c('div',{attrs:{"title":_vm.$t('common.imputation'),"sortable":true,"sortBy":"order.imputation.eotp"}},[(imputation)?_c('span',[_vm._v(_vm._s(imputation.eotp))]):_vm._e()])}},{key:"follower",fn:function(ref){
var follower = ref.item.follower;
return _c('div',{attrs:{"title":_vm.$t('orders.columns.managedBy'),"sortable":true,"sortBy":"order.follower.name"}},[(follower)?_c('span',[_vm._v(_vm._s(follower.name))]):_vm._e()])}},{key:"amount",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"nowrap has-text-right",attrs:{"title":_vm.$t('common.amount'),"sortable":true}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.amount))+" ")])}},{key:"statusWF",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"nowrap",attrs:{"title":_vm.$t('common.status')}},[(item.statusWF)?_c('span',[_c('router-link',{attrs:{"to":'/node/' + item.nodeId,"title":_vm._f("date")(item.statusWF.createdAt)}},[_vm._v(_vm._s(item.nodeName))]),_vm._v(" ("+_vm._s(_vm._f("date")(item.statusWF.createdAt))+") ")],1):_vm._e()])}},{key:"creator",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.creator'),"sortable":true,"sortBy":"creator.name"}},[_vm._v(" "+_vm._s(item.creator)+" ")])}},{key:"actions",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('router-link',{staticClass:"button is-primary",attrs:{"to":'/sst/attachement/' + item.id}},[_c('i',{staticClass:"fa fa-search"})])],1)}}],null,true)})],1),_c('modal',{ref:"createAttachementModal",attrs:{"title":_vm.$t('attachments.attachmentCreation')},on:{"create":_vm.checkOSAttachmentCreationDate}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('many2one-field',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"label":_vm.$t('common.order'),"title":_vm.$t('attachments.validatedOrders'),"fetch":_vm.$api.fetchValidatedOrders,"reference":"name","columns":_vm.orderMany2OneColumns,"inline":true,"edit":true,"hasFilter":true,"required":"","name":"orders2one","error":_vm.errors.has('orders2one')},model:{value:(_vm.create.order),callback:function ($$v) {_vm.$set(_vm.create, "order", $$v)},expression:"create.order"}}),_c('textarea-field',{attrs:{"label":_vm.$t('common.observation'),"inline":false,"edit":true},model:{value:(_vm.create.observation),callback:function ($$v) {_vm.$set(_vm.create, "observation", $$v)},expression:"create.observation"}}),_c('checkbox-field',{attrs:{"label":_vm.$t('common.dgd'),"inline":true,"edit":true},model:{value:(_vm.create.isDGD),callback:function ($$v) {_vm.$set(_vm.create, "isDGD", $$v)},expression:"create.isDGD"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }