<template>
  <field
    :edit="edit"
    :inline="inline"
    :label="label"
    :value="val"
    :readonly="readonly"
    :required="required"
    :cy-data="cyDataString"
  >
    <span slot="value" :cy-data="'field-' + cyDataString + '-display'">
      <template v-if="type === 'percentage'">{{ value }} %</template>
      <template v-else-if="type === 'number'">{{ value | number }}</template>
      <template v-else-if="type === 'price'">{{ value | priceEUR }}</template>
      <template v-else>{{ value }}</template>
    </span>

    <input
      v-if="!readonly"
      class="input"
      :type="type"
      @input="input"
      v-model="val"
      :required="required"
      v-validate="validate"
      :name="internal_id"
      :data-vv-as="label"
      :class="{ error: errors.has(internal_id) }"
      :cy-data="'input-' + cyDataString"
    />
    <div v-if="errors.has(internal_id)" class="message is-danger">
      <div class="message-body">{{ errors.first(internal_id) }}</div>
    </div>
    <div v-if="conflict" class="message is-danger">
      <div class="message-body">{{ conflict }}</div>
    </div>
  </field>
</template>

<script>
import uid from "uniqid";

export default {
  name: "text-field",
  inject: ["$validator"],
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "",
    },
    value: {},
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validate: {
      // eslint-disable-next-line
      type: String | Object,
      default: "",
    },
    conflict: {
      type: String,
      default: "",
    },
    cyData: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      val: "",
      internal_id: uid(),
    };
  },
  computed: {
    cyDataString() {
      const fieldLabel = this.cyData || this.label;
      if (!fieldLabel) {
        return "";
      }
      return fieldLabel.replaceAll(/[ ']/g, "");
    },
  },
  mounted() {
    this.val = this.value;
  },
  watch: {
    value(val) {
      this.val = val;
    },
  },
  methods: {
    input() {
      this.$emit("input", this.val.trim());
    },
  },
};
</script>

<style lang='scss' scoped>
.input {
  max-width: 500px;
}

.error {
  border: 2px solid red;
}
</style>
