<template>
  <table class="table is-fullwidth">
    <thead>
      <tr class="has-background-light">
        <th :style="{ width: '5%' }">#</th>
        <th>{{ $t("common.name") }}</th>
        <th>{{ $t("common.role") }}</th>
        <th>{{ $t("common.status") }}</th>
        <th>{{ $t("expressions.validationDate") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="!validators.length">
        <td :colspan="5" class="has-text-centered">
          {{ $t("common.noRecords") }}
        </td>
      </tr>
      <tr
        v-for="(validator, idx) in validators"
        :key="'validator_' + validator.id + '_' + idx"
      >
        <td>{{ idx + 1 }}</td>
        <td>{{ validator.user.name }}</td>
        <td>{{ validator.user.role.name }}</td>
        <td>
          <checkbox-field :readonly="true" v-model="validator.isValidated" />
        </td>
        <td>{{ validator.validationDate | datetime }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import axios from "axios";
export default {
  name: "validators-table",
  props: {
    envelopeId: [String, Number],
  },
  data() {
    return {
      validators: [],
    };
  },
  computed: {},
  mounted() {
    this.fetchValidators();
  },
  methods: {
    async fetchValidators() {
      if (!this.envelopeId) return;
      const res = await axios.get(`/envelope/${this.envelopeId}/validators`);
      this.validators = res.data;
      this.$emit("updated", this.validators.length);
    },
  },
  watch: {
    edit(val) {
      console.log("edit", val);
    },
  },
};
</script>
