<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Popup">
        <h1 class="title">{{ $t("common.popups") }}</h1>
        <div slot="actions">
          <button class="button is-primary" cy-data="cy-popup-create" @click="create">{{$t('common.create')}}</button>
        </div>
      </page-header>
      <datatable
        :fetch="$api.fetchPopupNotifications"
        ref="listPopupNotifications"
        :enableDownload="true"
        objectName="popupNotification"
      >
        <div
          slot="title"
          slot-scope="{ item }"
          :title="$t('notifications.title')"
          :sortable="true"
        >
          {{ item.title }}
        </div>

        <div
          slot="active"
          slot-scope="{ item }"
          :title="$t('notifications.active')"
          :sortable="true"
        >
          <field isCheckbox :isChecked="item.active" />
        </div>
        <div slot="actions" slot-scope="{ item }">
          <router-link :to="'/popups-management/' + item.id" class="button is-primary">
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>
  </div>
</template>
<script>
export default {
  name: 'popup-list',
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    create() {
      this.$router.replace("/popups-management/new");
    }
  }
}
</script>
