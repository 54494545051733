<template>
  <NotificationContainer :readedAt="readedAt">
    <div class="notification-detail">
      <p>
        <b>{{ context.name }}</b>
      </p>
      <p class="text-muted">
        You can go to my profile
        <router-link :to="'/user/' + creator.id">here</router-link>
        {{ context.content }}
      </p>
      <span class="text-muted"
        ><small>{{ createdAt | fromNow }}</small></span
      >
    </div>
  </NotificationContainer>
</template>

<script>
export default {
  name: "TestNotification",
  props: {
    readedAt: {},
    context: {},
    creator: {},
    template: {},
    createdAt: {},
    user: {},
  },
};
</script>

<style lang="scss" scoped>

  .notification-detail {
    p {
      margin-bottom: 5px;
      line-height: 1.2;
    }
  }

</style>
