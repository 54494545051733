<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.User" :create="openCreateModal">
        <h1 class="title">{{ $t("entities.users") }}</h1>
      </page-header>

      <datatable
        :fetch="$api.fetchUsers"
        ref="userList"
        :enableDownload="true"
        objectName="users"
      >
        <div
          slot="name"
          slot-scope="{ item }"
          :title="$t('common.name')"
          :sortable="true"
        >
          {{ item.name }}
        </div>
        <div
          slot="mail"
          slot-scope="{ item }"
          :title="$t('common.email')"
          :sortable="true"
        >
          {{ item.mail }}
        </div>
        <div
          slot="role"
          slot-scope="{ item }"
          :title="$t('common.profile')"
          :sortable="true"
        >
          <span v-if="item.role">{{ item.role.name }}</span>
        </div>
        <div
          slot="manager.name"
          slot-scope="{ item }"
          :title="$t('users.columns.manager')"
          :sortable="true"
        >
          <restricted-link
            v-if="item.manager"
            :entity="$entitiesName.User"
            :to="`/user/${item.manager.id}`"
          >
            {{ item.manager.name }} ({{ item.manager.quality }})
          </restricted-link>
        </div>
        <div slot="service" slot-scope="{ item }" :title="$t('common.service')">
          {{ item.service }}
        </div>
        <div slot="quality" slot-scope="{ item }" :title="$t('common.quality')">
          {{ item.quality }}
        </div>
        <div
          slot="active"
          slot-scope="{ item }"
          :title="$t('common.active')"
          :nofilter="true"
        >
          <checkbox-field v-model="item.active" :edit="false" :inline="true" />
        </div>
        <div slot="actions" slot-scope="{ item }">
          <router-link :to="'/user/' + item.id" class="button is-primary">
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>

    <modal
      ref="createUserModal"
      @create="createUser"
      :title="$t('users.addUser')"
    >
      <div class="columns">
        <div class="column is-half">
          <text-field
            :label="$t('users.ldapCommonName')"
            v-model="user.cn"
            :inline="false"
            :edit="true"
            data-test="sel-ldap-cn"
            required
          />
          <text-field
            :label="$t('common.phone')"
            data-test="sel-phone"
            v-model="user.phone"
            :inline="false"
            :edit="true"
          />

          <selector-field
            :label="$t('common.profile')"
            v-model="user.role"
            :options="$api.fetchRolesAsList"
            :inline="false"
            :edit="true"
            :preselect="true"
            data-test="sel-user-role"
            required
          >
            <template slot-scope="{ option }">{{ option.name }}</template>
          </selector-field>

          <article
            v-if="user.role"
            slot="helper"
            class="message"
            :class="{
              'is-danger': user.role.visibility == 1,
              'is-warning':
                user.role.visibility > 1 && user.role.visibility < 4,
              'is-info': user.role.visibility == 4,
            }"
          >
            <div class="message-body">{{ user.role.description }}</div>
          </article>
          <many2one-field
            :label="$t('users.columns.manager')"
            v-model="user.manager"
            :fetch="$api.fetchUsers"
            reference="name"
            @click="(item) => $router.push('/user/' + item.id)"
            :columns="columns"
            :inline="false"
            :edit="true"
            data-test="sel-user-manager"
          ></many2one-field>

          <text-field
            :label="$t('users.authorizationAmount')"
            type="number"
            v-model="user.engagement"
            :inline="false"
            :edit="true"
            data-test="sel-user-engagement"
            required
          />

          <text-field
            :label="$t('common.service')"
            data-test="sel-user-service"
            v-model="user.service"
            :inline="false"
            :edit="true"
            required
          />

          <text-field
            :label="$t('common.quality')"
            data-test="sel-user-quality"
            v-model="user.quality"
            :inline="false"
            :edit="true"
            required
          />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "users-list",
  inject: ["$validator"],
  data() {
    return {
      user: {
        cn: "",
        phone: "",
        role: null,
        manager: null,
        engagement: null,
      },
      columns: {
        name: {
          title: this.$t("common.name"),
          filter: "",
        },
        mail: {
          title: this.$t("common.email"),
          filter: "",
        },
        "manager.name": {
          title: this.$t("common.manager"),
          filter: "",
        },
        "role.name": this.$t("entities.role"),
        service: {
          title: this.$t("common.service"),
          filter: "",
        },
        quality: {
          title: this.$t("common.quality"),
          filter: "",
        },
      },
    };
  },
  methods: {
    openCreateModal() {
      this.$refs.createUserModal.open();
    },
    createUser() {
      // eslint-disable-next-line
      console.log(this.$validator);
      // eslint-disable-next-line
      this.$validator.validateAll().then((result) => {
        if (result) {
          axios.post("/user/create", this.user).then((response) => {
            if (response.data.success) {
              this.user = {
                uid: "",
                phone: "",
                role: null,
                manager: null,
                engagement: null,
              };
              this.$refs.userList.refresh();
              this.$refs.createUserModal.close();
              this.$router.push({
                name: "User",
                params: { id: response.data.id },
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.infos,
.agencies {
  display: inline-block;
}

.infos {
  width: 34%;
}

.agencies {
  width: 60%;
}
</style>
