var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"container is-fluid"},[_c('page-header',{attrs:{"entity":_vm.$entitiesName.Quantitative}},[_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("common.quantitatives"))+" ")])]),_c('datatable',{ref:"quantitativesList",attrs:{"fetch":_vm.$api.fetchQuantitatives,"enableDownload":"","objectName":"quantitatives"},scopedSlots:_vm._u([{key:"project",fn:function(ref){
var project = ref.item.project;
return _c('div',{attrs:{"title":_vm.$t('common.project')}},[(project)?_c('restricted-link',{attrs:{"entity":"project","to":("/project/" + (project.id))}},[_vm._v(" "+_vm._s(project.name)+" ")]):_vm._e()],1)}},{key:"subcontractorMarket",fn:function(ref){
var subcontractorMarket = ref.item.subcontractorMarket;
return _c('div',{attrs:{"title":_vm.$t('common.contractorAgreement')}},[(subcontractorMarket)?_c('restricted-link',{attrs:{"entity":"market_sst","to":("/sst/market/" + (subcontractorMarket.id))}},[_vm._v(" "+_vm._s(subcontractorMarket.edifice)+" ")]):_vm._e()],1)}},{key:"imputation",fn:function(ref){
var imputation = ref.item.imputation;
return _c('div',{attrs:{"title":_vm.$t('common.imputation')}},[(imputation)?_c('span',[_vm._v(" "+_vm._s(imputation.eotp)+" ")]):_vm._e()])}},{key:"arcgis",fn:function(ref){
var oscarSubProject = ref.item.oscarSubProject;
return (_vm.ActiveProfile.isFTTH)?_c('div',{attrs:{"title":_vm.$t('oscarSubProjects.columns.arcgisName')}},[_vm._v(" "+_vm._s(oscarSubProject && oscarSubProject.arcgisName)+" ")]):_vm._e()}},{key:"nro",fn:function(ref){
var oscarSubProject = ref.item.oscarSubProject;
return (_vm.ActiveProfile.isFTTH)?_c('div',{attrs:{"title":_vm.$t('common.nro')}},[_vm._v(" "+_vm._s(oscarSubProject && oscarSubProject.nroName)+" ")]):_vm._e()}},{key:"pm",fn:function(ref){
var oscarSubProject = ref.item.oscarSubProject;
return (_vm.ActiveProfile.isFTTH)?_c('div',{attrs:{"title":_vm.$t('common.pm')}},[_vm._v(" "+_vm._s(oscarSubProject && oscarSubProject.offerIdPm)+" ")]):_vm._e()}},{key:"reference",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.reference')}},[(item.prestation)?_c('span',[_vm._v(" "+_vm._s(item.prestation.reference.name)+" ")]):_vm._e()])}},{key:"designation",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.designation')}},[(item.prestation)?_c('span',[_vm._v(" "+_vm._s(item.prestation.description)+" ")]):_vm._e()])}},{key:"unit",fn:function(ref){
var unit = ref.item.unit;
return _c('div',{attrs:{"title":_vm.$t('common.unit')}},[(unit)?_c('restricted-link',{attrs:{"entity":"unit","to":("/unit/" + (unit.id))}},[_vm._v(" "+_vm._s(unit.name)+" ")]):_vm._e()],1)}},{key:"unitPrice",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('prestations.columns.unitPrice')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.unitPrice))+" ")])}},{key:"quantityOrdered",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('quantitatives.quantityOrdered')}},[_vm._v(" "+_vm._s(item.quantityOrdered)+" ")])}},{key:"cumulatedQuantity",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('quantitatives.cumulatedQuantity')}},[_vm._v(" "+_vm._s(item.quantityDone)+" ")])}},{key:"orderedAmount",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('quantitatives.orderedAmount')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.amountOrdered))+" ")])}},{key:"cumulatedAmount",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('quantitatives.cumulativeAmount')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.amountDone))+" ")])}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }