var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"container is-fluid"},[_c('page-header',{attrs:{"entity":_vm.$entitiesName.Dc4}},[_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('dc4.title'))+" ")])]),_c('datatable',{ref:"dc4List",attrs:{"fetch":_vm.$api.fetchDc4,"enableDownload":true,"objectName":"budget/dc4"},scopedSlots:_vm._u([{key:"project",fn:function(ref){
var project = ref.item.project;
return _c('div',{attrs:{"title":_vm.$t('common.project')}},[(project)?_c('restricted-link',{attrs:{"entity":"project","to":("/project/" + (project.id))}},[_vm._v(" "+_vm._s(project.name)+" ")]):_vm._e()],1)}},{key:"subcontractorMarket",fn:function(ref){
var subcontractorMarket = ref.item.subcontractorMarket;
return _c('div',{attrs:{"title":_vm.$t('common.edifice')}},[(subcontractorMarket)?_c('restricted-link',{attrs:{"entity":"subcontractor_market","to":("/sst/market/" + (subcontractorMarket.id))}},[_vm._v(" "+_vm._s(subcontractorMarket.edifice)+" ")]):_vm._e()],1)}},{key:"maxAmountDc4Ht",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('markets.columns.maxAmountDC4')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.maxAmountDc4Ht))+" ")])}},{key:"amountPayedAttachments",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('dc4.attachmentsPaid')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.amountPayedAttachments))+" ")])}},{key:"amountMonthlyStatementAttachments",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('dc4.readyToPayAttachments')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.amountMonthlyStatementAttachments))+" ")])}},{key:"amountValidatedAttachments",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('dc4.validatedAttachments')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.amountValidatedAttachments))+" ")])}},{key:"validatedAttachmentPayedMonthlyStatementAmountWithoutTax",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"nofilter":"","title":_vm.$t('dc4.allAttachments')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.totalAttachementAmount))+" ")])}},{key:"availableOnDc4",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"nofilter":"","title":_vm.$t('dc4.availableDC4')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.availableOnDc4))+" ")])}},{key:"getRadAmount",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"nofilter":"","title":_vm.$t('common.rad')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.radAmount))+" ")])}},{key:"availableOnDc4WithRAD",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('dc4.availableDC4WithRad')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.availableOnDc4WithRAD))+" ")])}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }