const GlobalFilters = {
  namespaced: true,
  state: {
  },
  getters: {
    // eslint-disable-next-line
    getFilters: (state) => (name) => state[name] || null,
  },
  actions: {
    setFilters({ commit }, object) {
      commit('SET_FILTERS', object);
    },
  },
  mutations: {
    SET_FILTERS(state, object) {
      let empty = true;
      object.filters.forEach((column) => {
        if (column.filter !== '') {
          empty = false;
        }
      });

      if (empty && state[object.name]) {
        delete state[object.name];
      } else {
        state[object.name] = object.filters;
      }
    },
  },
};

export default GlobalFilters;
