<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Category" :create="openCreateModal">
        <h1 class="title">{{ $t("common.category") }}</h1>

        <template slot="actions">
          <button @click="dedupe" class="button is-warning">
            {{ $t("categories.mergeDuplicates") }}
          </button>
        </template>
      </page-header>

      <datatable
        :fetch="$api.fetchCategories"
        ref="datatable"
        objectName="categories"
        identifier="parent.name"
      >
        <div
          slot="parent"
          slot-scope="{ item }"
          :title="$t('categories.parentCategory')"
          :sortable="true"
          sortBy="parent.name"
        >
          {{ item.parent ? item.parent.name : "" }}
        </div>
        <div
          slot="name"
          slot-scope="{ item }"
          :title="$t('common.name')"
          :sortable="true"
        >
          {{ item.name }}
        </div>
        <div slot="actions" slot-scope="{ item }">
          <router-link :to="'/category/' + item.id" class="button is-primary">
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>

    <modal
      ref="createCategoryModal"
      @create="createCategory"
      :title="$t('categories.categoryCreation')"
    >
      <div class="columns">
        <div class="column is-half">
          <many2one-field
            :label="$t('categories.parentCategory')"
            v-model="category.parent"
            :fetch="$api.fetchParentCategories"
            reference="name"
            @click="(item) => $router.push('/categories/' + item.id)"
            entity="category"
            :columns="columnsCategories"
            :hasFilter="true"
            v-validate="{ required: false }"
            name="category2one"
            :error="errors.has('category2one')"
            :inline="false"
            :edit="true"
          ></many2one-field>
          <text-field
            :label="$t('common.name')"
            v-model="category.name"
            :inline="false"
            :edit="true"
            required
          />
        </div>

        <div class="column is-half"></div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "categories-list",
  data() {
    return {
      category: {},
      columnsCategories: {
        name: this.$t("common.name"),
      },
    };
  },
  methods: {
    openCreateModal() {
      this.$refs.createCategoryModal.open();
    },
    createCategory() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          axios.post("/category", this.category).then((response) => {
            if (response.data.success) {
              this.$refs.datatable.refresh();
              this.$refs.createCategoryModal.close();
              this.$router.push({
                name: "Category",
                params: { id: response.data.id },
              });
            }
          });
        }
      });
    },
    dedupe() {
      const self = this;
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir fusionner les catégories ayant les même noms ?",
        () =>
          axios.post("/category/dedupe").then((response) => {
            if (response.data.success) {
              self.$refs.datatable.refresh();
              self.$awn.success("Catégories fusionnées avec succès !");
            }
          })
      );
    },
  },
};
</script>

<style scoped>
</style>
