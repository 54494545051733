<template>
  <div class="navbar-item has-dropdown is-hoverable">
    <a class="navbar-link is-arrowless notification-menu">
      <i class="fa fa-bell text-muted"></i>
      <span v-if="unreadCount > 0" class="unread">{{ unreadCount }}</span>
    </a>
    <div class="navbar-dropdown dropdown-right">
      <div
        class="notification-container has-text-black"
        @mouseover="markAsRead"
      >
        <div class="notification-ui_dd-content">
          <div
            v-for="(notification, index) in notifications"
            :key="index"
            @click="setAsRead(notification.id, notification.readedAt)"
          >
            <component
              :is="notificationTemplates[notification.template] || empty"
              v-bind="notification"
            />
          </div>
        </div>
        <div v-if="notifications.length === 0">Aucune notification</div>
        <div v-if="notifications.length > 0" class="text-center">
          <router-link
            to="/notifications"
            class="notification-get-all has-text-black"
          >
            Voir toutes les notifications
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import TestNotification from "./notifications/TestNotification.vue";
import EmptyNotification from "./notifications/EmptyNotification.vue";
import PopupNotification from "./notifications/PopupNotification.vue";

export default {
  name: "NotificationsMenu",
  components: {
    TestNotification,
    EmptyNotification,
    PopupNotification,
  },
  data() {
    return {
      unreadCount: 0,
      notifications: [],
      large: false,
      empty: EmptyNotification,
      notificationTemplates: {
        test_notification: TestNotification,
        popup_notification: PopupNotification,
      },
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
    }),
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.getLastNotifications();

      // Loop every 30sec to refresh the notifications
      setTimeout(() => {
        this.refresh();
      }, 30 * 1000);
    },
    markAsRead() {
      setTimeout(() => {
        this.notifications.forEach((notification) => {
          this.setAsRead(notification);
        });
      }, 100);
    },
    getLastNotifications() {
      if (!this.isAuthenticated) {
        return;
      }
      axios.get("/notification/latest").then((response) => {
        this.notifications = response.data.notifications;
        this.unreadCount = response.data.unread;
      });
    },

    setAsRead(notification) {
      if (notification.readedAt === null) {
        notification.readedAt = new Date().toISOString();
        axios.post(`/notification/read/${notification.id}`).then(() => {
          notification.readedAt = new Date().toISOString();
          this.unreadCount--;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.unread {
  position: absolute;
  background-color: #f00;
  color: #fff;
  padding: 0;
  text-align: center;
  min-width: 18px;
  min-height: 18px;
  font-size: 10px;
  border: 1px solid #f00;
  border-radius: 100%;
  top: 5px;
  right: 0;
  font-weight: bold;
}

.notification-ui_dd-content {
  width: 400px;
  height: auto;
  max-height: 80vh;
  overflow: auto;
}

.dropdown-right {
  right: 0px;
  left: unset;
}

.notification-get-all {
  display: flex;
  margin-top: 25px;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
