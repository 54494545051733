<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.RgCaution">
        <h1 class="title">
          {{$t('warranties.title')}}
        </h1>
      </page-header>

      <datatable :fetch="$api.fetchRgCaution"
                 ref="rgCautionList"
                 :enableDownload="true"
                 objectName="budget/warranties">
        <div slot="project"
             slot-scope="{ item: { project } }"
             :title="$t('common.project')">
          <restricted-link v-if="project"
                           entity="project"
                           :to="`/project/${project.id}`">
            {{ project.name }}
          </restricted-link>
        </div>
        <div slot="subcontractorMarket"
             slot-scope="{ item: { subcontractorMarket } }"
             :title="$t('orders.columns.sstMarket')">
          <restricted-link v-if="subcontractorMarket"
                           entity="subcontractor_market"
                           :to="`/sst/market/${subcontractorMarket.id}`">
            {{ subcontractorMarket.edifice }}
          </restricted-link>
        </div>
        <div slot="depositType"
             slot-scope="{ item }"
             :title="$t('warranties.depositType')"
             class="">
          {{ item.depositType }}
        </div>
        <div slot="attachementAmount"
             slot-scope="{ item }"
             :title="$t('warranties.attachementAmount')"
             class="has-text-right">
          {{ item.attachementAmount | priceEUR }}
        </div>
        <div slot="rg"
             slot-scope="{ item }"
             :title="$t('warranties.totalRetention')"
             class="has-text-right">
          {{ item.rg | priceEUR }}
        </div>
        <div slot="rgReleased"
             slot-scope="{ item }"
             :title="$t('warranties.releasedAmount')"
             class="has-text-right">
          {{ item.rgReleased | priceEUR }}
        </div>
        <div slot="rgLeft"
             slot-scope="{ item }"
             :title="$t('warranties.remainingAmount')"
             class="has-text-right">
          {{ item.rgLeft | priceEUR }}
        </div>
        <div slot="depositAmount"
             slot-scope="{ item }"
             :title="$t('warranties.bondAmount')"
             class="has-text-right">
          {{ item.depositAmount | priceEUR }}
        </div>
        <div slot="rgLeftToRelease"
             slot-scope="{ item }"
             :title="$t('warranties.withheldAmount')"
             class="has-text-right">
          {{ item.rgLeftToRelease | priceEUR }}
        </div>
      </datatable>
    </div>
  </div>
</template>

<script>
export default {
  name: 'warranties',
};
</script>
