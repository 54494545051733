<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Company"
                   :create="openCreateModal">
        <h1 class="title">{{$t('common.company')}}</h1>
      </page-header>

      <datatable :fetch="$api.fetchCompanies"
                 ref="datatable"
                 :enableDownload="true"
                 objectName="companies">
        <div slot="name"
             slot-scope="{ item }"
             :title="$t('common.name')"
             :sortable="true">{{ item.name }}</div>
        <div slot="siren"
             slot-scope="{ item }"
             :title="$t('common.siren')"
             :sortable="true">{{ item.siren }}</div>
        <div slot="manager"
             slot-scope="{ item }"
             :title="$t('common.manager')"
             :sortable="true">{{ item.manager }}</div>
        <div slot="edifice"
             slot-scope="{ item }"
             :title="$t('common.edifice')"
             :sortable="true">{{ item.edifice }}</div>
        <div slot="mail"
             slot-scope="{ item }"
             :title="$t('common.email')"
             :sortable="true">{{ item.mail }}</div>
        <div slot="actions"
             slot-scope="{ item }">
          <router-link :to="'/sst/company/' + item.id"
                       class="button is-primary">
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>

    <modal ref="createCompanyModal"
           @create="createCompany"
           :title="$t('companies.companyCreation')">
      <div class="columns">
        <div class="column is-half">
          <text-field :label="$t('companies.columns.edificeCode')"
                      v-model="company.edifice"
                      :inline="false"
                      :edit="true"
                      required />
          <text-field :label="$t('common.name')"
                      v-model="company.name"
                      :inline="false"
                      :edit="true"
                      required />
          <text-field :label="$t('common.siren')"
                      v-model="company.siren"
                      :inline="false"
                      :edit="true" />
        </div>

        <div class="column is-half">
          <text-field :label="$t('common.manager')"
                      v-model="company.manager"
                      :inline="false"
                      :edit="true" />
          <text-field :label="$t('common.email')"
                      v-model="company.mail"
                      :inline="false"
                      type="email"
                      :edit="true" />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'companies-list',
  data () {
    return {
      company: {},
    };
  },
  methods: {
    openCreateModal () {
      this.$refs.createCompanyModal.open();
    },
    createCompany () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          axios.post('/company/create', this.company).then((response) => {
            if (response.data.success) {
              this.$refs.datatable.refresh();
              this.$refs.createCompanyModal.close();
              this.$router.push({
                name: 'Company',
                params: { id: response.data.id },
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
