<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="logo">
          <img src="../assets/logo.png" />
          <h1 class="title">{{ $t("login.title") }}</h1>
        </div>
        <!-- <div>
          <div>123</div>
          <div v-for="(item,index) in columns"
               :key="index">
            {{item}}
          </div>
        </div> -->
        <div class="columns">
          <div class="column is-half is-offset-one-quarter">
            <div v-if="error" class="notification is-danger">
              <button class="delete" @click="error = null"></button>
              {{ error.message }}
              <p>
                {{ error.status }}
              </p>
            </div>

            <form @submit.prevent="connect">
              <div class="field">
                <label class="label">{{ $t("login.username") }}</label>
                <div class="control has-icons-left has-icons-right">
                  <input
                    class="input"
                    type="text"
                    name="login"
                    :placeholder="$t('login.username')"
                    v-model="auth.username"
                  />
                  <span class="icon is-small is-left">
                    <i class="fa fa-user"></i>
                  </span>
                </div>
              </div>

              <div class="field">
                <label class="label">{{ $t("login.password") }}</label>
                <div class="control has-icons-left has-icons-right">
                  <input
                    class="input"
                    type="password"
                    name="password"
                    :placeholder="$t('login.password')"
                    v-model="auth.password"
                  />
                  <span class="icon is-small is-left">
                    <i class="fa fa-key"></i>
                  </span>
                </div>
                <div>
                  <a href="https://mdp.axione.fr/">{{
                    $t("login.forgotPassword")
                  }}</a>
                </div>
              </div>

              <div class="field is-grouped submit">
                <div class="control">
                  <button
                    class="button is-primary"
                    :class="{ 'is-loading': loading }"
                    @disabled="loading"
                  >
                    {{ $t("common.connection") }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "login",
  data() {
    return {
      auth: {
        username: "",
        password: "",
      },
      attemptHash: "",
      // columns: this.$t('loginColumns'),
      error: null,
      loading: false,
    };
  },
  methods: {
    connect() {
      this.loading = true;

      /**
       * Count the number of login attempt with unique login and password.
       */

      // Create a unique string for the combination of login/password
      const hash = this.auth.username + ":" + this.auth.password;

      // If the hash is different we increase the internal counter.
      if (hash != this.attemptHash) {
        this.attemptHash = hash;
        this.$store.dispatch("auth/incDistinctAttempt");
      }
      this.$store.dispatch("auth/incAttempt");

      axios
        .post("/login", this.auth)
        .then((response) => {
          const token = response.data.token;
          axios.defaults.headers.Authorization = `Bearer ${token}`;
          axios
            .get("/user/profile")
            .then((response2) => {
              this.loading = false;
              const payload = {
                token,
                user: response2.data,
              };
              this.$store.commit("auth/setAuth", payload);
              this.$store.commit("auth/sendPopupNotification", true);
              this.$router.push({ name: "Home" });
            })
            .catch((err) => {
              this.loading = false;
              this.error = {
                status: err.response.status,
                message:
                  "Anomalie dans la fiche utilisateur - etablissement, projet ou responsable manquant. Contacter un Admin !",
              };
              // eslint-disable-next-line
              console.log("Error: ", err);
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch((err) => {
          this.loading = false;
          // eslint-disable-next-line
          console.log("Error: ", err);

          // If network error
          if (!err.response) {
            this.error = {
              status: "",
              message: "Erreur de connexion internet !",
            };
            return;
          }

          // Invalid user or password
          if (err.response.status === 403) {
            this.error = {
              status: err.response.status,
              message: "Identifiant ou mot de passe invalide !",
            };
            return;
          }

          // Other error from the application.
          this.error = {
            status: err.response.status,
            message: "Erreur interne du reseau ou de l'application !",
          };
        });
    },
  },
};
</script>

<style scoped>
.hero {
  background-color: #fff;
}

.logo {
  text-align: center;
  margin: -100px auto 20px auto;
}

.submit .control {
  margin: 20px auto;
}

.hero.is-fullheight {
  min-height: calc(100vh - 120px);
}
</style>
