<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.BpuTemplate"
                   :create="openCreateTemplateModal">
        <h1 class="title">{{$t('common.bpuTemplates')}}</h1>

        <template slot="actions">
          <button class="button is-primary"
                  @click="goToBpus">{{$t('common.bpu')}}</button>
        </template>
      </page-header>

      <datatable :fetch="$api.fetchTemplates"
                 ref="datatable">
        <div slot="name"
             slot-scope="{ item }"
             :title="$t('common.name')"
             :sortable="true">{{ item.name }}</div>
        <!-- <div slot="edifice" slot-scope="{ item }" title="Marché sous-traitant">
          <span v-if="item.market">{{ item.market.edifice }}</span>
          <span v-else>item</span>
        </div>-->
        <div slot="actions"
             slot-scope="{ item }">
          <router-link :to="'/sst/bputemplate/' + item.id"
                       class="button is-primary">
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>

    <modal ref="createBPUTemplateModal"
           @create="createBPUTemplate"
           :title="$t('bpuTemplates.bpuTemplateCreation')">
      <div class="columns">
        <div class="column is-half">
          <text-field :label="$t('common.name')"
                      v-model="bpuTemplate.name"
                      :inline="false"
                      :edit="true"
                      required />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'bpu-template-list',
  data () {
    return {
      bpuTemplate: {},
    };
  },
  methods: {
    openCreateTemplateModal () {
      this.$refs.createBPUTemplateModal.open();
    },
    createBPUTemplate () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          axios.post('/bputemplate', this.bpuTemplate).then((response) => {
            if (response.data.success) {
              this.$refs.datatable.refresh();
              this.$refs.createBPUTemplateModal.close();
              this.$router.push(`/sst/bputemplate/${response.data.id}`);
            }
          });
        }
      });
    },
    goToBpus () {
      this.$router.push('/sst/bpu');
    },
  },
};
</script>

<style scoped>
</style>

