<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Attachement"
                   :create="openCreateModal">
        <h1 class="title">{{$t('entities.prestationReferences')}}</h1>
      </page-header>
      <datatable ref="prestationreferences"
                 :fetch="$api.fetchPrestationReferences"
                 :enableDownload="false"
                 objectName="prestationreferences"
                 identifier="name">
        <div slot="name"
             slot-scope="{ item }"
             :title="$t('common.name')"
             :sortable="true">{{ item.name }}</div>
        <div slot="actions"
             slot-scope="{ item }">
          <router-link :to="'/prestationreference/' + item.id"
                       class="button is-primary">
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
      <modal ref="createPrestationReferencesModal"
             @create="createPrestationReference"
             :title="$t('prestationReferences.prestationReferenceCreation')">
        <div class="columns">
          <div class="column is-half">
            <text-field :label="$t('common.name')"
                        v-model="protoreferencePrestationReference.name"
                        :inline="false"
                        :edit="true"
                        required />
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'prestation-references-list',
  data () {
    return {
      protoreferencePrestationReference: {
        name: '',
      },
      modalIsOpen: false,
    };
  },
  methods: {
    createPrestationReference () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const created = Object.assign({}, this.protoreferencePrestationReference);
          axios
            .post('/prestationreference', created)
            .then((res) => {
              this.closeCreateModal();
              this.$refs.prestationreferences.refresh();
              this.$router.push({
                name: 'PrestationReference',
                params: { id: res.data.id },
              });
            });
          this.protoreferencePrestationReference = {
            name: '',
          };
        }
      });
    },
    openCreateModal () {
      this.$refs.createPrestationReferencesModal.open();
    },
    closeCreateModal () {
      this.$refs.createPrestationReferencesModal.close();
    },
  },
};
</script>

