<template>
  <div class="panel">
    <div class="panel-block is-block no-border">
      <text-field
        type="text"
        :label="$t('notifications.title')"
        v-model="val.title"
        :inline="true"
        :edit="edit"
        v-validate="{ required: true }"
        name="duration"
        :error="errors.has('duration')"
      ></text-field>

      <checkbox-field :value="val.active"
                      v-model="val.active"
                      :label="$t('notifications.active')"
                      :inline="true"
                      :edit="edit"/>
      <vue-editor v-model="val.content" v-if="edit" />
      <div class="popup-body ql-editor" v-if="!edit" v-html="val.content"></div>
    </div>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";

export default {
  name: 'popup-editor',
  components: {
    VueEditor
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      val: this.value,
    };
  },
  watch: {
    value (val) {
      this.val = val;
    }
  },
  methods: {

  },
};
</script>
<style scoped lang="scss">
.panel-block {
  position: relative;
}
</style>
