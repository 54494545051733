<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Dc4">
        <h1 class="title">
          {{$t('dc4.title')}}
        </h1>
      </page-header>

      <datatable :fetch="$api.fetchDc4"
                 ref="dc4List"
                 :enableDownload="true"
                 objectName="budget/dc4">
        <div slot="project"
             slot-scope="{ item: { project } }"
             :title="$t('common.project')">
          <restricted-link v-if="project"
                           entity="project"
                           :to="`/project/${project.id}`">
            {{ project.name }}
          </restricted-link>
        </div>
        <div slot="subcontractorMarket"
             slot-scope="{ item: { subcontractorMarket } }"
             :title="$t('common.edifice')">
          <restricted-link v-if="subcontractorMarket"
                           entity="subcontractor_market"
                           :to="`/sst/market/${subcontractorMarket.id}`">
            {{ subcontractorMarket.edifice }}
          </restricted-link>
        </div>
        <div slot="maxAmountDc4Ht"
             slot-scope="{ item }"
             :title="$t('markets.columns.maxAmountDC4')"
             class="has-text-right">
          {{ item.maxAmountDc4Ht | priceEUR }}
        </div>
        <div slot="amountPayedAttachments"
             slot-scope="{ item }"
             :title="$t('dc4.attachmentsPaid')"
             class="has-text-right">
          {{ item.amountPayedAttachments | priceEUR }}
        </div>
        <div slot="amountMonthlyStatementAttachments"
             slot-scope="{ item }"
             :title="$t('dc4.readyToPayAttachments')"
             class="has-text-right">
          {{ item.amountMonthlyStatementAttachments | priceEUR }}
        </div>
        <div slot="amountValidatedAttachments"
             slot-scope="{ item }"
             :title="$t('dc4.validatedAttachments')"
             class="has-text-right">
          {{ item.amountValidatedAttachments | priceEUR }}
        </div>
        <div slot="validatedAttachmentPayedMonthlyStatementAmountWithoutTax"
             nofilter
             slot-scope="{ item }"
             :title="$t('dc4.allAttachments')"
             class="has-text-right">
          {{ item.totalAttachementAmount | priceEUR }}
        </div>
        <div slot="availableOnDc4"
             nofilter
             slot-scope="{ item }"
             :title="$t('dc4.availableDC4')"
             class="has-text-right">
          {{ item.availableOnDc4 | priceEUR }}
        </div>
        <div slot="getRadAmount"
             nofilter
             slot-scope="{ item }"
             :title="$t('common.rad')"
             class="has-text-right">
          {{ item.radAmount | priceEUR }}
        </div>
        <div slot="availableOnDc4WithRAD"
             slot-scope="{ item }"
             :title="$t('dc4.availableDC4WithRad')"
             class="has-text-right">
          {{ item.availableOnDc4WithRAD | priceEUR }}
        </div>
      </datatable>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dc4',
};
</script>
