<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.ProjectType"
                   :edit="edit"
                   :save="save"
                   :cancel="cancel"
                   :remove="remove">
        <h1 class="title">
          <breadcrumb location="/projecttypes"
                      :text="$t('entities.projectType')"></breadcrumb>
          {{ projectType.name }}
        </h1>
      </page-header>

      <div class="panel infos">
        <div class="panel-heading has-background-primary">{{$t('common.information')}}</div>
        <div class="panel-block is-block no-border">
          <text-field :label="$t('common.name')"
                      v-model="projectType.name"
                      required></text-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'project-type',
  props: ['id'],
  data () {
    return {
      projectType: {},
    };
  },
  computed: {
    ...mapGetters({
      editMode: 'states/isEdition',
    }),
  },
  mounted () {
    this.fetchData();
  },
  methods: {
    fetchData () {
      axios.get(`/projecttype/${this.id}`).then((response) => {
        this.projectType = response.data;
        this.$store.dispatch('states/setEdition', false);
      });
    },
    edit () {
      this.$store.dispatch('states/setEdition', true);
    },
    cancel () {
      this.fetchData();
    },
    save () {
      return axios.put(`/projecttype/${this.id}`, this.projectType).then((response) => {
        if (response.data.success) {
          this.fetchData();
        }
      });
    },
    remove () {
      const self = this;
      return this.$awn.confirm(
        'Êtes-vous sûr de vouloir supprimer cet élément ?',
        () => axios.delete(`/projecttype/${self.id}`).then((response) => {
          if (response.data.success) {
            self.$router.replace('/projecttypes');
          }
        }),
      );
    },
  },
};
</script>

<style scoped>
</style>

