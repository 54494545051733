<template>
  <span cy-data="field-agency-display">
    {{ value.edifice }}
    <span v-if="value.synchronize" class="icons">
      <i class="fa fa-check-circle" :class="{
        'fa-check-circle has-text-success': value.status === 1,
        'fa-exclamation-circle has-text-warning': value.status === 2,
        'fa-times-circle has-text-danger': value.status === 3,
      }"></i>
      <i class="fa fa-folder" :class="{
        'has-text-success': value.documentsCompleted,
        'has-text-danger': !value.documentsCompleted,
      }"></i>
    </span>
  </span>
</template>

<script>

export default {
  name: 'agency-display',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  }
}
</script>

<style scoped>

.icons {
  white-space: nowrap;
}

.icons i {
  margin: 0 2px;
  font-size: 14px;
}


</style>
