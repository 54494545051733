<template>
  <table class="table">
    <thead>
      <tr>
        <th
          :style="{ width: '5%' }"
          @click="sortBy('initial')"
          :class="{ sortable: columns.some((col) => col.class === 'sortable') }"
        >
          {{ showIndex ? "#" : "" }}
        </th>
        <th
          v-for="(column, i) in columns"
          :key="i"
          :class="column.class"
          @click="sortBy(column.sort)"
        >
          <span>
            <slot :name="column.name" :columns="columns" :items="items">{{
              column.title
            }}</slot>
          </span>
          <i
            v-if="sort && sort.column === column.sort"
            class="fa"
            :class="{
              'fa-sort-down': sort.direction === 'ASC',
              'fa-sort-up': sort.direction === 'DESC',
            }"
          ></i>
          <span class="help" v-if="column.help" :title="column.help">?</span>
        </th>
        <th v-if="hasActions">Action</th>
      </tr>
      <slot name="extraHeaderRow" :columns="columns"></slot>
    </thead>
    <tbody>
      <slot name="firstDataRow" :items="items"></slot>
      <tr v-if="loading">
        <td
          class="loader-container"
          :colspan="columns.length + 1 + (hasActions ? 1 : 0)"
        >
          <i class="loader"></i>
        </td>
      </tr>
      <template v-if="!loading">
        <template v-for="(item, j) in items">
          <slot name="dataRows" :item="item" :index="j">
            {{ item }}
            <slot name="action"></slot>
          </slot>
        </template>
      </template>
      <slot name="lastDataRow" :items="items"></slot>
    </tbody>
  </table>
</template>
<script>
export default {
  name: "flexi-table",
  props: ["loader", "columns", "showIndex", "hasActions"],
  data() {
    return {
      items: {},
      loading: false,
      sort: null,
    };
  },
  watch: {
    items: {
      handler() {
        this.$emit("input", this.items);
      },
      deep: true,
    },
    columns: {
      handler() {
        this.fetch();
      },
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.loader((data) => {
        this.items = data;
        this.loading = false;
      });
    },
    setLoading(val) {
      this.loading = val;
    },
    sortBy(column) {
      if (!column) {
        return;
      }
      if (column === "initial") {
        this.sort = null;
        this.$emit("sortBy", "initialValue");
        return;
      }
      const sort = {
        property: column,
        direction: null,
      };

      if (this.sort && this.sort.property === column) {
        sort.direction = this.sort.direction === "ASC" ? "DESC" : "ASC";
      } else {
        sort.direction = "ASC";
      }

      this.sort = sort;
      this.$emit("sortBy", `${sort.property},${sort.direction}`);
    },
  },
};
</script>
<style  lang="scss" scoped>
.no-data {
  display: flex;
  justify-content: center;
}

.loader-container {
  font-size: 30px;

  .loader {
    margin: 0 auto;
  }
}
.help {
  display: inline-block;
  color: white;
  background-color: #019fc4;
  border-radius: 50%;
  padding: 0 6px;
  cursor: pointer;
}
.sortable {
  cursor: pointer;
  color: rgb(50, 115, 220);
  i {
    margin-left: 5px;
  }
}
</style>
