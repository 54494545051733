<template>
  <field
    :cy-data="cyDataString"
    :edit="edit"
    :inline="inline"
    :label="label"
    :value="displayedValue"
    :readonly="readonly"
    :required="required"
    :key="'key_' + optionsArray.length"
  >
    <div
      class="select"
      :class="{ 'is-loading': loading }"
      :cy-data="`select-container-${cyDataString}`"
    >
      <select @input="onSelect" :cy-data="`select-${cyDataString}`">
        <option
          v-for="(option, index) in optionsArray"
          :data-test="`sel-option-${option.name}`"
          :key="index"
          :value="index"
          :selected="isSelected(option, index)"
        >
          <slot :option="option">{{ option }}</slot>
        </option>
      </select>
    </div>
    <slot name="helper"></slot>
  </field>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "select-field",
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      // eslint-disable-next-line
      type: Object | String,
      default: null,
    },
    options: {
      type: Function,
    },
    pkey: {
      type: String,
      default: "id",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    cyData: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      val: {},
      optionsArray: [],
      loading: false,
      isKeyValueOfString: false,
    };
  },
  computed: {
    ...mapGetters({
      editMode: "states/isEdition",
    }),
    cyDataString() {
      const fieldLabel = this.cyData ? this.cyData : this.label;
      if (!fieldLabel) {
        return "";
      }
      return fieldLabel.replaceAll(/[ ']/g, "");
    },
    displayedValue() {
      if (!this.val) {
        return "";
      }

      if (this.isKeyValueOfString) {
        return this.optionsArray[this.val];
      }

      return typeof this.val === "string" ? this.val : this.val.name;
    },
  },
  mounted() {
    if (this.value) {
      this.val = this.value;
      this.optionsArray.push(this.value);
    }

    if (this.edit) {
      this.refresh();
    }
    /*
    if (this.editMode) {
      this.refresh();
    } */
    // this.refresh();
  },
  watch: {
    value(val) {
      this.val = val;
      if (this.optionsArray.length < 1) {
        this.optionsArray.push(this.value);
      }
    },
    editMode(val) {
      if (val) {
        this.refresh();
      }
    },
    edit(val) {
      if (val) {
        this.refresh();
      }
    },
  },
  methods: {
    refresh() {
      if (
        (Array.isArray(this.optionsArray) && this.optionsArray.length > 1) ||
        Object.keys(this.optionsArray).length > 1
      )
        return;

      this.loading = true;
      this.options((data) => {
        this.loading = false;
        this.optionsArray = data;

        if (!Array.isArray(data)) {
          this.isKeyValueOfString = true;
        }

        if (!this.value) {
          if (this.isKeyValueOfString) {
            this.$emit("input", Object.keys(this.optionsArray)[0]);
            return;
          }

          this.$emit("input", this.optionsArray[0]);
        }
      });
    },
    onSelect(event) {
      if (!this.isKeyValueOfString) {
        this.$emit("input", this.optionsArray[event.target.value]);
        return;
      }
      this.$emit("input", event.target.value);
    },
    isSelected(option, index) {
      if (!this.val) {
        return false;
      }

      if (this.isKeyValueOfString) {
        return this.val === index;
      }

      return (
        (typeof option === "string" && option === this.val) ||
        (typeof option[this.pkey] !== "undefined" &&
          option[this.pkey] === this.val[this.pkey])
      );
    },
  },
};
</script>

<style lang='scss' scoped>
</style>
