<template>
  <canvas ref="gaugeChart"></canvas>
</template>

<script>
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default {
  name: "GaugeChart",
  props: {
    value: {
      type: Number,
      required: true,
    },
    minValue: {
      type: Number,
      default: -3,
    },
    maxValue: {
      type: Number,
      default: 10,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      chartInstance: null,
    };
  },
  watch: {
    value: "renderChart",
    minValue: "renderChart",
    maxValue: "renderChart",
  },
  mounted() {
    this.resizeCanvas();
    window.addEventListener("resize", this.resizeCanvas);
    this.renderChart();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeCanvas);
  },
  methods: {
    resizeCanvas() {
      const canvas = this.$refs.gaugeChart;
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      this.renderChart();
    },
    getFixedSegmentData() {
      const colors = ["white", "#DB3912", "#FE9A01", "#0E961B"];
      const ranges = [
        { start: this.minValue, end: 0, color: colors[0] },
        { start: 0, end: 3.3, color: colors[1] },
        { start: 3.3, end: 6.6, color: colors[2] },
        { start: 6.6, end: this.maxValue, color: colors[3] },
      ];
      return ranges.map((range) => ({
        value: range.end - range.start,
        color: range.color,
      }));
    },
    drawNeedle() {
      const canvas = this.$refs.gaugeChart;
      const ctx = canvas.getContext("2d");
      const cw = canvas.offsetWidth;
      const ch = canvas.offsetHeight;
      const cx = cw / 2;
      const cy = ch / 2;
      const radius = Math.min(cx, cy) * 0.9;

      const normalizedValue =
        (this.value - this.minValue) / (this.maxValue - this.minValue);
      const needleAngle = normalizedValue * 360 - 315;

      ctx.save();
      ctx.translate(cx, cy);
      ctx.rotate((needleAngle * Math.PI) / 180);

      ctx.beginPath();
      ctx.moveTo(0, -3);
      ctx.lineTo(radius, 0);
      ctx.lineTo(0, 3);
      ctx.fillStyle = "#000";
      ctx.fill();

      ctx.rotate(-((needleAngle * Math.PI) / 180));
      ctx.translate(-cx, -cy);
      ctx.beginPath();
      ctx.arc(cx, cy, 7, 0, Math.PI * 2);
      ctx.fill();

      ctx.restore();
    },
    renderChart() {
      if (this.$refs.gaugeChart) {
        const segmentData = this.getFixedSegmentData();
        const labels = segmentData.map((_, index) => `Segment ${index + 1}`);
        const data = segmentData.map((segment) => segment.value);
        const backgroundColor = segmentData.map((segment) => segment.color);

        const ctx = this.$refs.gaugeChart.getContext("2d");

        // Destroy the old chart instance if it exists
        if (this.chartInstance) {
          this.chartInstance.destroy();
        }

        // Create a new chart instance
        this.chartInstance = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: labels,
            datasets: [
              {
                data: data,
                backgroundColor: backgroundColor,
                borderWidth: 0,
              },
            ],
          },
          options: {
            cutout: "80%",
            rotation: 135,
            circumference: 360,
            responsive: false,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            animation: {
              onComplete: () => {
                this.drawNeedle();
              },
            },
            ...this.options,
          },
        });
      }
    },
  },
};
</script>
