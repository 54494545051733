var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"container is-fluid"},[_c('div',{staticClass:"header"},[(_vm.node && _vm.node.workflow)?_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("common.workflow"))+" "+_vm._s(_vm.node.workflow.name)+" - "+_vm._s(_vm.node.name)+" ")]):_vm._e()]),_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left actions"},_vm._l((_vm.transitions),function(transition,index){return _c('button',{directives:[{name:"show",rawName:"v-show",value:(
            !Object.values(_vm.WorkflowCodes.ENV.TRANSITION).includes(
              transition.transitionCode
            )
          ),expression:"\n            !Object.values(WorkflowCodes.ENV.TRANSITION).includes(\n              transition.transitionCode\n            )\n          "}],key:index,staticClass:"button is-success",class:{
            'is-danger': transition.transitionType === 2,
            'is-loading': _vm.loading,
          },attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.moveToken(transition)}}},[_vm._v(" "+_vm._s(transition.name)+" ")])}),0),(_vm.hasMyActions && _vm.transitions.length != 0)?_c('div',{staticClass:"field has-addons"},[_c('p',{staticClass:"control"},[_c('a',{staticClass:"button",class:{ 'is-primary': _vm.toDoFilter === 'all' },on:{"click":function($event){return _vm.setToDoFilter('all')}}},[_vm._v(_vm._s(_vm.$t("common.all")))])]),_c('p',{staticClass:"control"},[_c('a',{staticClass:"button",class:{ 'is-primary': _vm.toDoFilter === 'canDo' },on:{"click":function($event){return _vm.setToDoFilter('canDo')}}},[_vm._v(_vm._s(_vm.$t("expressions.myScope")))])]),_c('p',{staticClass:"control"},[_c('a',{staticClass:"button",class:{ 'is-primary': _vm.toDoFilter === 'haveToDo' },on:{"click":function($event){return _vm.setToDoFilter('haveToDo')}}},[_vm._v(_vm._s(_vm.$t("expressions.myTasks")))])])]):_vm._e()]),(_vm.node.objectName)?_c('datatable',{ref:"listTokens",attrs:{"fetch":function (p) { return _vm.$api.fetchTokens(_vm.id, p, _vm.filter); },"columns":_vm.columns,"order":"DESC"},scopedSlots:_vm._u([{key:"entity.total",fn:function(ref){
          var item = ref.item;
return _c('div',{staticClass:"nowrap has-text-right",attrs:{"title":_vm.$t('common.amount')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.entity && item.entity.total))+" ")])}},{key:"entity.amount",fn:function(ref){
          var item = ref.item;
return _c('div',{staticClass:"nowrap has-text-right",attrs:{"title":_vm.$t('common.amount')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.entity && item.entity.amount))+" ")])}},{key:"entity.project",fn:function(ref){
          var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.project')}},[_vm._v(" "+_vm._s(item.entity && item.entity.project && item.entity.project.name)+" ")])}},{key:"comment",fn:function(ref){
          var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.comments')}},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.comments[item.id]),expression:"comments[item.id]",modifiers:{"trim":true}}],attrs:{"type":"text"},domProps:{"value":(_vm.comments[item.id])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.comments, item.id, $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])}},{key:"createdAt",fn:function(ref){
          var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.date')}},[_vm._v(" "+_vm._s(_vm._f("datetime")(item.createdAt))+" ("+_vm._s(_vm._f("fromNow")(item.createdAt))+") ")])}},{key:"actions",fn:function(ref){
          var item = ref.item;
return _c('div',{},[(item.entity)?_c('span',[(_vm.node.objectName == _vm.WorkflowCodes.OS.NODE.OBJECT_NAME)?_c('router-link',{staticClass:"button is-primary",attrs:{"to":'/sst/order/' + item.entity.id}},[_c('i',{staticClass:"fa fa-search"})]):_vm._e(),(_vm.node.objectName == _vm.WorkflowCodes.ATT.NODE.OBJECT_NAME)?_c('router-link',{staticClass:"button is-primary",attrs:{"to":'/sst/attachement/' + item.entity.id}},[_c('i',{staticClass:"fa fa-search"})]):_vm._e(),(_vm.node.objectName == _vm.WorkflowCodes.ENV.NODE.OBJECT_NAME)?_c('router-link',{staticClass:"button is-primary",attrs:{"to":'/sst/contract/' +
              item.entity.contractId +
              '?envelopeId=' +
              item.entity.id}},[_c('i',{staticClass:"fa fa-search"})]):_vm._e()],1):_vm._e()])}}],null,false,765540517),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }