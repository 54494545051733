export const Workflows = Object.freeze({
  OS: {
    CODE: "services_order",
    NODE: {
      OBJECT_NAME: "servicesOrder",
      DRAFT: "os_draft",
      VALIDATION_PENDING_CDP: "os_validation_cdp",
      VALIDATION_PENDING_SST: "os_validation_sst",
      VALIDATED: "os_validated",
      CLOSED: "os_closed",
      CANCELED: "os_canceled",
      ABANDONED: "os_abandoned",
    },
    TRANSITION: {
      CANCELED_DRAFT: "os_canceled_draft",
      CANCELLED_OR_REFUSED: "os_cancelled_or_refused",
      ABANDONED_TO_VALIDATED: "os_from_abandoned_to_validated",
      CLOSED_TO_VALIDATED: "os_from_closed_to_validated",
      REFUSED_CDP: "os_refused_cdp",
      SUBMIT_TO_CDP: "os_submit_to_cdp",
      SUBMIT_TO_SST: "os_submit_to_sst",
      TO_ABANDONED: "os_to_abandoned",
      TO_CANCELED: "os_to_canceled",
      TO_CLOSED: "os_to_closed",
      TO_VALIDATED: "os_to_validated",
    },
  },
  ATT: {
    CODE: "attachement",
    NODE: {
      OBJECT_NAME: "attachement",
      DRAFT: "att_draft",
      VALIDATING_SST: "att_validation_sst",
      VALIDATING: "att_validating",
      VALIDATING_AMOUNT: "att_validating_amount",
      VALIDATED: "att_validated",
      PREPARED_PAY: "att_prepared_for_payment",
      PAID: "att_paid",
      REFUSED: "att_refused",
    },
    TRANSITION: {
      POSTPONED: "att_postponed",
      SUBMIT_TO_CDP: "att_submit_to_cdp",
      TO_DRAFT: "att_to_draft",
      TO_PAID: "att_to_paid",
      TO_PREPARED: "att_to_prepared",
      TO_REFUSED: "att_to_refused",
      TO_VALIDATED: "att_to_validated",
      TO_VALIDATION: "att_to_validation",
    },
  },
  ENV: {
    NODE: {
      OBJECT_NAME: "envelope",
      DRAFT: "env_draft",
      VALIDATION_PENDING: "env_pending_validation",
      VALIDATED: "env_validated",
    },
    TRANSITION: {
      SUBMIT_TO_VALIDATION: "env_submit_to_validation",
      TO_VALIDATION: "env_to_validation",
      TO_VALIDATED: "env_to_validated",
      REVOKE_VALIDATION: "env_revoke_validation",
      FROM_VALIDATED_TO_DRAFT: "env_from_validated_to_draft",
      TO_REFUSED: "env_to_refused",
      FROM_REFUSED_TO_DRAFT: "env_from_refused_to_draft",
      // SUBMIT_ELECTRONIC_SIGNATURE: "env_to_electronic_signature",
    },
  },
});

export const Entities = [];
