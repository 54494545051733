<template>
  <NotificationContainer :readedAt="readedAt">
    No template for this notification type {{ template }}!
  </NotificationContainer>
</template>

<script>
export default {
  name: "EmptyNotification",
  props: {
    template: {},
    readedAt: String,
  },
};
</script>
