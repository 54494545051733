<template>
  <field
    :cy-data="cyDataString"
    :edit="edit"
    :inline="inline"
    :label="label"
    :readonly="readonly"
    :required="required"
  >
    <span
      slot="value"
      v-if="val"
      :cy-data="`field-${cyDataString}-display`"
      class="textarea-container"
    >
      <nl2br tag="div" :text="val" />
    </span>
    <textarea
      v-if="!readonly"
      class="textarea"
      :class="{ error: errors.has(internal_id) }"
      @input="$emit('input', $event.target.value.trim())"
      v-model="val"
      :name="internal_id"
      :required="required"
      v-validate
      :cy-data="'textarea-' + cyDataString"
    ></textarea>
  </field>
</template>

<script>
import uid from "uniqid";

export default {
  name: "textarea-field",
  inject: ["$validator"],
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "",
    },
    value: {},
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    cyData: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      val: "",
      // eslint-disable-next-line
      internal_id: uid(),
    };
  },
  computed: {
    cyDataString() {
      const fieldLabel = this.cyData ? this.cyData : this.label;
      return fieldLabel.replaceAll(/[ ']/g, "");
    },
  },
  mounted() {
    this.val = this.value;
  },
  watch: {
    value(val) {
      this.val = val;
    },
  },
  methods: {},
};
</script>

<style lang='scss' scoped>
.textarea {
  min-width: 100px;
  max-width: 500px;
  display: inline-block;
}

.textarea-container {
  display: inline-block;
  min-height: 100px;
  border: 1px solid #eee;
  padding: 5px 10px;
  min-width: 350px;
  width: 100%;
  max-width: 500px;
  word-wrap: break-word;
}

.error {
  border: 2px solid red;
}
</style>
