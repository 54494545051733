<template>
  <div>
    <paginate
      :paginate="pagination"
      @change="paginate"
      :showOptions="true"
      :options="paginateOptions"
    />
    <table>
      <thead>
        <tr>
          <th colspan="4">{{ $t("common.name") }}</th>
          <th>{{ $t("evaluation.averageRating") }}</th>
          <th>{{ $t("evaluation.evaluation") }}</th>
          <th>{{ $t("evaluation.safety") }}</th>
          <th>{{ $t("evaluation.procurement") }}</th>
          <th>{{ $t("evaluation.project") }}</th>
        </tr>
      </thead>
      <tbody>
        <!-- Companies -->
        <tr v-if="companies.length === 0 && loading === true">
          <td class="loader-container" colspan="9"><i class="loader"></i></td>
        </tr>
        <tr v-if="companies.length === 0 && loading === false">
          <td>{{ $t("expressions.noData") }}</td>
        </tr>
        <template v-for="(company, companyIndex) in companies">
          <tr
            :key="company.id"
            @click="toggleAgencies(companyIndex)"
            :class="{
              'company-selected': expandedAgencyIndex === companyIndex,
              'border-companies': expandedAgencyIndex !== null,
            }"
            class="selectable companies"
          >
            <td colspan="4">{{ company.name }}</td>
            <td>
              <div style="display: flex">
                <span
                  class="pastille"
                  :class="{
                    red: company.average.score < 3.33,
                    yellow:
                      company.average.score >= 3.33 &&
                      company.average.score < 6.66,
                    green: company.average.score >= 6.66,
                  }"
                ></span>
                <span style="min-width: 20px">{{
                  company.average.score.toFixed(1)
                }}</span>
              </div>
            </td>
            <td>{{ company.average.count }}</td>
            <td>{{ company.details.safety.score.toFixed(1) }}</td>
            <td>{{ company.details.procurement.score.toFixed(1) }}</td>
            <td>{{ company.details.projects.score.toFixed(1) }}</td>
          </tr>

          <!-- Agencies-->
          <template v-if="expandedAgencyIndex === companyIndex">
            <template v-if="loadingAgencies">
              <tr class="agencies last-agency">
                <td class="loader-container">
                  <i class="loader"></i>
                </td>
              </tr>
            </template>
            <tr v-if="agencies.length > 0" class="subtitle-separator tr-indent">
              <td>Agences</td>
              <td colspan="8"></td>
            </tr>
            <template v-for="(agency, agencyIndex) in agencies">
              <tr
                :key="company.id + '-' + agency.id"
                @click="toggleProjects(agencyIndex)"
                class="agencies selectable tr-indent"
                :class="{
                  'last-agency': agencyIndex === agencies.length - 1,
                  'agency-selected': expandedProjectIndex === agencyIndex,
                }"
              >
                <td></td>
                <td colspan="3">{{ agency.name }}</td>
                <td>
                  <div style="display: flex">
                    <span
                      class="pastille"
                      :class="{
                        red: agency.average.score < 3.33,
                        yellow:
                          agency.average.score >= 3.33 &&
                          agency.average.score < 6.66,
                        green: agency.average.score >= 6.66,
                      }"
                    ></span>
                    <span style="min-width: 20px">
                      {{ agency.average.score.toFixed(1) }}
                    </span>
                  </div>
                </td>
                <td>{{ agency.average.count }}</td>
                <td>{{ agency.details.safety.score.toFixed(1) }}</td>
                <td>{{ agency.details.procurement.score.toFixed(1) }}</td>
                <td>{{ agency.details.projects.score.toFixed(1) }}</td>
              </tr>
              <!-- Projects -->
              <template v-if="expandedProjectIndex === agencyIndex">
                <template v-if="loadingProject">
                  <tr class="project last-project tr-indent-1">
                    <td class="loader-container">
                      <i class="loader"></i>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <template>
                    <tr
                      v-if="projects.length > 0"
                      class="subtitle-separator separator-project tr-indent-1"
                    >
                      <td></td>
                      <td>Projets</td>
                      <td colspan="7" class="pl-60"></td>
                    </tr>
                    <template v-for="(project, projectIndex) in projects">
                      <tr
                        :key="agency.id + '-' + project.id"
                        class="projects selectable tr-indent-1"
                        @click="toggleMarkets(projectIndex, project)"
                        :class="{
                          'last-project': projectIndex === projects.length - 1,
                          'project-selected':
                            expandedMarketIndex === projectIndex,
                        }"
                      >
                        <td></td>
                        <td></td>
                        <td colspan="2" class="pl-60">
                          {{ project.reference }}
                        </td>
                        <td>
                          <div style="display: flex">
                            <span
                              class="pastille"
                              :class="{
                                red: project.average < 3.33,
                                yellow:
                                  project.average >= 3.33 &&
                                  project.average < 6.66,
                                green: project.average >= 6.66,
                              }"
                            ></span>
                            <span style="min-width: 20px">
                              {{ project.average.toFixed(1) }}
                            </span>
                          </div>
                        </td>
                        <td>{{ project.count }}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>

                      <!-- Markets -->
                      <template
                        v-if="expandedMarketIndex === projectIndex && markets"
                      >
                        <tr
                          v-if="markets"
                          class="subtitle-separator separator-market tr-indent-2"
                        >
                          <td></td>
                          <td></td>
                          <td colspan="2">Marché sous-traitant</td>
                          <td class="pl-90" colspan="4"></td>
                        </tr>
                        <template>
                          <tr
                            v-for="(market, marketIndex) in markets"
                            :key="project.id + '-' + market.id"
                            class="markets tr-indent-2"
                            :class="{
                              'last-market': marketIndex === markets.length - 1,
                            }"
                          >
                            <td></td>
                            <td></td>
                            <td></td>
                            <td colspan="1" class="pl-90">
                              {{ market.reference }}
                            </td>
                            <td>
                              <div style="display: flex">
                                <span
                                  class="pastille"
                                  :class="{
                                    red: market.average < 3.33,
                                    yellow:
                                      market.average >= 3.33 &&
                                      market.average < 6.66,
                                    green: market.average >= 6.66,
                                  }"
                                ></span>
                                <span style="min-width: 20px">
                                  {{ market.average.toFixed(1) }}
                                </span>
                              </div>
                            </td>
                            <td>{{ market.count }}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </template>
                      </template>
                    </template>
                  </template>
                </template>
              </template>
            </template>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import Paginate from "@/components/Paginate";
import PaginationBuilder from "@/lib/Paginate";
import axios from "axios";

export default {
  name: "EvaluationTable",
  components: {
    Paginate,
  },
  props: {
    fetch: Function,
    singleSelect: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 10,
    },
    paginateOptions: {
      type: Array,
      default: () => [50, 100, 200],
    },
    identifier: {
      type: String,
      default: "id",
    },
    order: {
      type: String,
      default: "ASC",
    },
    paramsFilters: {
      type: String,
      default: "start_date=&end_datethis.end_date&companies=&agencies=",
    },
  },
  data() {
    return {
      loading: false,
      internalColumns: [],
      hasFilters: false,
      companies: [],
      agencies: [],
      projects: [],
      markets: [],
      start_date: "2000-01-01",
      end_date: new Date().toISOString().slice(0, 10),
      pagination: {
        page: 1,
        total: 0,
        size: this.size,
        sort: {
          column: this.identifier,
          direction: this.order,
        },
      },
      selectAll: false,
      selected: [],
      API: axios.defaults.baseURL,
      authToken: this.$store.getters["auth/getToken"],
      emptyTableMessage: this.customEmptyTableMessage,
      tableSummary: null,
      expandedAgencyIndex: null,
      expandedProjectIndex: null,
      expandedMarketIndex: null,
      companyIndex: null,
      agencyIndex: null,
      projectIndex: null,
      loadingProject: false,
      loadingAgencies: false,
    };
  },
  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.pagination.sort.column = this.identifier;
      this.pagination.sort.direction = this.order;
      this.refreshWithFilters(this.paramsFilters);
    },

    refreshWithFilters(paramsFilters) {
      const p = new PaginationBuilder(this.preparePaginationObject());
      this.loading = true;
      this.fetch(p, paramsFilters)
        .then((data) => {
          this.callback(data);
        })
        .catch(this.fetchError)
        .finally(() => {
          this.$nextTick(() => {
            this.loading = false;
          });
        });
    },
    callback(data) {
      this.pagination.total = data.totalElements;
      this.companies = Array.isArray(data.content) ? data.content : [];
      this.emptyTableMessage = this.$t("expressions.noData");
      this.tableSummary = data.summary || null;
    },
    preparePaginationObject() {
      return {
        ...this.pagination,
        page: this.pagination.page - 1,
        sort: {
          ...this.pagination.sort,
        },
        filters: [],
      };
    },
    paginate(p) {
      this.pagination = p;
      this.expandedAgencyIndex = null;
      this.refreshWithFilters(this.paramsFilters);
    },
    toggleAgencies(index) {
      this.markets = [];
      this.expandedMarketIndex = null;
      this.expandedProjectIndex = null;
      if (this.expandedAgencyIndex === index) {
        this.expandedAgencyIndex = null;
        this.agencies = [];
      } else {
        this.expandedAgencyIndex = index;
        this.getAgenciesEvaluations(this.companies[index].id);
      }
    },
    toggleProjects(agencyIndex) {
      this.markets = [];
      this.expandedMarketIndex = null;
      if (this.expandedProjectIndex === agencyIndex) {
        this.expandedProjectIndex = null;
        this.projects = [];
      } else {
        this.expandedProjectIndex = agencyIndex;
        if (this.agencies[agencyIndex]) {
          this.getProjectsEvaluations(this.agencies[agencyIndex].id);
        } else {
          console.error("No agency found for this index.");
        }
      }
    },
    toggleMarkets(projectIndex, project) {
      if (this.expandedMarketIndex === projectIndex) {
        this.expandedMarketIndex = null;
        this.markets = [];
      } else {
        this.expandedMarketIndex = projectIndex;
        this.markets = project.markets;
      }
    },

    getAgenciesEvaluations(companyId) {
      this.loadingAgencies = true;
      this.$api
        .fetchAgenciesEvaluations(companyId, this.paramsFilters)
        .then((response) => {
          this.agencies = response;
        })
        .catch((error) => {
          console.error("Error fetching agency evaluations:", error);
          this.agencies = [];
        })
        .finally(() => {
          this.loadingAgencies = false;
        });
    },
    getProjectsEvaluations(agencyId) {
      this.loadingProject = true;
      this.$api
        .fetchProjectsAndMarketsEvaluations(agencyId, this.paramsFilters)
        .then((response) => {
          this.projects = response;
        })
        .catch((error) => {
          console.error("Error fetching agency evaluations:", error);
          this.projects = [];
        })
        .finally(() => {
          this.loadingProject = false;
        });
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

thead {
  background-color: #f4f4f4;
}

th,
td {
  padding: 10px;
  text-align: left;
}

tr.companies {
  border-bottom: solid 1px #f1f1f1;
}

tr.agencies {
  td:not(:first-child) {
    border-top: solid 1px #f1f1f1;
  }
}
tr.projects {
  td:not(:nth-child(1)):not(:nth-child(2)) {
    border-top: solid 1px #f1f1f1;
  }
}

tr.markets {
  td:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
    border-top: solid 1px #f1f1f1;
  }
}

tr.company-selected {
  background-color: #01b4dd;
  border: solid 1px #01b4dd;
  color: white;
}

.companies:hover {
  background: #e5f7fb;
}

.company-selected:hover {
  background: #00a2c6;
  color: white;
}

.subtitle-separator td {
  line-height: 7px;
  font-weight: bold;
  font-size: 12px;
}

.separator-project td:nth-child(2),
.separator-market td:nth-child(2),
.agencies td:nth-child(2),
.projects td:nth-child(2),
.projects td:nth-child(3),
.markets td:nth-child(2),
.markets td:nth-child(4) {
  border-left: solid 1px #fe9a01;
}

.projects td:nth-child(3),
.separator-market td:nth-child(3),
.markets td:nth-child(3) {
  border-left: solid 1px #9a01fe;
}
.markets td:nth-child(4) {
  border-left: solid 1px #fe1b01;
}

.tr-indent td:first-child,
.tr-indent-1 td:nth-child(2),
.tr-indent-2 td:nth-child(3) {
  min-width: 25px;
  width: 25px;
  max-width: 25px;
}

tr.agencies:hover {
  td:not(:first-child) {
    background: #e5f7fb;
  }
}
tr.projects:hover {
  td:not(:nth-child(1)):not(:nth-child(2)) {
    background: #e5f7fb;
  }
}

.selectable {
  cursor: pointer;
}

.pastille {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 20px;
}

.red {
  background-color: #db3912;
}
.yellow {
  background-color: #fe9a01;
}
.green {
  background-color: #0e961b;
}

.loader-container {
  font-size: 30px;
  text-align: center;
  background-color: #fff;
  .loader {
    margin: 0 auto;
  }
}
</style>
