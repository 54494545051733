import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL || "/api";

const localStorageKey = `cs_user_data${API_URL}`;
const attemptCountKey = `cs_attempt_count${API_URL}`;

const Authentication = {
  namespaced: true,
  state: {
    token: null,
    user: {
      role: null,
    },
    attempt: {
      count: 0,
      unique: 0,
    },
    config: {},
    activeProfile: {},
    sendPopupNotification: false,
  },
  getters: {
    isAuthenticated: (state) => {
      if (state.token) return true;
      return false;
    },
    isSst: (state) =>
      state.activeProfile.role.options.includes(
        "serviceorders.display_sst_validation_in_dashboard"
      ),
    hasMyActions: (state) =>
      state.activeProfile.role.options.includes(
        "userinterface.display_my_actions_menu"
      ),
    getToken: (state) => state.token,
    getAttempt: (state) => state.attempt,
    getUid: (state) => state.user.uid || null,
    getName: (state) => state.user.name || null,
    getMail: (state) => state.user.mail || null,
    getUser: (state) => state.user || {},
    getActiveProfile: (state) => state.activeProfile || {},
    getRights: (state) => (entity) => {
      if (
        !state.activeProfile.role ||
        !state.activeProfile.role.entities[entity]
      ) {
        return {
          create: false,
          delete: false,
          read: false,
          update: false,
        };
      }
      return state.activeProfile.role.entities[entity];
    },
    getOptions: (state) => state.activeProfile.role.options,
    getAvailableProfiles: (state) => {
      const children = state.user.children || {};
      const user = state.user || {};
      const profiles = [...children, user];

      return profiles.filter(
        (user) => user.uid !== state.activeProfile.uid && user.active
      );
    },
    getLocalStorageKey: () => localStorageKey,
    getPopupNotification: (state) => state.sendPopupNotification,
  },
  actions: {
    incAttempt({ commit }) {
      commit("incAttempt");
    },
    incDistinctAttempt({ commit }) {
      commit("incDistinctAttempt");
    },
    setAuth({ commit }, auth) {
      commit("setAuth", auth);
    },
    checkAuth({ commit }) {
      const data = JSON.parse(localStorage.getItem(localStorageKey));
      commit("setAuth", data);
    },
    logout({ commit }) {
      commit("logout");
    },
    sendPopupNotification({ commit }, value) {
      commit("sendPopupNotification", value);
    },
  },
  mutations: {
    incAttempt(state) {
      let data = JSON.parse(localStorage.getItem(attemptCountKey));
      if (!data) {
        data = {
          count: 0,
          unique: 0,
        };
      }
      data.count++;
      state.attempt.count = data.count;
      state.attempt.unique = data.unique;
      localStorage.setItem(attemptCountKey, JSON.stringify(data));
    },
    incDistinctAttempt(state) {
      let data = JSON.parse(localStorage.getItem(attemptCountKey));
      if (!data) {
        data = {
          count: 0,
          unique: 0,
        };
      }
      data.unique++;
      state.attempt.count = data.count;
      state.attempt.unique = data.unique;
      localStorage.setItem(attemptCountKey, JSON.stringify(data));
    },
    setAuth(state, data) {
      if (data == null) {
        return;
      }
      const profileInfo = data;
      if (!profileInfo.activeProfile) {
        profileInfo.activeProfile = data.user;
      }
      if (!profileInfo.user) {
        profileInfo.user = state.user;
      }
      localStorage.setItem(localStorageKey, JSON.stringify(profileInfo));
      state.token = profileInfo.token;
      state.user = profileInfo.user;
      state.activeProfile = profileInfo.activeProfile;
      axios.defaults.headers.Authorization = `Bearer ${state.token}`;

      // Update the auth attempt count
      let attemptCount = JSON.parse(localStorage.getItem(attemptCountKey));
      if (!attemptCount) {
        attemptCount = {
          count: 0,
          unique: 0,
        };
      }
      state.attempt.count = attemptCount.count;
      state.attempt.unique = attemptCount.unique;
    },
    logout(state) {
      if (state.user && state.user.uid) {
        localStorage.removeItem(attemptCountKey);
      }
      localStorage.removeItem(localStorageKey);
      state.token = null;
      state.user = {};
      axios.defaults.headers.Authorization = null;
    },
    sendPopupNotification(state, value) {
      state.sendPopupNotification = value;
    },
  },
};

export default Authentication;
