var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"container is-fluid"},[_c('page-header',{attrs:{"entity":_vm.$entitiesName.Company,"create":_vm.openCreateModal}},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('common.company')))])]),_c('datatable',{ref:"datatable",attrs:{"fetch":_vm.$api.fetchCompanies,"enableDownload":true,"objectName":"companies"},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.name'),"sortable":true}},[_vm._v(_vm._s(item.name))])}},{key:"siren",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.siren'),"sortable":true}},[_vm._v(_vm._s(item.siren))])}},{key:"manager",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.manager'),"sortable":true}},[_vm._v(_vm._s(item.manager))])}},{key:"edifice",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.edifice'),"sortable":true}},[_vm._v(_vm._s(item.edifice))])}},{key:"mail",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.email'),"sortable":true}},[_vm._v(_vm._s(item.mail))])}},{key:"actions",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('router-link',{staticClass:"button is-primary",attrs:{"to":'/sst/company/' + item.id}},[_c('i',{staticClass:"fa fa-search"})])],1)}}])})],1),_c('modal',{ref:"createCompanyModal",attrs:{"title":_vm.$t('companies.companyCreation')},on:{"create":_vm.createCompany}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('text-field',{attrs:{"label":_vm.$t('companies.columns.edificeCode'),"inline":false,"edit":true,"required":""},model:{value:(_vm.company.edifice),callback:function ($$v) {_vm.$set(_vm.company, "edifice", $$v)},expression:"company.edifice"}}),_c('text-field',{attrs:{"label":_vm.$t('common.name'),"inline":false,"edit":true,"required":""},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}}),_c('text-field',{attrs:{"label":_vm.$t('common.siren'),"inline":false,"edit":true},model:{value:(_vm.company.siren),callback:function ($$v) {_vm.$set(_vm.company, "siren", $$v)},expression:"company.siren"}})],1),_c('div',{staticClass:"column is-half"},[_c('text-field',{attrs:{"label":_vm.$t('common.manager'),"inline":false,"edit":true},model:{value:(_vm.company.manager),callback:function ($$v) {_vm.$set(_vm.company, "manager", $$v)},expression:"company.manager"}}),_c('text-field',{attrs:{"label":_vm.$t('common.email'),"inline":false,"type":"email","edit":true},model:{value:(_vm.company.mail),callback:function ($$v) {_vm.$set(_vm.company, "mail", $$v)},expression:"company.mail"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }