<template>
  <tr>
    <td>{{ rowNumber }}</td>
    <td>
      <selector-field-mod
      ref="referenceRef"
        :edit="true"
        :inline="false"
        v-model="prestation.reference"
        :options="fetchPrestationReferences"
        cyData="reference"
        :style="{ maxWidth: '200px' }"
        showEmpty
      >
        <template slot-scope="{ option }">{{ option.name }}</template>
      </selector-field-mod>
    </td>
    <td>
      <selector-field-mod
        ref="categoryRef"
        :edit="true"
        :inline="false"
        v-model="parentCategory"
        :options="$api.fetchAllParentCategories"
        cyData="category"
        :style="{ maxWidth: '200px' }"
        showEmpty
      >
        <template slot-scope="{ option }">{{ option.name }}</template>
      </selector-field-mod>
    </td>
    <td>
      <selector-field-mod
        ref="subCategoryRef"
        :edit="true"
        :inline="false"
        v-model="selectedCategory"
        :options="fetchSubCategories"
        :style="{ maxWidth: '200px' }"
      >
        <template slot-scope="{ option }">{{ option.name }}</template>
      </selector-field-mod>
    </td>
    <td>
      <text-field
        v-model="prestation.code"
        :inline="false"
        :edit="true"
        cyData="code"
      ></text-field>
    </td>
    <td>
      <text-field
        v-model="prestation.description"
        :inline="false"
        :edit="true"
        cyData="designation"
      ></text-field>
    </td>
    <td>
      <selector-field
        ref="unitRef"
        :edit="true"
        :inline="false"
        v-model="prestation.unit"
        :options="fetchUnits"
        :style="{ maxWidth: '200px' }"
        pkey="id"
      >
        <template slot-scope="{ option }">{{ option.name }}</template>
      </selector-field>
    </td>
    <td>
      <selector-field
        ref="typeRef"
        :edit="true"
        :inline="false"
        v-model="prestation.type"
        :options="fetchTypes"
        :style="{ maxWidth: '200px' }"
        pkey="id"
      >
        <template slot-scope="{ option }">{{ option.name }}</template>
      </selector-field>
    </td>
    <td v-if="newMode">
      <button
        class="button has-background-success is-pulled-right"
        cy-data="save-button"
        @click="save"
      >
        <i class="fa fa-plus white"></i>
      </button>
    </td>
    <td v-else>
      <button class="button has-background-success" @click="closeEdit">
        <i class="fa fa-plus white"></i>
      </button>
    </td>
  </tr>
</template>

<script>
import axios from "axios";

export default {
  name: "bpu-template-editor",
  props: {
    value: Object,
    rowNumber: {
      type: Number,
      default: null,
    },
    newMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      prestation: this.value,
      parentCategory: null,
      selectedCategory: null,
    };
  },
  watch: {
    value(value) {
      this.prestation = value;
      this.refreshSelectors();
    },
    prestation(value) {
      this.$emit("input", value);
    },
    parentCategory() {
      this.$refs.subCategoryRef.refresh();
    },
    selectedCategory(value) {
      this.prestation.category = value;
    },
  },
  methods: {
    save() {
      this.$emit("save");
      this.selectedCategory = null;
      this.parentCategory = null;
    },
    refreshSelectors() {
      Object.keys(this.$refs).forEach((ref) => {
        // refresh the data pickers to tirgger initial value
        this.$refs[ref].refresh();
      });
    },
    closeEdit() {
      this.$emit("finished");
    },
    fetchUnits(callback) {
      axios.get("/bputemplates/units").then((res) => {
        callback(res.data);
      });
    },
    fetchPrestationReferences(callback) {
      axios.get("/bputemplates/prestationreferences").then((res) => {
          callback(res.data);
        });
    },
    fetchTypes(callback) {
      axios.get("/bputemplates/prestationtypes").then((res) => {
        callback(res.data);
      });
    },
    fetchSubCategories(callback) {
      if (!this.parentCategory) {
        callback([]);
        return;
      }

      this.$api.fetchSubCategoriesAsList(this.parentCategory.id, (data) => {
        if (data.length < 1) {
          callback([]);
          this.selectedCategory = this.parentCategory;
          return;
        }
        this.selectedCategory = data[0];
        callback(data.sort((a, b) => a.name > b.name));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.white {
  color: #fff;
}
</style>
