<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$entitiesName.BpuTemplate"
        :remove="remove"
        :save="saveThenExit"
      >
        <h1 class="title" cy-data="cy-bputemplate-title">
          <breadcrumb
            location="/sst/bputemplates/"
            text="BPU Template"
          ></breadcrumb>
          <span
            :class="{ editable: getRights($entitiesName.BpuTemplate).update }"
            @click.stop="editName"
            v-if="!getRights($entitiesName.BpuTemplate).update || !editingName"
          >
            {{ template.name }}
            <span
              class="editable-icon"
              v-if="getRights($entitiesName.BpuTemplate).update"
            >
              <button class="button has-background-primary is-small">
                <i class="fa fa-pencil"></i>
              </button>
            </span>
          </span>
          <span v-else>
            <input
              class="input"
              type="text"
              v-model="template.name"
              required
              v-validate="{ required: true }"
              name="bputemplatename"
              :class="{ error: errors.has('bputemplatename') }"
            />
            <button
              class="button has-background-primary is-small"
              @click="saveAfterNameEdit"
            >
              <i class="fa fa-check"></i>
            </button>
            <button
              class="button has-background-primary is-small"
              @click="resetAfterNameEdit"
            >
              <i class="fa fa-times"></i>
            </button>
          </span>
        </h1>

        <template slot="actions">
          <form
            class="inline-form"
            method="post"
            target="_blank"
            :action="`${API}/bputemplate/${template.id}/export?sort=${sorting}`"
          >
            <button type="submit" class="button is-primary">
              {{ $t("common.exporter") }}
            </button>
            <input type="hidden" name="token" :value="authToken" />
          </form>
          <button
            class="button is-primary"
            v-if="getRights($entitiesName.BpuTemplate).update"
            @click="importClick"
          >
            {{ $t("common.import") }}
          </button>
        </template>
      </page-header>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          {{ $t("common.prestations") }}
        </div>
        <div>
          <flexi-table
            :style="{ width: '100%' }"
            :loader="fetchPrestations"
            :columns="columns"
            :showIndex="true"
            :hasActions="
              getRights($entitiesName.BpuTemplate).delete ||
              getRights($entitiesName.BpuTemplate).update
            "
            @input="updatePrestations"
            ref="presTable"
            @sortBy="updateSort"
          >
            <!-- list display  -->
            <template slot="dataRows" slot-scope="{ item, index }">
              <tr>
                <td>{{ index + 1 }}</td>
                <td>
                  <selector-field
                    v-if="editingRow === index"
                    v-model="item.reference"
                    :cy-data="`${getCyDataCode(item.code)}-reference`"
                    :options="fetchPrestationReferences"
                    :edit="true"
                    :inline="false"
                    :style="{ maxWidth: '200px' }"
                  >
                    <template slot-scope="{ option }">{{
                      option.name
                    }}</template>
                  </selector-field>
                  <div v-else :cy-data="`field-${getCyDataCode(item.code)}-reference-display`">
                    {{
                      item.reference ? item.reference.name : ''
                    }}
                  </div>
                </td>
                <td>
                  <selector-field
                    v-if="editingRow === index"
                    :cy-data="$t('category')"
                    v-model="currentRowParentCategory"
                    :options="$api.fetchAllParentCategories"
                    :edit="true"
                    :inline="false"
                    :style="{ maxWidth: '200px' }"
                  >
                    <template slot-scope="{ option }">{{
                      option.name
                    }}</template>
                  </selector-field>
                  <div v-else :cy-data="`field-${getCyDataCode(item.code)}-category-display`">
                    {{
                      item.category.parent
                        ? item.category.parent.name
                        : item.category.name
                    }}
                  </div>
                </td>
                <td>
                  <selector-field
                    v-if="editingRow === index"
                    v-model="currentRowCategory"
                    :options="fetchSubCategories"
                    :edit="true"
                    :inline="false"
                    ref="categoryRef"
                    :style="{ maxWidth: '200px' }"
                  >
                    <template slot-scope="{ option }">{{
                      option.name
                    }}</template>
                  </selector-field>
                  <div
                    v-else
                    :cy-data="`field-${getCyDataCode(item.code)}-sub-category-display`"
                  >
                    {{ item.category.parent ? item.category.name : "" }}
                  </div>
                </td>
                <td>
                  <text-field
                    :cy-data="`${getCyDataCode(item.code)}-code`"
                    v-model="item.code"
                    :inline="false"
                    :edit="editingRow === index"
                  ></text-field>
                </td>
                <td>
                  <text-field
                    v-model="item.description"
                    :inline="false"
                    :edit="editingRow === index"
                    :cy-data="`${getCyDataCode(item.code)}-designation`"
                  ></text-field>
                </td>
                <td>
                  <selector-field
                    :edit="editingRow === index"
                    :inline="false"
                    v-model="item.unit"
                    :options="fetchUnits"
                    :style="{ maxWidth: '200px' }"
                    :cy-data="`field-${getCyDataCode(item.code)}-unit-display`"
                  >
                    <template slot-scope="{ option }">{{
                      option.name
                    }}</template>
                  </selector-field>
                </td>
                <td>
                  <selector-field
                    :edit="editingRow === index"
                    :inline="false"
                    v-model="item.type"
                    :options="fetchTypes"
                    :style="{ maxWidth: '200px' }"
                    :cy-data="`field-${getCyDataCode(item.code)}-type-display`"
                  >
                    <template slot-scope="{ option }">{{
                      option.name
                    }}</template>
                  </selector-field>
                </td>
                <td
                  slot="action"
                  v-if="
                    getRights($entitiesName.BpuTemplate).delete ||
                    getRights($entitiesName.BpuTemplate).edit
                  "
                >
                  <div
                    v-if="editingRow === null || editingRow !== index"
                    class="action-buttons"
                  >
                    <button
                      v-if="getRights($entitiesName.BpuTemplate).delete"
                      class="button has-background-danger"
                      @click="deleteRow(item)"
                    >
                      <i class="fa fa-trash white"></i>
                    </button>
                    <button
                      v-if="getRights($entitiesName.BpuTemplate).update"
                      class="button has-background-primary"
                      :cy-data="`${getCyDataCode(item.code)}-edit-button`"
                      @click="editRow(index, item)"
                    >
                      <i class="fa fa-pencil white"></i>
                    </button>
                  </div>
                  <div v-else-if="editingRow === index" class="action-buttons">
                    <button
                      class="button has-background-grey"
                      :cy-data="`${getCyDataCode(item.code)}-cancel-button`"
                      @click="cancelEditRow()"
                    >
                      <i class="fa fa-ban white"></i>
                    </button>
                    <button
                      class="button has-background-success"
                      :cy-data="`${getCyDataCode(item.code)}-save-button`"
                      @click="saveRow(index)"
                    >
                      <i class="fa fa-save white"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </template>
            <!-- editor of newly created bpu template  -->
            <bpu-template-editor
              v-if="getRights($entitiesName.BpuTemplate).update"
              slot="lastDataRow"
              newMode
              v-model="newPrestation"
              @save="saveNewPrestation"
            ></bpu-template-editor>
          </flexi-table>
        </div>
      </div>
    </div>
    <input
      ref="templateFileInput"
      type="file"
      :style="{ display: 'none' }"
      name="bpuFile"
      accept="text/csv"
      @change="importFile"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import BPUTemplateEditor from "./BpuTemplateEdit";

const initialSorting = "category.parent.name,category.name,description,ASC";

export default {
  name: "bpu-template",
  inject: ["$validator"],
  components: {
    "bpu-template-editor": BPUTemplateEditor,
  },
  props: ["id"],
  data() {
    return {
      template: {
        id: null,
        name: "",
        prestations: [],
      },
      currentRowParentCategory: {
        name: "-",
        id: -1,
      },
      currentRowCategory: {
        name: "-",
        id: -1,
      },
      newPrestation: {
        bpu: null,
        reference: null,
        category: null,
        code: null,
        description: null,
        unit: null,
        type: null,
      },
      columns: [
        {
          title: this.$t("common.reference"),
          name: "reference",
          sort: "reference.name",
          class: "sortable",
        },
        {
          title: this.$t("common.category"),
          name: "category",
          sort: "category.parent.name",
          class: "sortable",
        },
        {
          title: this.$t("common.subcategory"),
          name: "subcategory",
          sort: "category.name",
          class: "sortable",
        },
        {
          title: this.$t("prestations.columns.itemCode"),
          name: "code",
          sort: "code",
          class: "sortable",
        },
        {
          title: this.$t("common.designation"),
          name: "description",
          sort: "description",
          class: "sortable",
        },
        {
          title: this.$t("common.unit"),
          name: "unit",
          sort: "unit.name",
          class: "sortable",
        },
        {
          title: this.$t("common.type"),
          name: "type",
          sort: "type.name",
          class: "sortable",
        },
      ],
      editingName: false,
      oldName: null,
      editingRow: null,
      editingRowData: null,
      API: axios.defaults.baseURL,
      authToken: this.$store.getters["auth/getToken"],
      sorting: initialSorting,
    };
  },
  computed: {
    ...mapGetters({
      getRights: "auth/getRights",
      loading: "states/isLoading",
    }),

  },
  watch: {
    currentRowParentCategory(val, oldVal) {
      if (val.id !== oldVal.id && this.$refs.categoryRef) {
        this.$refs.categoryRef.refresh();
        this.currentRowCategory = {
          id: -1,
          name: "---",
        };
      }
      if (this.currentRowCategory.id === -1 && this.editingRowData !== null) {
        this.editingRowData.category = val;
      }
    },
    currentRowCategory(val) {
      if (val.id === -1) {
        this.editingRowData.category = this.currentRowParentCategory;
        return;
      }
      this.editingRowData.category = val;
    },
    sorting() {
      this.$refs.presTable.fetch();
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      return axios.get(`/bputemplate/${this.id}`).then((res) => {
        this.newPrestation.bpu = res.data;
        this.template = {
          ...this.template,
          ...res.data,
        };
      });
    },
    refresh() {
      this.fetch().then(() => {
        this.$refs.presTable.fetch();
      });
    },
    fetchPrestations(callback) {
      axios
        .get(`/bputemplate/${this.id}/prestations?sort=${this.sorting}`)
        .then((res) => {
          callback(res.data);
        });
    },
    fetchSubCategories(callback) {
      if (!this.currentRowParentCategory.id) {
        callback([]);
        return;
      }

      this.$api.fetchSubCategoriesAsList(
        this.currentRowParentCategory.id,
        (data) => {
          data.unshift({ id: -1, name: "---" });
          callback(data.sort((a, b) => a.name > b.name));
        }
      );
    },
    fetchUnits(callback) {
      axios.get("/bputemplates/units").then((res) => {
        callback(res.data);
      });
    },
    fetchTypes(callback) {
      axios.get("/bputemplates/prestationtypes").then((res) => {
        callback(res.data);
      });
    },
    fetchPrestationReferences(callback) {
      axios.get("/bputemplates/prestationreferences").then((res) => {
          callback(res.data);
        });
    },
    updatePrestations(value) {
      this.template.prestations = value;
    },
    editRow(row, item) {
      // category checks to set existing value
      if (item.category.parent) {
        this.currentRowParentCategory = item.category.parent;
        this.currentRowCategory = item.category;
      } else {
        this.currentRowParentCategory = item.category;
      }
      this.editingRowData = item;
      this.editingRow = row;
    },
    cancelEditRow(){
      this.editingRow = null;
    },
    saveRow(row) {
      const prestation = this.template.prestations[row];
      if (prestation) {
        axios.put(`prestation/${prestation.id}`, prestation).then(() => {
          this.$refs.presTable.fetch();
          this.editingRow = null;
          this.editingRowData = null;
        });
      }
    },
    deleteRow(item) {
      this.$awn.confirm(
        "Êtes-vouus sûr de vouloir supprimer cet élément ?",
        () => {
          axios.delete(`/prestation/${item.id}`).then(() => {
            this.$refs.presTable.fetch();
          });
        }
      );
    },
    saveNewPrestation() {
      const newPrestation = JSON.parse(JSON.stringify(this.newPrestation));

      const empty = Object.keys(newPrestation).some(
        (key) => newPrestation[key] === null || !newPrestation[key]
      );
      if (empty) {
        // eslint-disable-next-line
        alert(
          "Impossible de créer une nouvelle prestation. Certains champs sont vides."
        );
        return;
      }
      axios
        .post("/prestation", newPrestation)
        .then(() => {
          this.refreshAfterNew();
        })
        .catch(() => {
          console.log("error catched");
          // do nothing?
        });
    },
    importClick() {
      // eslint-disable-next-line
      this.$refs.templateFileInput.click();
    },
    importFile(e) {
      if (e.target.files[0]) {
        const form = new FormData();
        form.set("file", e.target.files[0]);
        axios
          .post(`/bputemplate/${this.template.id}/import`, form, {
            headers: { "Content-Type": "charset=UTF-8" },
          })
          .then(() => {
            this.refresh();
            // eslint-disable-next-line
            alert("Import effectué avec succès");
          })
          .catch((err) => {
            // eslint-disable-next-line
            console.error(err);
          });
      }
    },
    save(callback) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          axios
            .put(`/bputemplate/${this.template.id}`, this.template)
            .then(() => {
              if (typeof callback === "function") {
                callback();
              }
            })
            .catch((e) => {
              // eslint-disable-next-line
              console.error(e.message);
            });
        }
      });
    },
    saveThenExit() {
      const backToList = () => {
        this.$router.push("/sst/bputemplates");
      };
      this.save(backToList);
    },
    refreshAfterNew() {
      this.newPrestation = {
        // reset data
        bpu_id: this.template,
        reference: null,
        category: null,
        code: null,
        description: null,
        unit: null,
        type: null,
      };
      this.refresh();
    },
    editName() {
      this.editingName = true;
      this.oldName = this.template.name;
    },
    saveAfterNameEdit() {
      this.save(this.closeNameEdit);
    },
    resetAfterNameEdit() {
      this.template.name = this.oldName;
      this.oldName = null;
      this.closeNameEdit();
    },
    closeNameEdit() {
      this.editingName = false;
      this.refresh();
    },
    remove() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios.delete(`/bputemplate/${this.id}`).then((response) => {
            if (response.data.success) {
              this.$router.replace("/sst/bputemplates");
            }
          })
      );
    },
    updateSort(newValue) {
      if (newValue === "initialValue") {
        this.sorting = initialSorting;
        return;
      }

      this.sorting = newValue;
    },
    getCyDataCode(code){
      return code.replaceAll(' ', '-')
    }
  },
};
</script>

<style lang="scss" scoped>
.field {
  min-width: 100px;
}

.white {
  color: #fff;
}

.title .input {
  width: unset;
}

.editable {
  cursor: pointer;

  .editable-icon {
    display: none;
  }

  &:hover {
    .editable-icon {
      display: inline;
    }
  }
}

.inline-form {
  display: inline;
}

.action-buttons {
  min-width: 85px;
  text-align: right;
}

.error {
  border: 2px solid red;
}
</style>
