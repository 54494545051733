import Vue from 'vue';
import VueI18n from 'vue-i18n'
import fr from './fr';
import en from './en';

const messages = {
  en,
  fr
}

export const globalLocale = process.env.VUE_APP_LOCALE || 'fr_FR'
export const shortLocale = globalLocale.substring(0, 2)
export const currency = shortLocale === 'fr' ? 'EUR' : 'GBP'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: shortLocale,
  fallbackLocale: 'fr', //Default to French when there is no English
  silentFallbackWarn: true, //Suppress warning
  messages
})
