<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Role"
                   :create="openCreateModal">
        <h1 class="title">{{$t('common.role')}}</h1>
      </page-header>

      <datatable :fetch="$api.fetchRoles"
                 ref="roleList">
        <div slot="name"
             slot-scope="{ item }"
             :title="$t('common.name')">{{ item.name }}</div>
        <div slot="description"
             slot-scope="{ item }"
             :title="$t('common.description')">{{ item.description }}</div>
        <div slot="actions"
             slot-scope="{ item }">
          <router-link :to="'/role/' + item.id"
                       class="button is-primary">
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>

    <modal ref="createRoleModal"
           @create="createRole"
           :title="$t('roles.roleCreation')">
      <div class="columns">
        <div class="column is-half">
          <text-field :label="$t('common.name')"
                      v-model="role.name"
                      :inline="false"
                      :edit="true"
                      required
                      data-test="sel-role-name"></text-field>
          <textarea-field :label="$t('common.description')"
                          v-model="role.description"
                          :inline="false"
                          :edit="true"
                          required
                          data-test="sel-role-description"></textarea-field>
          <selector-field :options="$api.fetchVisibilityTypes"
                          :label="$t('common.visibility')"
                          v-model="role.visibility"
                          :inline="false"
                          :edit="true"
                          data-test="sel-role-visibility"></selector-field>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';
import Datatable from '../../components/Datatable';

export default {
  name: 'roles',
  inject: ['$validator'],
  components: {
    Datatable,
  },
  data () {
    return {
      role: {},
    };
  },
  methods: {
    openCreateModal () {
      this.$refs.createRoleModal.open();
    },
    createRole () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const role = {
            ...this.role,
          };
          const visibilityTypes = this.$api.fetchVisibilityTypes();
          const visibilityCode = visibilityTypes.findIndex(
            item => item === role.visibility,
          );

          role.visibility = visibilityCode + 1;
          axios.post('/role', role).then((response) => {
            if (response.data.success) {
              this.role = {};
              this.$refs.roleList.refresh();
              this.$refs.createRoleModal.close();
              this.$router.push({
                name: 'Role',
                params: { id: response.data.id },
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
