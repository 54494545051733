<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Order">
        <h1 class="title">{{$t('common.retentions')}}</h1>
      </page-header>

      <div class="filters has-background-primary">
        <div class="filter-item">
          <many2one-field :label="$t('common.projects')"
                          v-model="projects"
                          :fetch="$api.fetchProjects"
                          reference="name"
                          entity="project"
                          :columns="{ name: $t('expressions.projectName')}"
                          :hasFilter="true"
                          :inline="false"
                          :edit="true"></many2one-field>
        </div>
        <div class="filter-item">
          <many2one-field :label="$t('entities.contractorAgreement')"
                          v-model="markets"
                          :fetch="$api.fetchMarkets"
                          reference="edifice"
                          entity="subcontractor_market"
                          :columns="marketColumns"
                          :hasFilter="true"
                          :inline="false"
                          :edit="true">
          </many2one-field>
        </div>
        <div class="filter-item">
          <many2one-field :label="$t('entities.oscarSubProject')"
                          v-model="subProjects"
                          :fetch="$api.fetchOscarSubProjects"
                          reference="name"
                          entity="sheet"
                          :columns="{ arcgisName: $t('oscarSubProjects.columns.arcgisName'), nroName: $t('common.nro'), offerIdPm: $t('common.pm') }"
                          :hasFilter="true"
                          :inline="false"
                          :edit="true"></many2one-field>
        </div>
        <div class="filter-item">
          <many2one-field :label="$t('common.imputations')"
                          v-model="imputations"
                          :fetch="$api.fetchImputations"
                          reference="eotp"
                          entity="imputation"
                          :columns="{ eotp: $t('common.eotp') }"
                          :hasFilter="true"
                          :inline="false"
                          :edit="true"></many2one-field>
        </div>
        <div class="filter-item form"
             v-if="userRoleOptions.includes($roleOptions.OS.EDIT_FINANCIAL_INFO)">
          <div>
            <selector-field v-model="dateSelected"
                            :options="fetchDateTypes"
                            edit
                            :pKey="'key'"
                            :inline="false" />
            <date-field v-model="newDate"
                        edit
                        :inline="false"></date-field>
          </div>
          <div class="button is-primary"
               :disabled="!newDate || selectedOrders.length === 0"
               @click="setDatesToOrders">{{$t('common.save')}}</div>
        </div>
      </div>

      <datatable :fetch="(p) => $api.fetchOrderWarranties(p, params)"
                 ref="orderWarantiesList"
                 :enableDownload="true"
                 objectName="orders/warranties"
                 order="DESC"
                 selector
                 v-model="selectedOrders"
                 :additional-download-params="params">
        <div slot="reference"
             slot-scope="{ item }"
             :title="$t('warranties.orderReference')"
             :sortable="true">
          <restricted-link entity="order"
                           :to="`/sst/order/${item.id}`" v-if="item.id">
            {{ item.reference }}
          </restricted-link>
          <span v-else>{{ item.reference }}</span>
        </div>
        <div slot="follower"
             slot-scope="{ item }"
             :title="$t('orders.columns.orderManagedBy')"
             :sortable="true">{{ item.follower && item.follower.name }}</div>
        <div slot="statusWF"
             slot-scope="{ item }"
             :title="$t('common.status')">{{ item.nodeName }}</div>
        <div slot="endDate"
             slot-scope="{ item }"
             :title="$t('warranties.orderEndDate')"
             :sortable="true"
             nofilter>{{ item.endDate }}</div>
        <div slot="deliveryDate"
             slot-scope="{ item }"
             :title="$t('warranties.deliveryDate')"
             :sortable="true"
             nofilter>{{ item.deliveryDate | reverseDate}}</div>
        <div slot="releaseDate"
             slot-scope="{ item }"
             :title="$t('warranties.releaseDate')"
             :sortable="true"
             nofilter>{{ item.releaseDate | reverseDate}}</div>
        <div slot="amount"
             slot-scope="{ item }"
             :title="$t('warranties.attAmount')"
             class="has-text-right"
             :help="$t('warranties.attAmountHelp')"
             nofilter>{{ item.alreadyInvoiced | priceEUR }}</div>
        <div slot="rg"
             slot-scope="{ item }"
             :title="$t('common.rg')"
             class="has-text-right"
             nofilter
             :help="$t('warranties.rgHelp')">{{ item.rg | priceEUR }}</div>
        <div slot="depositUsed"
             slot-scope="{ item }"
             :title="$t('warranties.bondRg')"
             class="has-text-right"
             nofilter
             :help="$t('warranties.bondRgHelp')">{{ item.depositUsed | priceEUR }}</div>
        <div slot="rgToRelease"
             slot-scope="{ item }"
             :title="$t('warranties.withheldRg')"
             class="has-text-right"
             nofilter>{{ item.rgToRelease | priceEUR }}</div>
        <div slot="rgReleased"
             slot-scope="{ item }"
             :title="$t('warranties.rgReleased')"
             class="has-text-right"
             nofilter
             :help="$t('warranties.rgReleasedHelp')">{{ item.rgReleased | priceEUR }}</div>
        <div slot="rgleft"
             slot-scope="{ item }"
             :title="$t('warranties.rgToRelease')"
             class="has-text-right"
             nofilter
             :help="$t('warranties.rgToReleaseHelp')">{{ item.rgLeft | priceEUR }}</div>
      </datatable>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import Datatable from '../../components/Datatable';
import AgencyDisplay from '../../components/displays/AgencyDisplay';

export default {
  name: 'OrderWarranties',
  components: {
    Datatable
  },
  data () {
    return {
      marketColumns: {
        'agency.company.name': this.$t('common.company'),
        agency: {
          title: this.$t('agencies.columns.edifice'),
          component: AgencyDisplay,
          filter: ''
        }
      },
      selectedOrders: [],
      projects: [],
      markets: [],
      subProjects: [],
      imputations: [],
      dateSelected: '',
      newDate: null
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      userRoleOptions: 'auth/getOptions',
    }),
    params () {
      return 'projects=' + this.projectIds + '&markets=' + this.marketIds + '&subprojects=' + this.subProjectIds + '&imputations=' + this.imputationIds
    },
    projectIds () {
      if (this.projects.length === 0) return []

      return this.projects.reduce((acc, val) => {
        return [...acc, val.id]
      }, [])
    },
    marketIds () {
      if (this.markets.length === 0) return []

      return this.markets.reduce((acc, val) => {
        return [...acc, val.id]
      }, [])
    },
    subProjectIds () {
      if (this.subProjects.length === 0) return []

      return this.subProjects.reduce((acc, val) => {
        return [...acc, val.id]
      }, [])
    },
    imputationIds () {
      if (this.imputations.length === 0) return []

      return this.imputations.reduce((acc, val) => {
        return [...acc, val.id]
      }, [])
    },
  },
  watch: {
    projects () {
      this.$refs.orderWarantiesList.refresh()
    },
    markets () {
      this.$refs.orderWarantiesList.refresh()
    },
    subProjects () {
      this.$refs.orderWarantiesList.refresh()
    },
    imputations () {
      this.$refs.orderWarantiesList.refresh()
    }
  },
  methods: {
    fetchDateTypes (callback) {
      callback(
        {
          deliveryDate: 'Date PV de réception',
          releaseDate: 'Date libération de la RG'
        }
      );
    },
    setDatesToOrders () {
      let orders = JSON.parse(JSON.stringify(this.selectedOrders))
      orders.forEach(o => {
        o[this.dateSelected] = this.newDate
      })

      axios.put(`/orders`, orders).then(() => {
        this.newDate = null
        this.$refs.orderWarantiesList.refresh()
      })
    }
  },
};
</script>

<style scoped lang="scss">
.filters {
  display: flex;
  padding: 10px;
  margin-bottom: 15px;
}
.filter-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  margin: 0 10px;
  overflow: hidden;
  & > * {
    margin: 0 !important;
  }
  .filter-item {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 15px;
    background-color: white;
    border-radius: 5px;
    margin: 0 10px;
    overflow: hidden;
    & > * {
      margin: 0 !important;
    }
    height: 115px;
    .button {
      align-self: center;
    }
    &:hover {
      height: initial;
      min-height: 115px;
    }
    &.form {
      min-width: max-content;
    }
  }
}
</style>
