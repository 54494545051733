<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header>
        <h1 class="title">
          <span> {{ $t("account.myAccount") }}</span>
        </h1>
      </page-header>

      <div class="columns" v-if="userRoleOptions.includes($roleOptions.USERS.CAN_DELEGATE)">
        <div class="column">
          <div class="panel">
            <div class="panel-heading has-background-primary">
              {{ $t('account.delegation.delegations') }}
              <button
                slot="activator"
                cy-data="add-delegation-button"
                class="button is-small is-success"
                @click="openDelegationModal"
              >
                <i class="fa fa-plus"></i>
              </button>
            </div>
            <div class="panel-block">
              <div class="has-text-centered is-full-width" v-if="userCreatedDelegations.length <= 0">
                {{ $t('account.delegation.noDelegation') }}
              </div>
              <table class="table is-fullwidth" v-else>
                <thead>
                <tr>
                  <td>{{ $t("account.delegation.delegateTo") }}</td>
                  <td>{{ $t('expressions.startDate') }}</td>
                  <td>{{ $t('expressions.endDate') }}</td>
                  <td></td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(d, index) in userCreatedDelegations" :key="index" :cy-data="`user-delegation-${index}`">
                  <td>{{ d.delegateTo.name }}</td>
                  <td>{{ d.startDate | date }}</td>
                  <td>{{ d.endDate | date }}</td>
                  <td>
                    <button
                      class="button is-small is-danger"
                      @click="deleteDelegation(d)"
                      cy-data="delete-delegation"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="panel">
            <div class="panel-heading has-background-primary">
              {{ $t('account.delegation.receivedDelegations') }}
            </div>
            <div class="panel-block">
              <div class="has-text-centered is-full-width" v-if="userReceivedDelegations.length <= 0">
                {{ $t('account.delegation.noReceivedDelegation') }}
              </div>
              <table class="table is-fullwidth" v-else>
                <thead>
                <tr>
                  <td>{{ $t("account.delegation.delegateFrom") }}</td>
                  <td>{{ $t('expressions.startDate') }}</td>
                  <td>{{ $t('expressions.endDate') }}</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(d, index) in userReceivedDelegations" :key="index">
                  <td>{{ d.delegateFrom.name }}</td>
                  <td>{{ d.startDate | date }}</td>
                  <td>{{ d.endDate | date }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      ref="addDelegationModal"
      :title="$t('account.delegation.delegationCreation')"
      @create="createDelegation"
      @close="closeModal"
    >
      <div class="columns">
        <div class="column">
          <many2one-field
            :label="$t('entities.user')"
            v-model="delegation.delegateTo"
            :fetch="$api.fetchDelegationUsers"
            reference="name"
            :columns="userColumns"
            :inline="false"
            :edit="true"
            required
            :hasFilter="true"
            v-validate="{ required: true }"
            name="users2one"
            :error="errors.has('markets2one')"
            cy-data="delegation-user"
          ></many2one-field>
          <date-field
            :label="$t('expressions.startDate')"
            v-model="delegation.startDate"
            :inline="false"
            :edit="true"
            cy-data="delegation-start-date"
            required
          ></date-field>
          <date-field
            :label="$t('expressions.endDate')"
            v-model="delegation.endDate"
            :inline="false"
            :edit="true"
            cy-data="delegation-end-date"
            @focusout="updateDelegationEndDate"
            required
          ></date-field>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>

import moment from 'moment';
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: 'account-manage',
  data() {
    return {
      delegation: {},
      userCreatedDelegations: [],
      userReceivedDelegations: [],
      userColumns: {
        name: this.$t("common.name"),
        mail: "E-Mail",
        "role.name": this.$t("entities.role"),
        service: this.$t("common.service"),
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapGetters({
      userRoleOptions: "auth/getOptions",
    }),
  },
  methods: {
    fetchData()
    {
      axios
        .get(`/delegation/all`)
        .then((response) => {
          this.userCreatedDelegations = response.data;
        }).then(() => {
        axios
          .get(`/delegation/my-delegations`)
          .then((response) => {
            this.userReceivedDelegations = response.data;
            this.$store.dispatch("states/setEdition", false);
          })
      })
    },
    openDelegationModal() {
      this.$refs.addDelegationModal.open();
    },
    updateDelegationEndDate(val)
    {
      if(moment(val).isBefore(this.delegation.startDate))
        this.$nextTick(() => this.delegation.endDate = this.delegation.startDate);
    },
    createDelegation()
    {
      this.$validator.validateAll().then((result) => {
        if(!result)
          return;

        axios.post("/delegation/create", this.delegation).then((response) => {
          if (!response.data.success) {
            return;
          }
          this.delegation = {}
          this.$refs.addDelegationModal.close();
          this.fetchData();
        });
      })
    },
    deleteDelegation(delegation)
    {
      this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () => {
          axios.delete(`/delegation/${delegation.id}`).then(() => {
            this.fetchData();
          });
        }
      );
    },
    closeModal()
    {
      this.delegation = {};
    }
  }
}
</script>

<style lang="scss" scoped>
.is-full-width {
  width: 100%;
  flex: 1;
}
</style>
