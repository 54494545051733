<template>
  <field
    :cy-data="cyData"
    :readonly="readonly"
    :edit="edit"
    :inline="inline"
    :label="label"
    :required="required"
  >
    <!-- Mode read -->
    <span slot="value" v-if="val">
      <span v-if="_events.click && _events.click[0] != null">
        <a @click="clickCheck" :cy-data="`field-${cyData}-display`">
          <slot name="display" :item="val">{{
            val[reference] ? val[reference] : ""
          }}</slot>
        </a>
      </span>
      <span v-else :cy-data="`field-${cyData}-display`">
        <slot name="display" :item="val">{{
          val[reference] ? val[reference] : ""
        }}</slot>
      </span>
    </span>
    <slot name="helper"></slot>

    <!-- Mode edit -->
    <!-- Single select -->
    <span v-if="singleSelect && val && !readonly">
      <span v-if="_events.click">
        <a @click="$emit('click', val)">
          <slot name="display" :item="val">{{
            val[reference] ? val[reference] : ""
          }}</slot>
        </a>
      </span>
      <span v-else>
        <slot name="display" :item="val">{{
          val[reference] ? val[reference] : ""
        }}</slot>
      </span>
    </span>

    <!-- Multi Select -->
    <span
      class="multiselect-values"
      v-if="!singleSelect && value && value.length > 0"
    >
      <div v-for="(object, index) in value" :key="index">
        {{ object[reference] }}
      </div>
    </span>
    <modal
      v-if="!readonly"
      :title="title"
      ref="selectionModal"
      @close="cancel"
      @create="emitInput(val)"
      :action="'Sélectionner'"
    >
      <button
        slot="activator"
        class="button is-small"
        :cy-data="`button-${cyData}`"
        @click="activated"
      >
        <i class="fa fa-pencil"></i>
      </button>
      <div v-if="singleSelect" slot="toolbar" class="close-button">
        <button class="button is-medium" @click="$refs.selectionModal.close()">
          {{ $t("common.close") }}
        </button>
      </div>

      <datatable
        :single-select="singleSelect"
        :size="15"
        :fetch="fetch"
        v-model="val"
        @input="singleSelect ? emitInput($event) : null"
        :columns="columns"
        :filterColumns="hasFilter"
        selector
        :order="order"
      ></datatable>
    </modal>
    <button
      v-if="val && !readonly && !required"
      class="button is-danger is-small"
      @click="singleSelect ? emitInput(null) : emitInput([])"
    >
      <i class="fa fa-trash"></i>
    </button>
    <span v-if="error" class="tag is-danger"
      >&nbsp;{{ $t("errors.many2OneField.selectItem", { field: label}) }}</span
    >
  </field>
</template>

<script>
import uid from "uniqid";
import { mapGetters } from "vuex";
// vee-validate required only works with this field if there is a falsy, or no value in the v-model it's mapped to

export default {
  name: "many2one-field",
  props: {
    entity: {
      type: [String, null],
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "",
    },
    fetch: Function,
    value: {},
    reference: String,
    columns: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: uid(),
    },
    hasFilter: {
      type: Boolean,
      default: false,
    },
    order: {
      type: String,
      default: "ASC",
    },
    cyData: {
      type: String,
      default: function () {
        return this.label.replaceAll(/[ ']/g, "");
      },
    },
    title: {
      type: String,
      default: function () {
        return this.$t("common.selection");
      },
    },
  },
  data() {
    return {
      val: null,
      internal_id: uid(),
    };
  },
  mounted() {
    this.val = Array.isArray(this.value)
      ? JSON.parse(JSON.stringify(this.value))
      : this.value;
  },
  watch: {
    value(val) {
      this.val = JSON.parse(JSON.stringify(val));
    },
  },
  computed: {
    ...mapGetters({
      getRights: "auth/getRights",
    }),
    singleSelect() {
      return !Array.isArray(this.val);
    },
  },
  methods: {
    emitInput(item) {
      this.$emit("input", item);
      this.$refs.selectionModal.close();
    },
    clickCheck() {
      if (this.entity && !this.getRights(this.entity).read) {
        this.$awn.warning(this.$t("expressions.notAccessToEntity"));
        return;
      }
      this.$emit("click", this.val);
    },
    cancel() {
      if (this.singleSelect) {
        return;
      }
      this.val = JSON.parse(JSON.stringify(this.value));
    },
    activated()
    {
      this.$emit("activated", null);
    }
  },
};
</script>

<style lang='scss' scoped>
.close-button {
  width: 100%;
  text-align: center;
}

.value {
  padding: 0 12px;
}
</style>
