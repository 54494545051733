<template>
  <div
    class="field"
    :class="{ 'is-horizontal': inline }"
    :cy-data="'field-' + cyDataString"
  >
    <div class="input-label" :class="{ inline: inline }">
      {{ label + ((edit || globalEdit) && required ? "*" : "") }}
    </div>
    <slot v-if="readonly || (!edit && globalEdit === false)" name="value">
      <span :cy-data="'field-' + cyDataString + '-display'">
        <!-- FIXME: To be removed from the Field component. Each child should override the slot "value" to display its content properly. -->
        <template v-if="isCheckbox">
          <i
            :class="{
              fa: true,
              'fa-check': isChecked,
              'fa-times': !isChecked,
              'has-text-success': isChecked,
              'has-text-danger': !isChecked,
            }"
          ></i>
        </template>
        <template v-if="type === 'percentage'">{{ value }} %</template>
        <template v-if="type === 'number'">{{ value | number }}</template>
        <template v-if="type === 'price'">{{ value | priceEUR }}</template>
        <template v-if="!isCheckbox && !type">{{ value }}</template>
      </span>
    </slot>
    <slot v-if="readonly || edit || globalEdit === true"></slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "field",
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "",
    },
    value: {},
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isCheckbox: {
      type: Boolean,
      default: false,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
    cyData: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      globalEdit: "states/isEdition",
    }),
    cyDataString() {
      const fieldLabel = this.cyData || this.label;
      if (!fieldLabel) {
        return "";
      }
      return fieldLabel.replaceAll(/[ ']/g, "");
    },
  },
};
</script>

<style lang='scss' scoped>
.field {
  width: 100%;
  margin-right: 4px;
  align-items: center;
}

.input-label {
  font-weight: bold;
  vertical-align: top;
  padding-right: 4px;
}

.inline {
  display: inline-block;
  flex-shrink: 0;
  width: 30%;
  border-bottom: 1px dotted #ddd;
}
</style>
