var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{attrs:{"cy-data":"field-agency-display"}},[_vm._v(" "+_vm._s(_vm.value.edifice)+" "),(_vm.value.synchronize)?_c('span',{staticClass:"icons"},[_c('i',{staticClass:"fa fa-check-circle",class:{
      'fa-check-circle has-text-success': _vm.value.status === 1,
      'fa-exclamation-circle has-text-warning': _vm.value.status === 2,
      'fa-times-circle has-text-danger': _vm.value.status === 3,
    }}),_c('i',{staticClass:"fa fa-folder",class:{
      'has-text-success': _vm.value.documentsCompleted,
      'has-text-danger': !_vm.value.documentsCompleted,
    }})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }