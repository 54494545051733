<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$entitiesName.Category"
        :edit="edit"
        :save="save"
        :cancel="cancel"
        :remove="remove"
      >
        <h1 class="title">
          <breadcrumb
            location="/categories"
            :text="$t('common.category')"
          ></breadcrumb>
          {{ category.name }}
        </h1>
      </page-header>

      <div class="panel infos">
        <div class="panel-heading has-background-primary">
          {{ $t("common.information") }}
        </div>
        <div class="panel-block is-block no-border">
          <many2one-field
            :label="$t('categories.parentCategory')"
            v-model="category.parent"
            :fetch="$api.fetchParentCategories"
            reference="name"
            @click="(item) => $router.push('/category/' + item.id)"
            entity="category"
            :columns="columnsCategories"
            :hasFilter="true"
            v-validate="{ required: true }"
            name="category2one"
            :error="errors.has('category2one')"
          ></many2one-field>
          <text-field
            :label="$t('common.name')"
            v-model="category.name"
            required
          ></text-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "category",
  props: ["id"],
  data() {
    return {
      category: {},
      columnsCategories: {
        name: this.$t("common.name"),
      },
    };
  },
  computed: {
    ...mapGetters({
      editMode: "states/isEdition",
    }),
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    id() {
      this.fetchData();
    },
  },
  methods: {
    fetchData() {
      axios.get(`/category/${this.id}`).then((response) => {
        this.category = response.data;
        this.$store.dispatch("states/setEdition", false);
      });
    },
    edit() {
      this.$store.dispatch("states/setEdition", true);
    },
    cancel() {
      this.fetchData();
    },
    save() {
      return axios
        .put(`/category/${this.id}`, this.category)
        .then((response) => {
          if (response.data.success) {
            this.fetchData();
          }
        });
    },
    remove() {
      const self = this;
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios.delete(`/category/${self.id}`).then((response) => {
            if (response.data.success) {
              self.$router.replace("/categories");
            }
          })
      );
    },
  },
};
</script>

<style scoped>
</style>
