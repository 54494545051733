<template>
  <router-link class="breadcrumb" :to="location">
    {{ text }}
    <i class="fa fa-chevron-right fa-xs"></i>
  </router-link>
</template>
<script>
export default {
  name: 'breadcrumb',
  props: {
    location: String,
    text: String,
  },
};
</script>
<style lang="scss" scoped>
.breadcrumb {
  color: #289fc3;
  font-size: inherit;
}

.fa-xs {
  font-size: 0.75em;
}
</style>

