<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header entity="prestation_type"
                   :edit="edit"
                   :save="save"
                   :cancel="cancel"
                   :remove="remove">
        <h1 class="title">
          <breadcrumb location="/prestationtypes"
                      :text="$t('entities.prestationType')"></breadcrumb>
          {{ prestationtype.name }}
        </h1>

      </page-header>
      <div class="panel">
        <div class="panel-heading has-background-primary">
          <span>{{$t('common.information')}}</span>
        </div>
        <div class="panel-block is-block no-border">
          <div class="columns">
            <div class="column is-half"
                 v-if="prestationtype">
              <text-field :label="$t('common.name')"
                          v-model="prestationtype.name"
                          required></text-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'prestationtype',
  props: ['id'],
  data () {
    return {
      prestationtype: {
        name: null,
        id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      editMode: 'states/isEdition',
      getRights: 'auth/getRights',
      user: 'auth/getUser',
    }),
  },
  mounted () {
    this.fetchData();
  },
  methods: {
    fetchData () {
      return axios.get(`/prestationtype/${this.id}`)
        .then((r) => {
          this.prestationtype = r.data;
        });
    },
    remove () {
      return this.$awn.confirm(
        'Êtes-vous sûr de vouloir supprimer cet élément ?',
        () =>
          axios
            .delete(`/prestationtype/${this.id}`, this.prestationtype)
            .then((response) => {
              if (response.data.success) {
                this.$router.replace('/prestationtypes');
              }
            }),
      );
    },
    save () {
      return this.$validator.validateAll().then((result) => {
        if (result) {
          return axios
            .put(`/prestationtype/${this.id}`, this.prestationtype)
            .then((response) => {
              if (response.data.success) {
                this.cancel();
              }
            });
        }
        return Promise.resolve();
      });
    },
    edit () {
      this.$store.dispatch('states/setEdition', true);
    },
    cancel () {
      this.fetchData()
        .then(() => {
          this.$store.dispatch('states/setEdition', false);
        });
    },
  },
};
</script>

