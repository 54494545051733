<template>
  <div id="app">
    <header v-if="isAuthenticated">
      <navbar @onRTClick="openEmailModal"></navbar>
    </header>
    <router-view />
    <email-modal ref="emailModal" />
    <footer>
      Interface de Suivi Financier<br />
      <br />
      Axione ISF v{{ env.VUE_APP_VERSION }} by
      <a href="https://aliengen.com#axisf">AlienGen</a><br />
      <span v-if="!error">
        <span v-if="api_version">API v{{ api_version }}</span>
        <span v-if="!api_version">Loading...</span>
      </span>
      <span v-if="error">{{ error }}</span>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Navbar from "./components/Navbar";
import EmailModal from "./components/EmailModal";

export default {
  components: {
    Navbar,
    EmailModal,
  },
  name: "App",
  data() {
    return {
      api_version: null,
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      env: "states/getEnv",
    }),
  },
  mounted() {
    this.loadApiVersion();
  },
  methods: {
    loadApiVersion() {
      axios
        .get("/version")
        .then((response) => {
          this.api_version = response.data;
        })
        .catch((error) => {
          this.error = error.response
            ? error.response.data
            : "API not available!";
        });
    },
    openEmailModal() {
      this.$refs.emailModal.open();
    },
  },
};
</script>

<style>
body {
  overflow-x: auto;
}

#app {
}

footer,
footer a {
  margin-top: 40px;
  margin-bottom: 40px;
  color: #777;
  text-align: center;
  font-size: 12px;
}

.container {
  padding-top: 75px;
}

.container .header {
  margin-bottom: 15px;
}

.container .header h1 {
  display: inline-block;
  font-weight: bold;
  font-size: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container .header button {
  vertical-align: super;
  color: white;
}

.content .title {
  padding: 10px;
  font-size: 25px;
  padding-left: 23px;
  margin: -20px;
  margin-bottom: 23px;
  color: white;
}

.content .title button {
  margin-left: 30px;
}

.columns:last-child {
  margin-bottom: 0;
}
</style>
