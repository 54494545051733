<template>
  <field
    :edit="edit"
    :inline="inline"
    :label="label"
    :value="val | date"
    :readonly="readonly"
    :required="required"
    :cy-data="cyDataString"
  >
    <input
      class="input"
      type="date"
      @input="input"
      :value="val | date"
      :required="required"
      v-validate="{ mustBeAfter: mustBeAfterValue }"
      :name="internal_id"
      :class="{ error: errors.has(internal_id) }"
      v-if="!readonly"
      :cy-data="'input-' + cyDataString"
      @focusout="focusout"
    />
  </field>
</template>

<script>
import uid from "uniqid";

export default {
  name: "date-field",
  inject: ["$validator"],
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    mustBeAfter: {
      type: String,
      default: "",
    },
    cyData: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      val: "",
      internal_id: uid(),
    };
  },
  computed: {
    mustBeAfterValue() {
      return this.mustBeAfter ? this.mustBeAfter : false;
    },
    cyDataString() {
      const fieldLabel = this.cyData ? this.cyData : this.label;
      return fieldLabel.replaceAll(/[ ']/g, "");
    },
  },
  mounted() {
    this.val = this.value;
  },
  watch: {
    value(val) {
      this.val = val;
    },
  },
  methods: {
    input(val) {
      this.$emit("input", val.target.value);
    },
    focusout(val) {
      this.$emit('focusout', val.target.value);
    },
  },
};
</script>

<style lang='scss' scoped>
.input {
  max-width: 500px;
}

.error {
  border: 2px solid red;
}
</style>
