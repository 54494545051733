<template>
  <field :label="label" :inline="false">
    <input type="checkbox" :checked="isEntityTypeAllSelected" @click="e => selectAllEntities(e.target.checked)" class="switch is-success is-rounded" :id="id" />
    <label :for="id"></label>
  </field>
</template>
<script>
import ui from 'uniqid';

export default {
  name: 'select-all',
  props: {
    entities: Array,
    value: Array,
    pKey: String,
    label: {
      type: String,
      default: 'Tout sélectioner',
    },
  },
  data() {
    return {
      val: this.value,
      id: ui(),
    };
  },
  watch: {
    value(val) {
      this.val = val;
    },
  },
  computed: {
    isEntityTypeAllSelected() {
      if (!this.pKey && typeof this.entities[this.pKey] === 'undefined') {
        return this.entities.every(entity => this.val.includes(entity));
      }

      return this.entities.every(entity => this.val.includes(entity[this.pKey]));
    },
  },
  methods: {
    selectAllEntities(all) {
      let entities;
      if (!this.pKey && typeof this.entities[this.pKey] === 'undefined') {
        entities = this.entities;
      } else {
        entities = this.entities.map(e => e[this.pKey]);
      }

      let valued = [];
      if (all) {
        entities.filter(v => !this.value.includes(v))
          .forEach(v => valued.push(v));

        this.value.forEach(v => valued.push(v));
      } else {
        valued = this.value.filter(v => !entities.includes(v));
      }

      // const valued = all ? entities : [];
      this.$emit('input', valued);
    },
  },
};
</script>
<style lang='scss' scoped>
</style>
