<template>
  <modal
    ref="sendToSignatureModal"
    @create="sendToSignature"
    :disabled="isDisabled"
    @close="reset"
    :action="$t('common.send')"
    :loading="isSending"
    :title="$t('envelopes.signature.signersModalTitle')"
  >
    <div class="columns">
      <div
        class="column is-half"
        v-for="(signer, index) in signers"
        :key="index"
      >
        <h3 class="subtitle is-3">
          {{
            index === 0
              ? $t("envelopes.signature.axioneSide")
              : $t("envelopes.signature.contractorSide")
          }}
        </h3>
        <text-field
          :label="$t('common.name')"
          v-model="signer.name"
          :inline="false"
          :edit="true"
          required
        />
        <text-field
          :label="$t('common.email')"
          v-model="signer.email"
          :inline="false"
          :edit="true"
          required
          type="email"
        />
        <text-field
          :label="$t('common.phoneCountryCode')"
          v-model="signer.phoneCountryCode"
          :inline="false"
          :edit="true"
          required
        />
        <text-field
          :label="$t('common.phone')"
          v-model="signer.phoneNumber"
          :inline="false"
          :edit="true"
          required
        />
        <h4 class="subtitle is-4">
          <span>{{ $t("envelopes.signature.signaturePosition") }}</span>
          <span class="help" :title="$t('envelopes.signature.positionHelper')"
            >?</span
          >
        </h4>
        <div class="tabs is-toggle">
          <ul>
            <li
              :class="{ 'is-active': !signer.anchorRequired }"
              @click="signer.anchorRequired = false"
            >
              <a
                ><span>{{ $t("envelopes.signature.manual") }}</span></a
              >
            </li>
            <li
              :class="{ 'is-active': signer.anchorRequired }"
              @click="signer.anchorRequired = true"
            >
              <a
                ><span>{{ $t("envelopes.signature.automatic") }}</span></a
              >
            </li>
          </ul>
        </div>
        <text-field
          v-if="signer.anchorRequired"
          :label="$t('envelopes.signature.anchorString')"
          v-model="signer.anchorString"
          :inline="false"
          :required="signer.anchorRequired"
          :edit="true"
          :error="conflict"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import axios from "axios";

export default {
  name: "SendToSignatureModal",
  props: {
    envelopeId: [String, Number],
  },
  data() {
    return {
      signers: [
        {
          name: "",
          email: "",
          anchorRequired: false,
          anchorString: "key1",
          phoneCountryCode: "+33",
          phoneNumber: "",
        },
        {
          name: "",
          email: "",
          anchorRequired: false,
          anchorString: "key2",
          phoneCountryCode: "+33",
          phoneNumber: "",
        },
      ],
      isSending: false,
    };
  },
  computed: {
    conflict() {
      if (
        this.signers[0].anchorRequired &&
        this.signers[1].anchorRequired &&
        this.signers[0].anchorString === this.signers[1].anchorString
      ) {
        return this.$t("envelopes.signature.anchorStingsShouldBeDifferent");
      }
      return null;
    },
    isDisabled() {
      return !(this.envelopeId && !this.conflict && this.allFieldsValid);
    },
    allFieldsValid() {
      return this.signers.every(
        (signer) =>
          signer.name &&
          signer.email &&
          signer.phoneCountryCode &&
          signer.phoneNumber
      );
    },
  },
  methods: {
    open() {
      this.reset();
      this.$openModal("sendToSignatureModal");
    },
    async sendToSignature() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) return;

      this.isSending = true;
      const payload = {
        envelopeId: this.envelopeId,
        signers: this.signers,
      };

      try {
        const response = await axios.post("/document/signature/email", payload);
        if (response.data.success === "sent") {
          this.$awn.success(this.$t("envelopes.signature.successfullySent"));
          this.close();
        } else {
          this.$awn.warning(this.$t("envelopes.signature.failedSent"));
        }
      } catch (error) {
        console.error("Error sending to signature:", error);
        this.$awn.error(this.$t("envelopes.signature.sendError"));
      } finally {
        this.isSending = false;
      }
    },
    close() {
      this.$refs.sendToSignatureModal.close();
    },
    reset() {
      this.signers = this.signers.map((signer) => ({
        ...signer,
        name: "",
        email: "",
        anchorRequired: false,
        phoneNumber: "",
      }));
    },
  },
};
</script>
