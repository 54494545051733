<template>
  <component :is="container">
    <router-link :class="linkClass" :to="to" v-if="entity && getRights(entity) && getRights(entity).read">
      <slot></slot>
    </router-link>
    <span class="button is-light is-small" v-if="entity && getRights(entity) && !getRights(entity).read" @click="$awn.warning($t('expressions.noReadAccessToEntity'))">
      <slot></slot>
    </span>
    <span v-if="!entity">
      <slot></slot>
    </span>
  </component>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'restricted-link',
  props: {
    entity: {
      default: null,
      type: String,
    },
    to: {
      default: '',
      type: String,
    },
    container: {
      default: 'div',
      type: String,
    },
    linkClass: {
      default: '',
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      getRights: 'auth/getRights',
    }),
  },
};
</script>
