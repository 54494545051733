<template>
  <div class="home">
    <div class="container is-fluid">
      <h1 class="title">{{ $t("common.dashboard") }}</h1>
      <!-- MY ACTIONS -->
      <div class="panel" v-if="hasMyActions">
        <div class="panel-heading has-background-primary is-flex">
          {{ $t("expressions.myTasks")
          }}<i
            v-if="attachementsToValidateIsLoading || ordersToValidateIsLoading"
            class="loader"
          ></i>
        </div>
        <div
          class="panel-block is-block"
          v-if="!attachementsToValidateIsLoading && !ordersToValidateIsLoading"
        >
          <template v-if="validationOrders.length + validatingAttachements < 1">
            <article class="message is-success">
              <div class="message-body">
                {{ $t("home.nothingToValidate") }} :
              </div>
            </article>
          </template>
          <template v-else>
            <h5
              v-if="validationOrders.length > 0"
              class="title is-5 has-text-primary"
            >
              {{ $t("home.myOrders") }}
            </h5>
            <table
              class="table is-fullwidth"
              v-if="validationOrders.length > 0"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th class="has-text-centered">{{ $t("common.orders") }}</th>
                  <th class="has-text-centered">
                    {{ $t("common.contractor") }}
                  </th>
                  <th class="has-text-centered">{{ $t("common.total") }}</th>
                  <th class="has-text-centered">
                    {{ $t("expressions.endDate") }}
                  </th>
                  <th class="has-text-centered"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(serviceOrder, index) in validationOrders"
                  :key="index"
                >
                  <td>#{{ index + 1 }}</td>
                  <td>{{ serviceOrder.reference }}</td>
                  <td>
                    <span v-if="serviceOrder.company">{{
                      serviceOrder.company.name
                    }}</span>
                  </td>
                  <td class="has-text-right">
                    <span v-if="serviceOrder.total">{{
                      serviceOrder.total | priceEUR
                    }}</span>
                  </td>
                  <td>{{ serviceOrder.endDate }}</td>
                  <td class="has-text-right">
                    <router-link
                      :to="'/sst/order/' + serviceOrder.id"
                      class="button is-primary is-small"
                      ><i class="fa fa-search"></i
                    ></router-link>
                  </td>
                </tr>
              </tbody>
            </table>
            <h5
              v-if="validatingAttachements.length > 0"
              class="title is-5 has-text-primary"
            >
              {{ $t("home.myAttachments") }}
            </h5>
            <table
              class="table is-fullwidth"
              v-if="validatingAttachements.length > 0"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th class="has-text-centered">
                    {{ $t("common.attachments") }}
                  </th>
                  <th class="has-text-centered">
                    {{ $t("common.contractor") }}
                  </th>
                  <th class="has-text-centered">
                    {{ $t("orders.columns.complement") }}
                  </th>
                  <th class="has-text-centered">{{ $t("common.total") }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(attachement, index) in validatingAttachements"
                  :key="index"
                >
                  <td>#{{ index + 1 }}</td>
                  <td>{{ attachement.reference }}</td>
                  <td>{{ attachement.market.edifice }}</td>
                  <td>
                    <span v-if="attachement.order.name">{{
                      attachement.order.name
                    }}</span>
                  </td>
                  <td class="has-text-right">
                    <span v-if="attachement.amount">{{
                      attachement.amount | priceEUR
                    }}</span>
                  </td>
                  <td class="has-text-right">
                    <router-link
                      :to="'/sst/attachement/' + attachement.id"
                      class="button is-primary is-small"
                      ><i class="fa fa-search"></i
                    ></router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </div>
      </div>

      <!-- WORKFLOWS -->
      <div class="panel">
        <div class="panel-heading has-background-primary is-flex">
          {{ $t("home.workflowProcess") }}
          <i v-if="workflowIsLoading" class="loader"></i>
        </div>
        <div class="panel-block" v-if="!workflowIsLoading">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th></th>
                <th class="has-text-centered">{{ $t("common.workflow") }}</th>
                <th class="has-text-centered">{{ $t("common.node") }}</th>
                <th class="has-text-centered">{{ $t("common.quantity") }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <!-- For demo purposes: static nodes -->
              <tr v-for="(node, index) in workflow_nodes" :key="index">
                <td>#{{ index + 1 }}</td>
                <td>{{ node.workflow.name }}</td>
                <td>{{ node.name }}</td>
                <td>{{ node.count }}</td>
                <td class="has-text-right">
                  <button
                    @click.stop="$router.push('/node/' + node.id)"
                    class="button is-primary is-small"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- RUNNING ORDERS -->
      <div class="panel">
        <div class="panel-heading has-background-primary">
          {{ $t("home.ongoingWork") }}
        </div>
        <div class="panel-block is-block">
          <article class="message is-primary">
            <div class="message-body">
              <i v-if="amountIsLoading" class="loader is-black"></i>
              <template v-if="!amountIsLoading">
                <b
                  v-if="
                    runningOrdersTotalAmount.total &&
                    runningOrdersTotalAmount.amount
                  "
                  >{{ runningOrdersTotalAmount.total }}</b
                >
                {{ $t("home.ongoingOrders") }}
                <b>{{ runningOrdersTotalAmount.amount | priceEUR }}</b>
              </template>
            </div>
          </article>
          <datatable
            :fetch="$api.fetchRunningOrders"
            ref="odList"
            :enableDownload="false"
            noSelection
            customEmptyTableMessage="Aucune commande en cours. :)"
          >
            <div
              slot="reference"
              slot-scope="{ item }"
              :title="$t('common.orders')"
              nofilter
            >
              {{ item.reference }}
            </div>
            <div
              slot="company"
              slot-scope="{ item }"
              :title="$t('common.contractor')"
              nofilter
            >
              <span v-if="item.company">
                {{ item.company.name }}
              </span>
            </div>
            <div
              slot="total"
              slot-scope="{ item }"
              :title="$t('common.total')"
              nofilter
              class="has-text-right"
            >
              {{ item.total | priceEUR }}
            </div>
            <div
              slot="end_date"
              slot-scope="{ item }"
              :title="$t('expressions.endDate')"
              nofilter
            >
              {{ item.endDate }}
            </div>
            <div
              slot="action"
              slot-scope="{ item }"
              nofilter
              class="has-text-right"
            >
              <router-link
                :to="'/sst/order/' + item.id"
                class="button is-primary is-small"
              >
                <i class="fa fa-search"></i>
              </router-link>
            </div>
          </datatable>
        </div>
      </div>
    </div>
    <popup-component
      ref="popup"
      :popupData="popupData"
      :notificationData="notificationData"
      @close="onPopupClose"
    >
    </popup-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Home",
  props: ["id"],
  data() {
    return {
      workflow_nodes: [],
      runningOrders: [],
      validationOrders: [],
      validatingAttachements: [],
      attachements: [],
      runningOrdersTotalAmount: { total: 0, amount: 0 },
      amountIsLoading: false,
      ordersToValidateIsLoading: false,
      attachementsToValidateIsLoading: false,
      workflowIsLoading: false,
      popupData: {},
      popupNotifications: [],
      notificationData: {},
      currentPopupIndex: 0,
      popupReadedAt: {
        readed_at: new Date(),
      },
    };
  },
  computed: {
    ...mapGetters({
      hasMyActions: "auth/hasMyActions",
      getPopupNotification: "auth/getPopupNotification",
    }),
  },
  mounted() {
    Promise.all([
      this.getRunningOrderTotalAmount(),
      this.getWorflowNodes(),
      this.getAttachementsToValidate(),
      this.getOrdersToValidate(),
      this.getPopupNotifications(),
    ]);
  },

  methods: {
    getRunningOrderTotalAmount() {
      this.amountIsLoading = true;
      return this.$api
        .fetchRunningOrderTotalAmount()
        .then((d) => {
          this.runningOrdersTotalAmount = d;
        })
        .finally(() => {
          this.amountIsLoading = false;
        });
    },
    getWorflowNodes() {
      this.workflowIsLoading = true;
      return this.$api
        .fetchWorkflowNodes()
        .then((d) => {
          this.workflow_nodes = d;
        })
        .finally(() => {
          this.workflowIsLoading = false;
        });
    },
    getAttachementsToValidate() {
      this.attachementsToValidateIsLoading = true;
      return this.$api
        .fetchAttachementsToValidate()
        .then((d) => {
          this.validatingAttachements = d;
        })
        .finally(() => {
          this.attachementsToValidateIsLoading = false;
        });
    },
    getOrdersToValidate() {
      this.ordersToValidateIsLoading = true;
      return this.$api
        .fetchOrdersToValidate()
        .then((d) => {
          this.validationOrders = d;
        })
        .finally(() => {
          this.ordersToValidateIsLoading = false;
        });
    },

    getPopupNotifications() {
      if (this.getPopupNotification === true) {
        return this.$api
          .fetchLatestNotification()
          .then((notifications) => {
            if (Array.isArray(notifications)) {
              this.popupNotifications = notifications;
              this.currentPopupIndex = 0;
              if (this.popupNotifications.length > 0) {
                this.showNextPopup();
              }
            }
          })
          .catch((error) => {
            console.error("Error fetching notifications:", error);
          })
          .finally(() => {
            this.$store.commit("auth/sendPopupNotification", false);
          });
      }
    },
    showNextPopup() {
      if (this.currentPopupIndex < this.popupNotifications.length) {
        this.popupData =
          this.popupNotifications[this.currentPopupIndex].popupNotification;
        this.notificationData = this.popupNotifications[this.currentPopupIndex];

        this.$refs.popup.open();
        this.markPopupAsRead(
          this.popupNotifications[this.currentPopupIndex].id
        );
      }
    },
    onPopupClose(shouldMarkAsClosed) {
      if (shouldMarkAsClosed)
        this.markPopupAsClose(
          this.popupNotifications[this.currentPopupIndex].id
        );
      this.currentPopupIndex++;
      if (this.currentPopupIndex < this.popupNotifications.length) {
        this.showNextPopup();
      }
    },

    markPopupAsRead(id) {
      axios.post(`/notification/read/${id}`);
    },
    markPopupAsClose(id) {
      axios.post(`/notification/close/${id}`);
    },
  },
};
</script>

<style scoped>
.loader {
  border-left-color: #fff;
  border-bottom-color: #fff;
  margin: 0 20px;
}

.loader.is-black {
  border-left-color: #043b47;
  border-bottom-color: #043b47;
}
</style>
