var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"container is-fluid"},[_c('page-header',{attrs:{"entity":_vm.$entitiesName.RgCaution}},[_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('warranties.title'))+" ")])]),_c('datatable',{ref:"rgCautionList",attrs:{"fetch":_vm.$api.fetchRgCaution,"enableDownload":true,"objectName":"budget/warranties"},scopedSlots:_vm._u([{key:"project",fn:function(ref){
var project = ref.item.project;
return _c('div',{attrs:{"title":_vm.$t('common.project')}},[(project)?_c('restricted-link',{attrs:{"entity":"project","to":("/project/" + (project.id))}},[_vm._v(" "+_vm._s(project.name)+" ")]):_vm._e()],1)}},{key:"subcontractorMarket",fn:function(ref){
var subcontractorMarket = ref.item.subcontractorMarket;
return _c('div',{attrs:{"title":_vm.$t('orders.columns.sstMarket')}},[(subcontractorMarket)?_c('restricted-link',{attrs:{"entity":"subcontractor_market","to":("/sst/market/" + (subcontractorMarket.id))}},[_vm._v(" "+_vm._s(subcontractorMarket.edifice)+" ")]):_vm._e()],1)}},{key:"depositType",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('warranties.depositType')}},[_vm._v(" "+_vm._s(item.depositType)+" ")])}},{key:"attachementAmount",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('warranties.attachementAmount')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.attachementAmount))+" ")])}},{key:"rg",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('warranties.totalRetention')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.rg))+" ")])}},{key:"rgReleased",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('warranties.releasedAmount')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.rgReleased))+" ")])}},{key:"rgLeft",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('warranties.remainingAmount')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.rgLeft))+" ")])}},{key:"depositAmount",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('warranties.bondAmount')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.depositAmount))+" ")])}},{key:"rgLeftToRelease",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('warranties.withheldAmount')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.rgLeftToRelease))+" ")])}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }