<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header entity="null">
        <h1 class="title">{{$t('ods.title')}}</h1>
      </page-header>
      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <div class="level-item">
            <date-field class="no-margin"
                        :label="$t('expressions.startDate')"
                        v-model="startDate"
                        :inline="true"
                        :edit="true" />
            <date-field class="no-margin"
                        :label="$t('expressions.endDate')"
                        v-model="endDate"
                        :inline="true"
                        :edit="true" />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12">
          <datatable :fetch="(p) => $api.fetchBudgetOd(p)"
                     ref="odList"
                     :enableDownload="true"
                     objectName="budget/od"
                     :externalFilters="{startDate: startDate, endDate: endDate}">
            <div slot="monthly_statement"
                 slot-scope="{ item }"
                 :title="$t('ods.situationDate')"
                 nofilter>
              <restricted-link v-if="item.monthlyStatement"
                               entity="monthly_statement"
                               :to="`/sst/situation/${item.monthlyStatement.id}`">
                {{ $options.filters.date(item.monthlyStatement.date) }}
              </restricted-link>
            </div>
            <div slot="attachement"
                 slot-scope="{ item }"
                 :title="$t('ods.attSubcontractor')">
              <restricted-link v-if="item.attachement"
                               entity="attachement"
                               :to="`/sst/attachement/${item.attachement.id}`">
                {{ item.attachement.reference }}
              </restricted-link>
            </div>
            <div slot="market_eotp"
                 slot-scope="{ item }"
                 :title="$t('ods.senderEotp')">{{ item.market ? item.market.eotpST : '' }}</div>
            <div slot="order_eotp"
                 slot-scope="{ item }"
                 :title="$t('ods.receiverEotp')">{{ item.order ?  item.order.imputation.eotp : '' }}</div>
            <div slot="amount"
                 slot-scope="{ item }"
                 :title="$t('ods.amount')"
                 nofilter
                 class="has-text-right">
              {{ item.amount | priceEUR }}
            </div>
            <div slot="type"
                 slot-scope="{ item }"
                 :title="$t('ods.accountingNature')">{{ item.prestationType ? item.prestationType.name : '' }}</div>
            <div slot="currency"
                 :title="$t('common.currency')"
                 nofilter>
              {{$t('common.currentCurrency')}}
            </div>
          </datatable>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'od-list',
  data () {
    return {
      startDate: '',
      endDate: '',
    };
  },
};
</script>
<style lang="scss" scoped>
.no-margin:not(:last-child) {
  margin-bottom: 0;
}
</style>
