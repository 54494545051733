<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.PaymentGuarantees">
        <h1 class="title">{{$t('entities.paymentGuarantees')}}</h1>
      </page-header>
      <div class="is-block is-full-width">
        <div class="controls is-pulled-right">
          <button class="button is-primary white"
                  @click.stop="openUploadModal"
                  data-test="sel-document-upload">
            {{$t('common.upload')}}&nbsp;
            <i class="fa fa-upload"></i>
          </button>
        </div>
        <datatable ref="PaymentGuarantees"
                   :fetch="(p) => $api.fetchPaymentGuarantees(p, filter=null)"
                   objectName="paymentGuarantees"
                   order="DESC">
          <div slot="templateName"
               slot-scope="{ item }"
               :title="$t('expressions.templateName')"
               :sortable="true">{{ item.templateName }}</div>
          <div slot="createdAt"
               slot-scope="{ item }"
               :title="$t('expressions.createdAt')"
               type="date"
               :sortable="true"
               nofilter>{{ item.createdAt | reverseDate }}</div>
          <div slot="actions"
               slot-scope="{ item }">
            <form class="inline-form"
                  method="POST"
                  target="_blank"
                  :action="`${API}/paymentguarantee/${ item.id}`">
              <button class="button is-success is-small"
                      type="submit">
                {{$t('common.download')}}&nbsp;
                <i class="fa fa-download"></i>
              </button>
              <input type="hidden"
                     name="token"
                     :value="authToken">
            </form>

            <button class="button is-danger is-small"
                    @click="deleteDocument(item)">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </datatable>
        <modal ref="createDocumentModal"
               @create="uploadDocument"
               @close="closeModal"
               :title="$t('paymentGuarantees.addGuarantee')">
          <div class="columns">
            <div class="column is-half">
              <div class="field">
                <button class="button is-primary"
                        @click.stop="addFile">
                  {{$t('expressions.selectFile')}}&nbsp;
                  <i class="fa fa-choose"></i>
                </button>
              </div>
              <field readonly
                     inline
                     :label="$t('expressions.selectedFileName')">{{ selectedFileName }}</field>
              <input :style="{ display: 'none'}"
                     ref="documentFileInput"
                     type="file"
                     name="documentFile"
                     accept="application/pdf"
                     data-test="sel-document-file-input"
                     @change="selectFile">
              <text-field :label="$t('expressions.templateName')"
                          v-model="templateName"
                          :inline="false"
                          :edit="true"
                          required />
            </div>
            <div class="column is-half">
              <div class="field">
                <h6>{{$t('paymentGuarantees.fieldNamesMustBeInList')}}</h6>
                <div v-for="(item, index) in formSettings"
                     :key="index">
                  {{index}} : <span style="color:blue"
                        class="pull-right">{{item}}</span>
                </div>
              </div>
            </div>
          </div>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'payment-guarantee-manager',
  inject: ['$validator'],
  props: {
    deletable: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      formSettings: [],
      documents: [],
      createDocument: {
        file: null,
      },
      templateName: '',
      API: axios.defaults.baseURL,
      authToken: this.$store.getters['auth/getToken'],
      documentTableColumns: [
        {
          title: this.$t('expressions.fileName'),
          name: 'templateName',
          accessor: 'templateName',
          filter: '',
        },
        {
          title: 'Date',
          name: 'date',
          accessor: 'createdAt',
          filter: '',
        },
      ],
      isLoading: false,
    };
  },
  computed: {
    selectedFileName () {
      if (this.createDocument.file) {
        return this.createDocument.file.name;
      }
      return ' ';
    },
  },
  mounted () {
    this.fetchFormSettings();
  },
  methods: {
    fetchFormSettings () {
      axios.get('/paymentguarantee/formSettings')
        .then((response) => {
          this.formSettings = response.data;
        })
        .catch((error) => {
          this.error = error.response ? error.response.data : 'API not available!';
        });
    },
    uploadDocument () {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            const form = new FormData();
            if (this.createDocument.file === null) {
              alert("Veuillez sélectionner un fichier");
              return;
            }
            form.set('templateName', this.templateName);
            form.set('file', this.createDocument.file, this.createDocument.file.name);
            axios
              .post('paymentguarantee/create', form)
              .then(() => {
                this.$refs.PaymentGuarantees.refresh();
              })
              .finally(() => {
                this.$refs.createDocumentModal.close();
              });
          }
        }
      )

    },
    openUploadModal () {
      this.$refs.createDocumentModal.open();
    },
    addFile () {
      this.$refs.documentFileInput.click();
    },
    selectFile () {
      this.createDocument.file = this.$refs.documentFileInput.files[0];
    },
    closeModal () {
      this.createDocument = {
        documentType: null,
        file: null,
      };
      this.templateName = '';
    },
    deleteDocument (document) {
      return this.$awn.confirm(
        'Êtes-vous sûr de vouloir supprimer ce document ?',
        () =>
          axios.delete(`/paymentguarantee/${document.id}`).then((response) => {
            if (response.data.success) {
              this.$refs.PaymentGuarantees.refresh();
            }
          }),
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.is-full-width {
  width: 100%;
  flex: 1;
}

.white {
  color: #fff;
}

.field {
  margin-bottom: 0.75em;
}

.inline-form {
  display: inline;
}
td .is-pulled-right > * {
  margin-left: 4px;
}
</style>
