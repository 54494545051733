<template>
  <span>
    <span @click="show = true"> </span>
    <transition name="popup-background">
      <div v-if="show" class="modal-background"></div>
    </transition>
    <transition name="slide-fade">
      <div v-if="show" class="popup-placeholder" @click="close">
        <div class="popup-bg" @click.stop>
          <div class="popup-title has-text-centered is-size-5">
            <h2 class="has-text-weight-bold">{{ popupData.title }}</h2>
          </div>
          <div class="popup-body ql-editor" v-html="popupData.content"></div>
          <div class="toolbar">
            <slot name="toolbar">
              <div
                v-if="!preview && notificationData.closedAt === null"
                class="checkbox"
              >
                <input
                  type="checkbox"
                  id="close-popup"
                  name="close-popup"
                  v-model="closePopup"
                />
                <label for="close-popup">Ne plus afficher</label>
              </div>

              <button
                class="button is-medium"
                :class="{ 'is-loading': loading }"
                @click="close"
              >
                {{ $t("common.close") }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </transition>
  </span>
</template>

<script>
export default {
  name: "popup-component",
  props: {
    popupData: Object,
    notificationData: Object,
    preview: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: function () {
        return this.$t("common.create");
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      closePopup: false,
    };
  },
  methods: {
    open() {
      this.closePopup = false;
      this.show = true;
      this.$emit("open");
    },
    close() {
      this.show = false;
      this.$emit("close", this.closePopup);
    },
  },
};
</script>

<style scoped>
.popup-placeholder {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
  height: 100%;
}

.popup-bg {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 700px;
  height: 600px;
  background-color: #fff;
  padding: 20px;
}
.popup-body {
  height: calc(80% - 20px);
  padding: 10px;
  overflow: auto;
  padding-top: 30px;
  img {
    max-height: max-content;
  }
}

.popup-title {
  max-height: 10%;
  height: 10%;
  justify-content: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #dbdbdb;
  h2 {
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.slide-fade-enter-active,
.modal-background-enter-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-active,
.modal-background-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-700px);
}

.modal-background-enter,
.modal-background-leave-to {
  opacity: 0.5;
}

.toolbar {
  height: calc(10% + 20px);
  border-top: 1px solid #dbdbdb;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 20px;
}

.modal-background {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 35;
}

.checkbox {
  display: flex;
  label {
    padding-left: 10px;
    vertical-align: bottom;
  }
}
</style>
