import { render, staticRenderFns } from "./PrestationList.vue?vue&type=template&id=5230b411&scoped=true&"
import script from "./PrestationList.vue?vue&type=script&lang=js&"
export * from "./PrestationList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5230b411",
  null
  
)

export default component.exports