var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"container is-fluid"},[_c('page-header',{attrs:{"entity":_vm.$entitiesName.OscarSubProject}},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('entities.oscarSubProjects')))])]),_c('datatable',{attrs:{"fetch":_vm.$api.fetchOscarSubProjects,"enableDownload":true,"objectName":_vm.$entitiesName.OscarSubProject},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.name')}},[_vm._v(_vm._s(item.name))])}},{key:"arcgisName",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('oscarSubProjects.columns.arcgisName')}},[_vm._v(_vm._s(item.arcgisName))])}},{key:"nroName",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.nro')}},[_vm._v(_vm._s(item.nroName))])}},{key:"ipeIdPm",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('oscarSubProjects.columns.pmIpeId')}},[_vm._v(_vm._s(item.ipeIdPm))])}},{key:"offerIdPm",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('oscarSubProjects.columns.pmOfferId')}},[_vm._v(_vm._s(item.offerIdPm))])}},{key:"actions",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('router-link',{staticClass:"button is-primary",attrs:{"to":'/oscarsubproject/' + item.id}},[_c('i',{staticClass:"fa fa-search"})])],1)}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }