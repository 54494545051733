<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Attachement"
                   :create="openCreateModal">
        <h1 class="title">{{$t('entities.units')}}</h1>
      </page-header>
      <datatable ref="units"
                 :fetch="$api.fetchUnits"
                 :enableDownload="false"
                 objectName="units"
                 identifier="name">
        <div slot="name"
             slot-scope="{ item }"
             :title="$t('common.name')"
             :sortable="true">{{ item.name }}</div>
        <div slot="actions"
             slot-scope="{ item }">
          <router-link :to="'/unit/' + item.id"
                       class="button is-primary">
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
      <modal ref="createUnitModal"
             @create="createUnit"
             :title="$t('units.unitCreation')">
        <div class="columns">
          <div class="column is-half">
            <text-field :label="$t('common.name')"
                        v-model="prototypeUnit.name"
                        :inline="false"
                        :edit="true"
                        required />
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'units-list',
  data () {
    return {
      prototypeUnit: {
        name: '',
      },
      modalIsOpen: false,
    };
  },
  methods: {
    createUnit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const created = Object.assign({}, this.prototypeUnit);
          axios
            .post('/unit', created)
            .then((res) => {
              this.closeCreateModal();
              this.$refs.units.refresh();
              this.$router.push({
                name: 'Unit',
                params: { id: res.data.id },
              });
            });
          this.prototypeUnit = {
            name: '',
          };
        }
      });
    },
    openCreateModal () {
      this.$refs.createUnitModal.open();
    },
    closeCreateModal () {
      this.$refs.createUnitModal.close();
    },
  },
};
</script>

