var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('paginate',{attrs:{"paginate":_vm.pagination,"showOptions":true,"options":_vm.paginateOptions},on:{"change":_vm.paginate}}),_c('table',[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"4"}},[_vm._v(_vm._s(_vm.$t("common.name")))]),_c('th',[_vm._v(_vm._s(_vm.$t("evaluation.averageRating")))]),_c('th',[_vm._v(_vm._s(_vm.$t("evaluation.evaluation")))]),_c('th',[_vm._v(_vm._s(_vm.$t("evaluation.safety")))]),_c('th',[_vm._v(_vm._s(_vm.$t("evaluation.procurement")))]),_c('th',[_vm._v(_vm._s(_vm.$t("evaluation.project")))])])]),_c('tbody',[(_vm.companies.length === 0 && _vm.loading === true)?_c('tr',[_vm._m(0)]):_vm._e(),(_vm.companies.length === 0 && _vm.loading === false)?_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("expressions.noData")))])]):_vm._e(),_vm._l((_vm.companies),function(company,companyIndex){return [_c('tr',{key:company.id,staticClass:"selectable companies",class:{
            'company-selected': _vm.expandedAgencyIndex === companyIndex,
            'border-companies': _vm.expandedAgencyIndex !== null,
          },on:{"click":function($event){return _vm.toggleAgencies(companyIndex)}}},[_c('td',{attrs:{"colspan":"4"}},[_vm._v(_vm._s(company.name))]),_c('td',[_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"pastille",class:{
                  red: company.average.score < 3.33,
                  yellow:
                    company.average.score >= 3.33 &&
                    company.average.score < 6.66,
                  green: company.average.score >= 6.66,
                }}),_c('span',{staticStyle:{"min-width":"20px"}},[_vm._v(_vm._s(company.average.score.toFixed(1)))])])]),_c('td',[_vm._v(_vm._s(company.average.count))]),_c('td',[_vm._v(_vm._s(company.details.safety.score.toFixed(1)))]),_c('td',[_vm._v(_vm._s(company.details.procurement.score.toFixed(1)))]),_c('td',[_vm._v(_vm._s(company.details.projects.score.toFixed(1)))])]),(_vm.expandedAgencyIndex === companyIndex)?[(_vm.loadingAgencies)?[_c('tr',{staticClass:"agencies last-agency"},[_vm._m(1,true)])]:_vm._e(),(_vm.agencies.length > 0)?_c('tr',{staticClass:"subtitle-separator tr-indent"},[_c('td',[_vm._v("Agences")]),_c('td',{attrs:{"colspan":"8"}})]):_vm._e(),_vm._l((_vm.agencies),function(agency,agencyIndex){return [_c('tr',{key:company.id + '-' + agency.id,staticClass:"agencies selectable tr-indent",class:{
                'last-agency': agencyIndex === _vm.agencies.length - 1,
                'agency-selected': _vm.expandedProjectIndex === agencyIndex,
              },on:{"click":function($event){return _vm.toggleProjects(agencyIndex)}}},[_c('td'),_c('td',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(agency.name))]),_c('td',[_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"pastille",class:{
                      red: agency.average.score < 3.33,
                      yellow:
                        agency.average.score >= 3.33 &&
                        agency.average.score < 6.66,
                      green: agency.average.score >= 6.66,
                    }}),_c('span',{staticStyle:{"min-width":"20px"}},[_vm._v(" "+_vm._s(agency.average.score.toFixed(1))+" ")])])]),_c('td',[_vm._v(_vm._s(agency.average.count))]),_c('td',[_vm._v(_vm._s(agency.details.safety.score.toFixed(1)))]),_c('td',[_vm._v(_vm._s(agency.details.procurement.score.toFixed(1)))]),_c('td',[_vm._v(_vm._s(agency.details.projects.score.toFixed(1)))])]),(_vm.expandedProjectIndex === agencyIndex)?[(_vm.loadingProject)?[_c('tr',{staticClass:"project last-project tr-indent-1"},[_vm._m(2,true)])]:[[(_vm.projects.length > 0)?_c('tr',{staticClass:"subtitle-separator separator-project tr-indent-1"},[_c('td'),_c('td',[_vm._v("Projets")]),_c('td',{staticClass:"pl-60",attrs:{"colspan":"7"}})]):_vm._e(),_vm._l((_vm.projects),function(project,projectIndex){return [_c('tr',{key:agency.id + '-' + project.id,staticClass:"projects selectable tr-indent-1",class:{
                        'last-project': projectIndex === _vm.projects.length - 1,
                        'project-selected':
                          _vm.expandedMarketIndex === projectIndex,
                      },on:{"click":function($event){return _vm.toggleMarkets(projectIndex, project)}}},[_c('td'),_c('td'),_c('td',{staticClass:"pl-60",attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(project.reference)+" ")]),_c('td',[_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"pastille",class:{
                              red: project.average < 3.33,
                              yellow:
                                project.average >= 3.33 &&
                                project.average < 6.66,
                              green: project.average >= 6.66,
                            }}),_c('span',{staticStyle:{"min-width":"20px"}},[_vm._v(" "+_vm._s(project.average.toFixed(1))+" ")])])]),_c('td',[_vm._v(_vm._s(project.count))]),_c('td'),_c('td'),_c('td')]),(_vm.expandedMarketIndex === projectIndex && _vm.markets)?[(_vm.markets)?_c('tr',{staticClass:"subtitle-separator separator-market tr-indent-2"},[_c('td'),_c('td'),_c('td',{attrs:{"colspan":"2"}},[_vm._v("Marché sous-traitant")]),_c('td',{staticClass:"pl-90",attrs:{"colspan":"4"}})]):_vm._e(),_vm._l((_vm.markets),function(market,marketIndex){return _c('tr',{key:project.id + '-' + market.id,staticClass:"markets tr-indent-2",class:{
                            'last-market': marketIndex === _vm.markets.length - 1,
                          }},[_c('td'),_c('td'),_c('td'),_c('td',{staticClass:"pl-90",attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(market.reference)+" ")]),_c('td',[_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"pastille",class:{
                                  red: market.average < 3.33,
                                  yellow:
                                    market.average >= 3.33 &&
                                    market.average < 6.66,
                                  green: market.average >= 6.66,
                                }}),_c('span',{staticStyle:{"min-width":"20px"}},[_vm._v(" "+_vm._s(market.average.toFixed(1))+" ")])])]),_c('td',[_vm._v(_vm._s(market.count))]),_c('td'),_c('td'),_c('td')])})]:_vm._e()]})]]]:_vm._e()]})]:_vm._e()]})],2)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"loader-container",attrs:{"colspan":"9"}},[_c('i',{staticClass:"loader"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"loader-container"},[_c('i',{staticClass:"loader"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"loader-container"},[_c('i',{staticClass:"loader"})])}]

export { render, staticRenderFns }