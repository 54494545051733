<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.BpuSst" :create="openCreateModal">
        <h1 class="title">{{ $t("common.bpu") }}</h1>
        <template slot="actions">
          <button
            v-if="getRights($entitiesName.BpuTemplate).read"
            class="button is-primary"
            cy-data="cy-bpu-templates-button"
            @click="goToTemplates"
          >
            {{ $t("common.bpuTemplates") }}
          </button>
        </template>
      </page-header>

      <datatable
        :fetch="$api.fetchBpus"
        ref="datatable"
        :enableDownload="true"
        objectName="bpus"
      >
        <div
          slot="name"
          slot-scope="{ item }"
          :title="$t('common.name')"
          :sortable="true"
        >
          {{ item.name }}
        </div>
        <div
          slot="market"
          slot-scope="{ item: { market } }"
          :title="$t('common.contractorAgreement')"
          :sortable="true"
          sortBy="market.edifice"
        >
          <restricted-link
            :entity="$entitiesName.SubcontractorMarket"
            v-if="market"
            :to="`/sst/market/${market.id}`"
          >
            {{ market.edifice }}
          </restricted-link>
        </div>
        <div
          slot="agency"
          slot-scope="{ item: { agency } }"
          :title="$t('common.agency')"
          :sortable="true"
          sortBy="market.agency.edifice"
        >
          <restricted-link
            :entity="$entitiesName.Agency"
            v-if="agency"
            :to="`/sst/agency/${agency.id}`"
          >
            <agency-display :value="agency" />
          </restricted-link>
        </div>
        <div
          slot="project"
          slot-scope="{ item: { project } }"
          :title="$t('common.project')"
          :sortable="true"
          sortBy="market.project.name"
        >
          <restricted-link
            :entity="$entitiesName.Project"
            v-if="project"
            :to="`/project/${project.id}`"
          >
            {{ project.name }}
          </restricted-link>
        </div>
        <div
          slot="template"
          slot-scope="{ item: { bpuTemplate } }"
          :title="$t('common.template')"
          :sortable="true"
          sortBy="bpuTemplate.name"
        >
          <restricted-link
            v-if="bpuTemplate"
            :entity="$entitiesName.BpuTemplate"
            :to="`/sst/bputemplate/${bpuTemplate.id}`"
          >
            {{ bpuTemplate.name }}
          </restricted-link>
        </div>
        <div slot="actions" slot-scope="{ item }">
          <router-link
            entity="bpu_sst"
            :to="'/sst/bpu/' + item.id"
            class="button is-primary"
          >
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>

    <modal
      ref="createBPUModal"
      @create="createBPU"
      :title="$t('bpus.bpuCreation')"
    >
      <div class="columns">
        <div class="column is-half">
          <text-field
            :label="$t('common.name')"
            data-test="sel-bpu-name"
            v-model="bpu.name"
            :inline="false"
            :edit="true"
            required
          />
          <many2one-field
            :label="$t('common.template')"
            v-model="bpu.bpuTemplate"
            :fetch="$api.fetchTemplates"
            reference="name"
            :columns="columnsTemplates"
            :inline="false"
            :edit="true"
            required
            :hasFilter="true"
            v-validate="{ required: true }"
            name="bputemplate2one"
            :error="errors.has('bputemplate2one')"
            data-test="sel-bpu-template"
          ></many2one-field>
          <many2one-field
            :label="$t('common.contractorAgreement')"
            v-model="bpu.market"
            :fetch="$api.fetchMarkets"
            reference="edifice"
            @click="(item) => $router.push('/sst/market/' + item.id)"
            :columns="columnsMarkets"
            :inline="false"
            :edit="true"
            required
            :hasFilter="true"
            v-validate="{ required: true }"
            name="market2one"
            :error="errors.has('market2one')"
            data-test="sel-bpu-market"
          ></many2one-field>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "bpu-list",
  data() {
    return {
      bpu: {},
      columnsMarkets: {
        edifice: this.$t("common.edifice"),
        "project.name": this.$t("common.project"),
        "agency.edifice": this.$t("common.agency"),
      },
      columnsTemplates: {
        id: this.$t("common.id"),
        name: this.$t("common.name"),
      },
    };
  },
  computed: {
    ...mapGetters({
      getRights: "auth/getRights",
    }),
  },
  methods: {
    openCreateModal() {
      this.$refs.createBPUModal.open();
    },
    createBPU() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        axios.post("/bpu", this.bpu).then((response) => {
          if (!response.data.success) {
            return;
          }
          //this.$refs.datatable.refresh();
          // this.$refs.createBPUModal.close();
          this.$router.push({
            name: "BPU",
            params: { id: response.data.id },
          });
        });
      });
    },
    goToTemplates() {
      this.$router.push("/sst/bputemplates/");
    },
  },
};
</script>

<style scoped>
</style>
