<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.OsTemplate" :create="openCreateModal">
        <h1 class="title">{{ $t("entities.orderTemplates") }}</h1>
      </page-header>

      <datatable
        :fetch="$api.fetchOsTemplates"
        ref="osTemplateList"
        :enableDownload="true"
        objectName="osTemplate"
      >
        <div
          slot="name"
          slot-scope="{ item }"
          :title="$t('expressions.templateName')"
          :sortable="true"
        >
          {{ item.name }}
        </div>
        <div
          slot="bpuTemplate"
          slot-scope="{ item: { bpuTemplate } }"
          :title="$t('entities.bpuTemplate')"
          :sortable="true"
          sortBy="bpuTemplate.name"
        >
          <restricted-link
            v-if="bpuTemplate"
            entity="bpuTemplate"
            :to="`/sst/bputemplates/${bpuTemplate.id}`"
          >
            {{ bpuTemplate.name }}
          </restricted-link>
        </div>
        <div slot="actions" slot-scope="{ item }">
          <router-link
            :to="'/os/template/' + item.id"
            class="button is-primary"
          >
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>

    <modal
      ref="createOsTemplateModal"
      @close="closeCreateOsTemplateModal"
      @create="createOsTemplate"
      :title="$t('orderTemplates.templateCreation')"
    >
      <div class="columns">
        <div class="column is-half">
          <text-field
            :label="$t('expressions.templateName')"
            v-model="osTemplate.name"
            :inline="false"
            :edit="true"
            required
            data-test="sel-ostemplate-name"
          />
          <many2one-field
            :label="$t('common.template')"
            v-model="osTemplate.bpuTemplate"
            :fetch="$api.fetchTemplates"
            reference="name"
            :columns="columnsTemplates"
            :inline="false"
            :edit="true"
            required
            :hasFilter="true"
            v-validate="{ required: true }"
            name="bputemplate2one"
            :error="errors.has('bputemplate2one')"
            data-test="sel-bpu-template"
          ></many2one-field>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "osTemplateList",
  inject: ["$validator"],
  data() {
    return {
      osTemplate: {},
      columnsTemplates: {
        name: this.$t("common.name"),
      },
    };
  },
  methods: {
    openCreateModal() {
      this.$refs.createOsTemplateModal.open();
    },
    closeCreateOsTemplateModal() {
      this.osTemplate = {};
    },
    createOsTemplate() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          axios
            .post("/orderTemplate/create", this.osTemplate)
            .then((response) => {
              if (response.data.success) {
                this.$refs.osTemplateList.refresh();
                this.$refs.createOsTemplateModal.close();
                this.$router.push({
                  name: "OsTemplate",
                  params: { id: response.data.id },
                });
              }
            });
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
