// plugins/modalPlugin.js
export default {
    install(Vue) {
      Vue.prototype.$openModal = function (modalRef) {
        if (this.$refs[modalRef] && this.$refs[modalRef].open) {
          this.$refs[modalRef].open();
        } else {
          console.warn(`No modal found with ref ${modalRef}`);
        }
      };
      Vue.prototype.$closeModal = function (modalRef) {
        if (this.$refs[modalRef] && this.$refs[modalRef].close) {
          this.$refs[modalRef].close();
        } else {
          console.warn(`No modal found with ref ${modalRef}`);
        }
      };
    }
  };
  