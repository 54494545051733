<template>
  <field
    :edit="edit"
    :inline="inline"
    :label="label"
    :value="siretInternal"
    :readonly="readonly"
    :required="required"
    :cy-data="cyData"
  >
    <div>
      <div class="field has-addons">
        <div class="control">
          <input
            class="input"
            type="text"
            v-model="siretInternal"
            :required="required"
            v-validate="validate"
            :name="internal_id"
            :data-vv-as="label"
            :class="{ error: errors.has(internal_id) }"
            :cy-data="cyDataString"
            v-on:keyup.enter="search"
          />
        </div>
        <div class="control">
          <button
            class="button is-primary"
            :class="{ 'is-loading': loading }"
            @click="search"
            cy-data="siret-search"
          >
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>

      <div v-if="error" class="message is-danger">
        <div class="message-body">{{ error }}</div>
      </div>

      <div v-if="errors.has(internal_id)" class="message is-danger">
        <div class="message-body">{{ errors.first(internal_id) }}</div>
      </div>
    </div>
  </field>
</template>

<script>
import axios from "axios";
import uid from "uniqid";
import { mapGetters } from "vuex";

export default {
  name: "siret-field",
  inject: ["$validator"],
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "",
    },
    value: {},
    siret: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validate: {
      // eslint-disable-next-line
      type: String | Object,
      default: "",
    },
    cyData: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      val: {},
      siretInternal: this.siret,
      internal_id: uid(),
      loading: false,
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      globalEdit: "states/isEdition",
    }),
    cyDataString() {
      const fieldLabel = this.cyData ? this.cyData : this.label;
      if (!fieldLabel) {
        return "";
      }
      return `input-${fieldLabel.replaceAll(/[ ']/g, "")}`;
    },
  },
  mounted() {
    this.val = this.value;
    this.siretInternal = this.siret;
  },
  watch: {
    value(val) {
      this.val = val;
    },
    siret(val) {
      this.siretInternal = val;
    },
    siretInternal(val, oldval) {
      // If this is a valid SIRET handle the lookup
      if (!val) {
        return;
      }

      if (val === oldval) {
        return;
      }

      val = val.trim();

      if (val.length != 14) {
        return;
      }
      this.lookup(val);
    },
  },
  methods: {
    search() {
      const f = this.siretInternal;
      if (!f) {
        this.$emit("input", null);
        return;
      }
      const siret = f.trim();
      this.lookup(siret);
    },
    lookup(siret) {
      if (this.readonly || (!this.edit && this.globalEdit === false)) {
        return;
      }

      this.$emit("input", null);
      this.loading = true;
      this.error = null;
      axios
        .get("/agency/lookup/" + siret)
        .then((response) => {
          const data = response.data;
          if (!data.siret) {
            this.error =
              "Réponse invalide du serveur ! Veuillez réessayer plus tard !";
            return;
          }
          this.$emit("input", data);
        })
        .catch((error) => {
          if (!error.response.data) {
            return false;
          }
          this.error = error.response.data.error;
          this.$emit("input", {
            inThirdParty: false,
            siret,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang='scss' scoped>
.input {
  max-width: 500px;
}

.error {
  border: 2px solid red;
}
</style>
